import React from 'react';
import { get } from 'lodash';
import { Redirect, Route } from 'react-router-dom';

function InitialRoute({ component: Component, cars, isAuth, ...rest }) {
    function checkExistingCars(props) {
        return get(cars, 'empty') ? (
            <Redirect
                to={{
                    pathname: '/garage',
                    from: props.location,
                }}
            />
        ) : (
            <Component {...props} />
        );
    }

    return <Route {...rest} render={props => (isAuth ? checkExistingCars(props) : <Component {...props} />)} />;
}
export default InitialRoute;
