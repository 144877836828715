import { AppBar, Box, Button, CardMedia, Container, Grid, useMediaQuery, ButtonBase } from '@material-ui/core';
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useWindowScroll } from 'react-use';
import { ArrowBackIos } from '@material-ui/icons';
import { GarageIcon } from 'assets/icons/navigation/Garage';
import login from '../../assets/icons/login.png';
import logo from '../../assets/logo.png';
import AuthModal from '../../components/AuthModal';
import { NavDropdownMenu } from '../../components/NavDropdown';
import { CustomText } from '../../components/Text';
import { UnauthHeaderLinks } from './components/UnauthHeaderLinks';
import { NAVIGATION_DROPDOWN } from '../../constants/dropdown';
import TEXT from '../../constants/text';
import useStyles from './sign-in-up/styles';
import useHeaderStyles from './UnauthHeaderStyles';

export function UnauthHeader({ isAuth, cookies, successCallback }) {
    const classes = useStyles();
    const scroll = useWindowScroll();
    const isMobile = useMediaQuery('(max-width:600px)');
    const isMedium = useMediaQuery('(max-width: 920px)');
    const headerClasses = useHeaderStyles({ isScrolling: scroll.y > 0 });
    const history = useHistory();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);

    function handleOpenModal() {
        setShowModal(true);
    }

    function navigateToGarage() {
        history.push('/garage');
    }

    const handleShowModal = React.useCallback(state => setShowModal(state), []);

    return (
        <AppBar
            position="fixed"
            classes={{
                root: headerClasses.appbar,
                colorPrimary: headerClasses.appBarPrimary,
            }}>
            <Container className={`${classes.container} ${classes.headerContainer}`} maxWidth="lg">
                <Grid container className={headerClasses.gridContainer}>
                    <Grid className={headerClasses.gridItem} xs={3} sm={4} md={5} item>
                        {isMobile ? (
                            <ButtonBase disabled={!location.key} onClick={history.goBack}>
                                <ArrowBackIos className={headerClasses.backButton} />
                            </ButtonBase>
                        ) : (
                            <NavDropdownMenu
                                menuIcon="fas fa-bars"
                                title={TEXT.reviews}
                                options={NAVIGATION_DROPDOWN}
                                isAuth={isAuth}
                                titleStyle={classes.text}
                                textStyle={classes.dropdownItemText}
                                buttonStyle={classes.reviewsDropdownButton}
                                hasText={false}
                            />
                        )}
                        {!isMedium && !isAuth && (location.pathname === '/' || location.pathname === '/b2b') && (
                            <UnauthHeaderLinks />
                        )}
                    </Grid>
                    <Grid className={headerClasses.logoGridItem} xs={5} sm={4} md={2} item>
                        <Box className={classes.logoUnauthHeader}>
                            <Link to="/">
                                <CardMedia component="img" src={logo} alt="logo" />
                            </Link>
                        </Box>
                    </Grid>
                    <Grid className={headerClasses.gridItem} xs={4} sm={4} md={5} item justify="center">
                        {!isMedium && (
                            <Box display="flex" flex={1} justifyContent="flex-end" marginRight={1}>
                                <Link to="/registration">
                                    <CustomText className={classes.loginButtonText} text={TEXT.signup} />
                                </Link>
                            </Box>
                        )}
                        <Button className={classes.loginButton} onClick={isAuth ? navigateToGarage : handleOpenModal}>
                            {!isAuth && <CustomText className={classes.loginButtonText} text={TEXT.entry} />}
                            {isAuth ? (
                                <GarageIcon width={38} height={38} />
                            ) : (
                                <CardMedia component="img" src={login} alt="login" />
                            )}
                        </Button>
                    </Grid>
                </Grid>
                {isMedium && !isAuth && (location.pathname === '/' || location.pathname === '/b2b') && (
                    <UnauthHeaderLinks isMedium={isMedium} />
                )}
                <AuthModal
                    handleShowModal={handleShowModal}
                    successCallback={successCallback}
                    showModal={showModal}
                    cookies={cookies}
                    history={history}
                />
            </Container>
        </AppBar>
    );
}
