import { FuelIcon } from 'assets/icons/navigation/Fuel';
import { ServicesIcon } from 'assets/icons/navigation/Services';
import TEXT from './text';

export const ADD_DROPDOWN = [
    {
        title: TEXT.repairs,
        img: ServicesIcon,
        linkTo: '/works/event',
        isSvg: true,
    },
    {
        title: TEXT.refueling,
        img: FuelIcon,
        linkTo: '/works/refill',
        isSvg: true,
    },
];

export const REVIEWS_DROPDOWN = [
    {
        title: TEXT.carReviews,
        linkTo: '/feedback/cars',
    },
    {
        title: TEXT.sparesReviews,
        linkTo: '/feedback/spares',
    },
];

export const PROFILE_DROPDOWN = [
    {
        title: TEXT.profile,
        linkTo: '/profile',
    },
];

const NAVIGATION_DROPDOWN = [
    {
        title: TEXT.main,
        linkTo: '/',
    },
    {
        title: TEXT.carReviews,
        linkTo: '/feedback/cars',
    },
    {
        title: TEXT.sparesReviews,
        linkTo: '/feedback/spares',
    },
    {
        title: TEXT.carServices,
        linkTo: '/services',
    },
    {
        title: TEXT.promotions,
        linkTo: '/promotions',
    },
];
const NAVIGATION_DROPDOWN_AUTH = [...NAVIGATION_DROPDOWN];

NAVIGATION_DROPDOWN_AUTH.splice(1, 0, {
    title: TEXT.myGarage,
    linkTo: '/garage',
});

export { NAVIGATION_DROPDOWN, NAVIGATION_DROPDOWN_AUTH };
