import { get } from 'lodash';
import { NotificationManager } from 'react-notifications';
import { api } from '.';
import { javaResources, javaRoot, javaRootPrivate } from '../constants/urls';

export const getCarReviewCommentsApi = ({ page = 0, selectors }) => {
    return api
        .get(`${javaRoot}/autos/reviews?page=${page}&size=10`, { params: { ...selectors, sort: 'created_date,desc' } })
        .then(({ data }) => {
            const content = get(data, 'content', []) || [];
            const updatedContent = content.map(item => ({
                ...item,
                photo: item.avatar ? `${javaResources}${item.avatar}` : null,
            }));

            return { ...data, content: updatedContent };
        })
        .catch(() => {
            NotificationManager.error('Ошибка получения отзывов');
        });
};

export const getUserVehicle = async user => {
    try {
        const { data } = await api.get(`${javaRootPrivate}/users/${user.id}/autos`, {
            params: { size: 1, page: 0 },
        });
        return data;
    } catch (err) {
        NotificationManager.error('Ошибка получения автомобиля');
    }
};

export const getAllUserVehicles = async user => {
    const { data } = await api.get(`${javaRootPrivate}/users/${user.id}/autos`, {
        params: { size: 20, page: 0 },
    });
    return data;
};
