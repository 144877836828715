import { Box, Button, CardMedia, Container } from '@material-ui/core';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import login from '../../../assets/icons/login.png';
import logo from '../../../assets/logo.png';
import AuthModal from '../../../components/AuthModal';
import { CustomText } from '../../../components/Text';
import TEXT from '../../../constants/text';
import AccountContent from './account-content/AccountContent';
import useStyles from './styles';

const SignInUp = ({ isAuth, cookies, logout, user, successCallback }) => {
    const classes = useStyles();
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);

    function handleOpenModal() {
        setShowModal(true);
    }

    const handleShowModal = React.useCallback(state => setShowModal(state), []);

    function handleLogout() {
        logout();
        history.push('/');
    }

    return (
        <>
            <Container className={classes.container}>
                <Box className={classes.logoHeader}>
                    <Link to="/">
                        <CardMedia component="img" src={logo} alt="logo" />
                    </Link>
                </Box>
                {isAuth ? (
                    <AccountContent logout={handleLogout} user={user} />
                ) : (
                    <Button className={classes.loginButton} onClick={handleOpenModal}>
                        <CustomText className={classes.loginButtonText} text={TEXT.entry} />
                        <CardMedia component="img" src={login} alt="login" />
                    </Button>
                )}
                <AuthModal
                    handleShowModal={handleShowModal}
                    successCallback={successCallback}
                    showModal={showModal}
                    cookies={cookies}
                    history={history}
                />
            </Container>
        </>
    );
};

export default SignInUp;
