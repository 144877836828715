import {
    GET_EVENTS_FAILURE,
    GET_EVENTS_REQUEST,
    GET_EVENTS_SUCCESS,
    GET_WORK_TIMES_FAILURE,
    GET_WORK_TIMES_REQUEST,
    GET_WORK_TIMES_SUCCESS,
    GET_WORK_TYPES_FAILURE,
    GET_WORK_TYPES_REQUEST,
    GET_WORK_TYPES_SUCCESS,
    GET_EVENT_PLACES_FAILURE,
    GET_EVENT_PLACES_REQUEST,
    GET_EVENT_PLACES_SUCCESS,
    GET_JOBS_BY_PLACE_REQUEST,
    GET_JOBS_BY_PLACE_FAILURE,
    GET_JOBS_BY_PLACE_SUCCESS,
    SAVE_EVENTS_REQUEST,
    SAVE_EVENTS_FAILURE,
    SAVE_EVENTS_SUCCESS,
} from '../constants/calendar';

const initialState = {
    events: [],
    workTimes: [],
    workTypes: [],
    places: [],
    isLoading: false,
    error: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENTS_REQUEST:
        case GET_WORK_TYPES_REQUEST:
        case GET_WORK_TIMES_REQUEST:
        case GET_EVENT_PLACES_REQUEST:
        case GET_JOBS_BY_PLACE_REQUEST:
        case SAVE_EVENTS_REQUEST:
            return { ...state, isLoading: true };
        case GET_EVENTS_FAILURE:
        case GET_WORK_TIMES_FAILURE:
        case GET_WORK_TYPES_FAILURE:
        case GET_EVENT_PLACES_FAILURE:
        case GET_JOBS_BY_PLACE_FAILURE:
        case SAVE_EVENTS_FAILURE:
            return { ...state, error: action.error };
        case GET_EVENTS_SUCCESS:
            return { ...state, events: action.payload };
        case GET_WORK_TIMES_SUCCESS:
            return { ...state, workTimes: action.payload };
        case GET_WORK_TYPES_SUCCESS:
            return { ...state, workTypes: action.payload };
        case GET_EVENT_PLACES_SUCCESS:
            return { ...state, places: action.payload };
        case GET_JOBS_BY_PLACE_SUCCESS:
            return { ...state, jobs: action.payload };
        case SAVE_EVENTS_SUCCESS:
            return { ...state };
        default:
            return state;
    }
};

export default reducer;
