import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
    appbar: {
        boxShadow: 'none',
    },
    appBarPrimary: {
        backgroundColor: ({ isScrolling }) => (isScrolling ? 'rgb(235, 248, 249)' : '#fff'),
        opacity: 0.9,
        paddingRight: '0px !important',
    },
    gridContainer: {
        '& .MuiGrid-item': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '&:last-child': {
                justifyContent: 'flex-end',
            },
        },
    },
    logoGridItem: {
        justifyContent: 'center',
    },
    backButton: {
        color: '#0dabc2',
        fontSize: 24,
        backgroundColor: 'transparent',
        padding: '12px 12px 12px 6px',
    },
});
