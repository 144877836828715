export const GENERATE_CAR_REPORT_LINK_REQUEST = '@eventHistory//GENERATE_CAR_REPORT_LINK_REQUEST';
export const GENERATE_CAR_REPORT_LINK_SUCCESS = '@eventHistory//GENERATE_CAR_REPORT_LINK_SUCCESS';
export const GENERATE_CAR_REPORT_LINK_FAILURE = '@eventHistory//GENERATE_CAR_REPORT_LINK_FAILURE';

export const GET_CAR_REPORT_LINK_REQUEST = '@eventHistory//GET_CAR_REPORT_LINK_REQUEST';
export const GET_CAR_REPORT_LINK_SUCCESS = '@eventHistory//GET_CAR_REPORT_LINK_SUCCESS';
export const GET_CAR_REPORT_LINK_FAILURE = '@eventHistory//GET_CAR_REPORT_LINK_FAILURE';

export const GET_CAR_INFO_REQUEST = '@eventHistory//GET_CAR_INFO_REQUEST';
export const GET_CAR_INFO_SUCCESS = '@eventHistory//GET_CAR_INFO_SUCCESS';
export const GET_CAR_INFO_FAILURE = '@eventHistory//GET_CAR_INFO_FAILURE';

export const GET_CAR_MILEAGE_REQUEST = '@eventHistory//GET_CAR_MILEAGE_REQUEST';
export const GET_CAR_MILEAGE_SUCCESS = '@eventHistory//GET_CAR_MILEAGE_SUCCESS';
export const GET_CAR_MILEAGE_FAILURE = '@eventHistory//GET_CAR_MILEAGE_FAILURE';

export const GET_CAR_MILEAGE_STATISTICS_REQUEST = '@eventHistory//GET_CAR_MILEAGE_STATISTICS_REQUEST';
export const GET_CAR_MILEAGE_STATISTICS_SUCCESS = '@eventHistory//GET_CAR_MILEAGE_STATISTICS_SUCCESS';
export const GET_CAR_MILEAGE_STATISTICS_FAILURE = '@eventHistory//GET_CAR_MILEAGE_STATISTICS_FAILURE';

export const GET_CAR_REPORT_JOURNAL_EVENTS_REQUEST = '@eventHistory//GET_CAR_REPORT_JOURNAL_EVENTS_REQUEST';
export const GET_CAR_REPORT_JOURNAL_EVENTS_SUCCESS = '@eventHistory//GET_CAR_REPORT_JOURNAL_EVENTS_SUCCESS';
export const GET_CAR_REPORT_JOURNAL_EVENTS_FAILURE = '@eventHistory//GET_CAR_REPORT_JOURNAL_EVENTS_FAILURE';

export const GET_CAR_REPORT_FIELDS_REQUEST = '@eventHistory//GET_CAR_REPORT_FIELDS_REQUEST';
export const GET_CAR_REPORT_FIELDS_SUCCESS = '@eventHistory//GET_CAR_REPORT_FIELDS_SUCCESS';
export const GET_CAR_REPORT_FIELDS_FAILURE = '@eventHistory//GET_CAR_REPORT_FIELDS_FAILURE';

export const GET_CAR_REPORT_REFUELING_STATISTICS_REQUEST = '@eventHistory//GET_CAR_REPORT_REFUELING_STATISTICS_REQUEST';
export const GET_CAR_REPORT_REFUELING_STATISTICS_SUCCESS = '@eventHistory//GET_CAR_REPORT_REFUELING_STATISTICS_SUCCESS';
export const GET_CAR_REPORT_REFUELING_STATISTICS_FAILURE = '@eventHistory//GET_CAR_REPORT_REFUELING_STATISTICS_FAILURE';

export const GET_CAR_REPORT_REFUELING_SPENDING_REQUEST = '@eventHistory//GET_CAR_REPORT_REFUELING_SPENDING_REQUEST';
export const GET_CAR_REPORT_REFUELING_SPENDING_SUCCESS = '@eventHistory//GET_CAR_REPORT_REFUELING_SPENDING_SUCCESS';
export const GET_CAR_REPORT_REFUELING_SPENDING_FAILURE = '@eventHistory//GET_CAR_REPORT_REFUELING_SPENDING_FAILURE';

export const GET_CAR_REPORT_REFUELING_REQUEST = '@eventHistory//GET_CAR_REPORT_REFUELING_REQUEST';
export const GET_CAR_REPORT_REFUELING_SUCCESS = '@eventHistory//GET_CAR_REPORT_REFUELING_SUCCESS';
export const GET_CAR_REPORT_REFUELING_FAILURE = '@eventHistory//GET_CAR_REPORT_REFUELING_FAILURE';

export const GET_CAR_REPORT_GET_MONTH_MILEAGE_MONTH_DIFFERENCE_REQUEST =
    '@eventHistory//GET_CAR_REPORT_GET_MONTH_MILEAGE_MONTH_DIFFERENCE_REQUEST';
export const GET_CAR_REPORT_GET_MONTH_MILEAGE_MONTH_DIFFERENCE_SUCCESS =
    '@eventHistory//GET_CAR_REPORT_GET_MONTH_MILEAGE_MONTH_DIFFERENCE_SUCCESS';
export const GET_CAR_REPORT_GET_MONTH_MILEAGE_MONTH_DIFFERENCE_FAILURE =
    '@eventHistory//GET_CAR_REPORT_GET_MONTH_MILEAGE_MONTH_DIFFERENCE_FAILURE';

export const GET_CAR_REPORT_REFUELING_MONEY_SPENDING_STATISTICS_REQUEST =
    '@eventHistory//GET_CAR_REPORT_REFUELING_MONEY_SPENDING_STATISTICS_REQUEST';
export const GET_CAR_REPORT_REFUELING_MONEY_SPENDING_STATISTICS_SUCCESS =
    '@eventHistory//GET_CAR_REPORT_REFUELING_MONEY_SPENDING_STATISTICS_SUCCESS';
export const GET_CAR_REPORT_REFUELING_MONEY_SPENDING_STATISTICS_FAILURE =
    '@eventHistory//GET_CAR_REPORT_REFUELING_MONEY_SPENDING_STATISTICS_FAILURE';

export const GET_CAR_REPORT_ATTACHMENTS_REQUEST = '@eventHistory//GET_CAR_REPORT_ATTACHMENTS_REQUEST';
export const GET_CAR_REPORT_ATTACHMENTS_SUCCESS = '@eventHistory//GET_CAR_REPORT_ATTACHMENTS_SUCCESS';
export const GET_CAR_REPORT_ATTACHMENTS_FAILURE = '@eventHistory//GET_CAR_REPORT_ATTACHMENTS_FAILURE';
