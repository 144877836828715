import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import createRootReducer from '../reducers';

const persistConfig = {
    key: 'root',
    storage: sessionStorage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['spares', 'addCarPage', 'router', 'event', 'works', 'journal', 'eventHistory'],
};

const commonPersistConfig = {
    key: 'common',
    storage,
    blacklist: ['signupInfo', 'isLoading'],
};

const sparesPersistConfig = {
    key: 'spares',
    storage: sessionStorage,
    blacklist: ['reviews'],
};

const addCarPagePersistConfig = {
    key: 'addCarPage',
    storage: sessionStorage,
    blacklist: ['reviews, garage', 'checkedVin'],
};

const garagePersistConfig = {
    key: 'garage',
    storage: sessionStorage,
    blacklist: ['cars'],
};

const persistedReducer = persistReducer(
    persistConfig,
    createRootReducer(commonPersistConfig, sparesPersistConfig, addCarPagePersistConfig, garagePersistConfig),
);
export const store = createStore(
    persistedReducer,
    {},
    // composeWithDevTools(applyMiddleware(thunk, logger)),
    composeWithDevTools(applyMiddleware(thunk)),
);

export function storeWithInitialState(initialState) {
    return createStore(
        persistedReducer,
        initialState,
        // composeWithDevTools(applyMiddleware(thunk, logger)),
        composeWithDevTools(applyMiddleware(thunk)),
    );
}

export const persistor = persistStore(store);
