import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    container: {
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    appBar: {
        flexBasics: 'auto',
        flexWrap: 'wrap',
        width: '100%',
        backgroundColor: 'rgba(250, 250, 250, 0.8)',
    },
    text: {
        color: '#2B2B2B',
        fontSize: '1.09rem',
        fontFamily: 'Roboto-Light',
        textTransform: 'none',
        textDecoration: 'none',
        '@media(max-width:400px)': {
            fontSize: '1rem',
        },
        '@media(max-width:350px)': {
            fontSize: '0.88rem',
        },
    },
    dropdownItemText: {
        color: '#2B2B2B',
        fontSize: '1rem',
        fontFamily: 'Roboto-Light',
        textTransform: 'none',
    },
    addDropdown: {
        justifyContent: 'flex-end',
    },
    reviewsDropdown: {
        justifyContent: 'center',
        width: '100%',
        '& .MuiButton-text': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    reviewsDropdownButton: {
        width: '100%',
        height: 50,
    },
    tab: {
        display: 'flex',
        flexBasis: 'auto',
        zIndex: 10000,
        '&:hover $indicator': {
            width: '100%',
        },
    },
});

export default useStyles;
