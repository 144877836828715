import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Menu, MenuItem, Icon, Box } from '@material-ui/core';
import { usePopupState, bindMenu } from 'material-ui-popup-state/hooks';
import { get } from 'lodash';
import { CustomText } from '../Text';
import { getTabValue } from '../../utils/tab';
import useStyles from './styles';

export const NavDropdownMenu = ({
    options = [],
    titleStyle = null,
    textStyle = null,
    title = 'Добавить',
    menuIcon = null,
    isAuth = false,
    hasText = true,
}) => {
    const classes = useStyles();
    const location = useLocation();

    const popupState = usePopupState({ variant: 'popover', popupId: 'navMenu' });
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [state, setState] = useState({ timer: null, isClicked: false });

    function popupClose() {
        setState({ ...state, isClicked: false });
        popupState.close();
    }

    React.useEffect(() => {
        setSelectedIndex(getTabValue(location.pathname, isAuth).mobileIndex);
    }, [location]);

    function handleMenuItemClick(event, index) {
        event.preventDefault();
        setSelectedIndex(index);
        popupClose();
    }

    function buttonOut() {
        if (!state.isClicked) {
            const newTimer = setTimeout(popupClose, 200);
            setState({ timer: newTimer, isClicked: false });
        }
    }

    function clearTimer() {
        clearTimeout(state.timer);
    }

    function mouseEnter(event) {
        event.preventDefault();
        state.timer && clearTimeout(state.timer);
        popupState.open(event);
    }

    function buttonClicked(event) {
        event.preventDefault();
        state.timer && clearTimeout(state.timer);
        setState({ ...state, isClicked: true });
        popupState.open(event);
    }

    return (
        <Box className={classes.dropdown}>
            <Button
                onMouseOver={mouseEnter}
                onTouchEnd={buttonClicked}
                onBlur={() => {}}
                onFocus={() => {}}
                onMouseOut={buttonOut}
                disableRipple={state.isClicked}>
                {menuIcon && <Icon className={`${classes.icon} ${menuIcon}`} />}
                {hasText && <CustomText className={titleStyle} text={get(options, [selectedIndex, 'title'], title)} />}
            </Button>
            <Menu
                {...bindMenu(popupState)}
                MenuListProps={{ onMouseLeave: popupClose, onMouseOver: clearTimer }}
                className={classes.menu}>
                {options.map((option, index) => (
                    <MenuItem
                        key={option.title}
                        className={classes.menuItem}
                        onClick={event => handleMenuItemClick(event, index)}>
                        <Link className={classes.link} to={option.linkTo}>
                            <CustomText className={textStyle} text={option.title} />
                        </Link>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};
