export const GET_REFUELING_TYPES_REQUEST = '@refueling//GET_REFUELING_TYPES_REQUEST';
export const GET_REFUELING_TYPES_SUCCESS = '@refueling//GET_REFUELING_TYPES_SUCCESS';
export const GET_REFUELING_TYPES_FAILURE = '@refueling//GET_REFUELING_TYPES_FAILURE';

export const GET_REFUELING_TYPES_ROOT_REQUEST = '@refueling//GET_REFUELING_TYPES_ROOT_REQUEST';
export const GET_REFUELING_TYPES_ROOT_SUCCESS = '@refueling//GET_REFUELING_TYPES_ROOT_SUCCESS';
export const GET_REFUELING_TYPES_ROOT_FAILURE = '@refueling//GET_REFUELING_TYPES_ROOT_FAILURE';

export const GET_REFUELING_ORGANIZATIONS_REQUEST = '@refueling//GET_REFUELING_ORGANIZATIONS_REQUEST';
export const GET_REFUELING_ORGANIZATIONS_SUCCESS = '@refueling//GET_REFUELING_ORGANIZATIONS_SUCCESS';
export const GET_REFUELING_ORGANIZATIONS_FAILURE = '@refueling//GET_REFUELING_ORGANIZATIONS_FAILURE';

export const ADD_REFUELING_REQUEST = '@refueling//ADD_REFUELING_REQUEST';
export const ADD_REFUELING_SUCCESS = '@refueling//ADD_REFUELING_SUCCESS';
export const ADD_REFUELING_FAILURE = '@refueling//ADD_REFUELING_FAILURE';

export const GET_REFUELING_STATISTICS_REQUEST = '@refueling//GET_REFUELING_STATISTICS_REQUEST';
export const GET_REFUELING_STATISTICS_SUCCESS = '@refueling//GET_REFUELING_STATISTICS_SUCCESS';
export const GET_REFUELING_STATISTICS_FAILURE = '@refueling//GET_REFUELING_STATISTICS_FAILURE';

export const GET_REFUELING_CHARTS_DATA_REQUEST = '@refueling//GET_REFUELING_CHARTS_DATA_REQUEST';
export const GET_REFUELING_CHARTS_DATA_SUCCESS = '@refueling//GET_REFUELING_CHARTS_DATA_SUCCESS';
export const GET_REFUELING_CHARTS_DATA_FAILURE = '@refueling//GET_REFUELING_CHARTS_DATA_FAILURE';

export const GET_REFUELING_SPENDING_REQUEST = '@refueling//GET_REFUELING_SPENDING_REQUEST';
export const GET_REFUELING_SPENDING_SUCCESS = '@refueling//GET_REFUELING_SPENDING_SUCCESS';
export const GET_REFUELING_SPENDING_FAILURE = '@refueling//GET_REFUELING_SPENDING_FAILURE';

export const GET_LAST_REFUELING_REQUEST = '@refueling//GET_LAST_REFUELING_REQUEST';
export const GET_LAST_REFUELING_SUCCESS = '@refueling//GET_LAST_REFUELING_SUCCESS';
export const GET_LAST_REFUELING_FAILURE = '@refueling//GET_LAST_REFUELING_FAILURE';

export const GET_REFUELING_REQUEST = '@refueling//GET_REFUELING_REQUEST';
export const GET_REFUELING_SUCCESS = '@refueling//GET_REFUELING_SUCCESS';
export const GET_REFUELING_FAILURE = '@refueling//GET_REFUELING_FAILURE';

export const GET_MILEAGE_STATISTICS_REQUEST = '@refueling//GET_MILEAGE_STATISTICS_REQUEST';
export const GET_MILEAGE_STATISTICS_SUCCESS = '@refueling//GET_MILEAGE_STATISTICS_SUCCESS';
export const GET_MILEAGE_STATISTICS_FAILURE = '@refueling//GET_MILEAGE_STATISTICS_FAILURE';

export const GET_REFUELING_MONEY_SPENDING_STATISTICS_REQUEST =
    '@refueling//GET_REFUELING_MONEY_SPENDING_STATISTICS_REQUEST';
export const GET_REFUELING_MONEY_SPENDING_STATISTICS_SUCCESS =
    '@refueling//GET_REFUELING_MONEY_SPENDING_STATISTICS_SUCCESS';
export const GET_REFUELING_MONEY_SPENDING_STATISTICS_FAILURE =
    '@refueling//GET_REFUELING_MONEY_SPENDING_STATISTICS_FAILURE';
