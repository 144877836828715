export const USER_UPDATE_INFO_REQUEST = 'USER_UPDATE_INFO_REQUEST';
export const USER_UPDATE_INFO_SUCCESS = 'USER_UPDATE_INFO_SUCCESS';
export const USER_UPDATE_INFO_FAILURE = 'USER_UPDATE_INFO_FAILURE';

export const USER_UPDATE_AVATAR_REQUEST = 'USER_UPDATE_AVATAR_REQUEST';
export const USER_UPDATE_AVATAR_SUCCESS = 'USER_UPDATE_AVATAR_SUCCESS';
export const USER_UPDATE_AVATAR_FAILURE = 'USER_UPDATE_AVATAR_FAILURE';

export const USER_ADD_CONTACT_REQUEST = 'USER_ADD_CONTACT_REQUEST';
export const USER_ADD_CONTACT_SUCCESS = 'USER_ADD_CONTACT_SUCCESS';
export const USER_ADD_CONTACT_FAILURE = 'USER_ADD_CONTACT_FAILURE';

export const USER_UPDATE_CONTACT_REQUEST = 'USER_UPDATE_CONTACT_REQUEST';
export const USER_UPDATE_CONTACT_SUCCESS = 'USER_UPDATE_CONTACT_SUCCESS';
export const USER_UPDATE_CONTACT_FAILURE = 'USER_UPDATE_CONTACT_FAILURE';

export const USER_DELETE_CONTACT_REQUEST = 'USER_DELETE_CONTACT_REQUEST';
export const USER_DELETE_CONTACT_SUCCESS = 'USER_DELETE_CONTACT_SUCCESS';
export const USER_DELETE_CONTACT_FAILURE = 'USER_DELETE_CONTACT_FAILURE';

export const GET_CONTACTS_REQUEST = 'GET_CONTACTS_REQUEST';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const GET_CONTACTS_FAILURE = 'GET_CONTACTS_FAILURE';

export const GET_COUNTRIES_REQUEST = '@@common/GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_FAILURE = '@@common/GET_COUNTRIES_FAILURE';
export const GET_COUNTRIES_SUCCESS = '@@common/GET_COUNTRIES_SUCCESS';

export const GET_REGIONS_REQUEST = 'GET_REGIONS_REQUEST';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
export const GET_REGIONS_FAILURE = 'GET_REGIONS_FAILURE';

export const GET_CITIES_REQUEST = 'GET_CITIES_REQUEST';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE';
