import React from 'react';
import { colors } from 'styles/colors';

export function GarageIcon({
    width = 31,
    height = 31,
    colorPrimary = colors.primary,
    colorSecondary = colors.secondary,
}) {
    return (
        <svg width={width} height={height} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={width} height={height} fill="none" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.1419 9.44873C49.0896 8.85446 50.2841 8.85016 51.2359 9.43757L89.5522 33.0869C90.5233 33.6863 90.9692 34.6341 90.9859 35.5809C90.9952 35.6784 91 35.7772 91 35.8771V87.9789C91 89.6474 89.6716 91 88.033 91H85C83.3431 91 82 89.6569 82 88V46C82 44.3431 80.6568 43 79 43H21C19.3431 43 18 44.3431 18 46V88C18 89.6569 16.6568 91 15 91H11.967C10.3284 91 9 89.6474 9 87.9789V35.8771C9 35.7718 9.00531 35.6678 9.01563 35.5652C9.03688 34.6303 9.47757 33.6961 10.4311 33.0981L48.1419 9.44873Z"
                fill={colorSecondary}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.6195 50.615C36.136 49.6226 37.1618 49 38.2806 49H61.7194C62.8382 49 63.864 49.6226 64.3805 50.615L67.1832 56H69.2651H75.2831C76.3245 56 77.1687 56.8442 77.1687 57.8856V58.2451C77.1687 61.3108 73.5843 62.9766 71.241 61L71.0434 60.8L71.8762 63.048C74.3084 63.3865 76.1807 65.4746 76.1807 68V69V83C76.1807 84.1046 75.2853 85 74.1807 85H67.3012C66.7489 85 66.3012 84.5523 66.3012 84V81C66.3012 80.4477 65.8535 80 65.3012 80H34.6988C34.1465 80 33.6988 80.4477 33.6988 81V84C33.6988 84.5523 33.2511 85 32.6988 85H25.8193C24.7147 85 23.8193 84.1046 23.8193 83V69V68C23.8193 65.4746 25.6916 63.3865 28.1238 63.048L28.9566 60.8L28.759 61C26.4156 62.9766 22.8313 61.3108 22.8313 58.2451V57.8856C22.8313 56.8442 23.6755 56 24.7169 56H30.7349H32.8168L35.6195 50.615ZM38.977 50C37.8072 50 36.7441 50.68 36.2535 51.742L33.98 56.6639C33.7948 57.0648 33.7004 57.5017 33.7036 57.9434L33.7183 60H66.2817L66.2964 57.9434C66.2996 57.5017 66.2052 57.0648 66.02 56.6639L63.7465 51.742C63.2559 50.68 62.1928 50 61.023 50H38.977Z"
                fill={colorSecondary}
            />
            <path
                d="M27.7711 68.7505C27.7711 67.3288 29.0735 66.2637 30.4669 66.5457L36.3277 67.7322C37.0973 67.888 37.6506 68.5645 37.6506 69.3497V69.3497C37.6506 70.2611 36.9117 71 36.0003 71H30.0205C28.7782 71 27.7711 69.9929 27.7711 68.7505V68.7505Z"
                fill={colorPrimary}
            />
            <path
                d="M72.2289 68.7505C72.2289 67.3288 70.9265 66.2637 69.5331 66.5457L63.6722 67.7322C62.9026 67.888 62.3494 68.5645 62.3494 69.3497V69.3497C62.3494 70.2611 63.0882 71 63.9997 71H69.9794C71.2217 71 72.2289 69.9929 72.2289 68.7505V68.7505Z"
                fill={colorPrimary}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.6296 71.1333C39.6128 70.8882 39.6414 70.6498 39.7084 70.4266H60.2916C60.3586 70.6498 60.3872 70.8882 60.3704 71.1333H39.6296ZM39.7386 71.8333L39.8536 72.5072H60.1464L60.2613 71.8333H39.7386ZM39.995 73.3362L39.9729 73.2072H60.027L60.005 73.3362C59.9826 73.4674 59.9478 73.5937 59.9019 73.7141H40.0981C40.0522 73.5937 40.0173 73.4674 39.995 73.3362ZM40.5521 74.4141C40.9167 74.7785 41.4192 75 41.9665 75H58.0335C58.5808 75 59.0833 74.7785 59.4478 74.4141H40.5521ZM59.9168 69.7266H40.0832C40.4472 69.2858 40.9981 69 41.6254 69H58.3746C59.0019 69 59.5528 69.2858 59.9168 69.7266Z"
                fill="white"
            />
        </svg>
    );
}
