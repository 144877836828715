export const GET_JOB_HIERARCHY_REQUEST = 'GET_JOB_HIERARCHY_REQUEST';
export const GET_JOB_HIERARCHY_FAILURE = 'GET_JOB_HIERARCHY_FAILURE';
export const GET_JOB_HIERARCHY_SUCCESS = 'GET_JOB_HIERARCHY_SUCCESS';

export const GET_JOB_HIERARCHY_COMPANY_REQUEST = 'GET_JOB_HIERARCHY_COMPANY_REQUEST';
export const GET_JOB_HIERARCHY_COMPANY_FAILURE = 'GET_JOB_HIERARCHY_COMPANY_FAILURE';
export const GET_JOB_HIERARCHY_COMPANY_SUCCESS = 'GET_JOB_HIERARCHY_COMPANY_SUCCESS';

export const GET_ROOT_CATEGORIES_REQUEST = '@autoservice//GET_ROOT_CATEGORIES_REQUEST';
export const GET_ROOT_CATEGORIES_SUCCESS = '@autoservice//GET_ROOT_CATEGORIES_SUCCESS';
export const GET_ROOT_CATEGORIES_FAILURE = '@autoservice//GET_ROOT_CATEGORIES_FAILURE';
