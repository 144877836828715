const useStyles = {
    container: {},
    fields: {
        width: '100%',
        marginTop: 5,
        '& .MuiInputBase-input': {
            fontSize: 16,
            fontFamily: 'Roboto-Medium',
            padding: '6px',
        },
    },
    buttons: {
        width: '160px',
        fontFamily: 'Roboto-Medium',
    },
    input: {
        '& .MuiFormLabel-root': {
            color: 'rgba(0, 0, 0, 0.54)',
        },
    },
    spinner: {
        color: '#0e444d',
        alignSelf: 'center',
        marginTop: 20,
    },
    countdownError: {
        fontSize: '0.85rem',
        color: '#f44336',
    },
};

export default useStyles;
