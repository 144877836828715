export const getTabValue = (pathname, isAuth) => {
    switch (pathname) {
        case '/':
            return {
                value: 0,
                mobileIndex: 0,
            };
        case '/garage':
            return {
                value: 1,
                mobileIndex: 1,
            };
        case '/car/add':
            return {
                value: 1,
                mobileIndex: 1,
            };
        case '/feedback/cars':
            return {
                value: isAuth ? 2 : 1,
                mobileIndex: isAuth ? 2 : 1,
            };
        case '/feedback/spares':
            return {
                value: isAuth ? 2 : 1,
                mobileIndex: isAuth ? 3 : 2,
            };
        case '/feedback/rules':
            return {
                value: isAuth ? 2 : 1,
                mobileIndex: 0,
            };
        case '/services':
            return {
                value: isAuth ? 3 : 2,
                mobileIndex: isAuth ? 4 : 3,
            };
        case '/promotions':
            return {
                value: isAuth ? 4 : 3,
                mobileIndex: isAuth ? 5 : 4,
            };
        default:
            return {
                value: false,
                mobileIndex: 0,
            };
    }
};
