import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    menuContainer: {
        position: 'static',
    },
    clientNumber: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        fontFamily: 'Roboto-Light',
        fontSize: '1.05rem',
        color: '#2B2B2B',
        textTransform: 'capitalize',
    },
    profileIconWithName: {
        minHeight: 60,
        border: '0px solid #d3d4d5',
        borderBottomWidth: 1,
        zIndex: 3000000000,
        cursor: 'initial',
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
    optionTab: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    icon: {
        '&:hover': {
            color: '#0dabc2',
        },
    },
    link: {
        width: '100%',
        height: '100%',
        margin: '-12px -16px',
        padding: '12px 16px',
        textDecoration: 'none',
    },
    menu: {
        '& .MuiList-padding': {
            paddingTop: 0,
        },
    },
    hover: {
        zIndex: 5000,
    },
    out: {},
});

export default useStyles;
