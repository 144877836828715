import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getEventStatuses } from '../actions/event';

export const EVENT_STATUSES_KEYS = {
    SELF_SERVICE: 'SELF_SERVICE',
    REPAIR_REQUEST: 'REPAIR_REQUEST',
    REQUEST_CONFIRMED: 'REQUEST_CONFIRMED',
    REFUSED: 'REFUSED',
    IN_PROGRESS: 'IN_PROGRESS',
    REGISTRATION: 'REGISTRATION',
    PAYMENT_WAITING: 'PAYMENT_WAITING',
    PAYED: 'PAYED',
    INDEBTED: 'INDEBTED',
    FINISHED: 'FINISHED',
};

export const EVENT_STATUSES = {
    SELF_SERVICE: 'Самостоятельное обслуживание',
    REPAIR_REQUEST: 'Заявка на обслуживание',
    REQUEST_CONFIRMED: 'Запрос подтверждён',
    REFUSED: 'Отказ',
    IN_PROGRESS: 'В работе',
    REGISTRATION: 'Регистрация',
    PAYMENT_WAITING: 'Ожидание платежа',
    PAYED: 'Оплачено',
    INDEBTED: 'Задолженность',
    FINISHED: 'Готово',
};

export const EVENT_STATUSES_ICON = {
    SELF_SERVICE: 'СО',
    REPAIR_REQUEST: 'ЗО',
    REQUEST_CONFIRMED: 'ЗП',
    REFUSED: 'ОТ',
    IN_PROGRESS: 'ВР',
    REGISTRATION: 'ОД',
    PAYMENT_WAITING: 'ОП',
    PAYED: 'ОП',
    INDEBTED: 'ЗД',
    FINISHED: 'ГО',
};

export const EVENT_STATUSES_TOOLTIP = {
    SELF_SERVICE: 'СО - Самостоятельное Обслуживание. Обслуживание выполнялось самостоятельно.',
    REPAIR_REQUEST: 'ЗО - Заявка на обслуживание',
    REQUEST_CONFIRMED: 'ЗП - Заявка подтверждена',
    REFUSED: 'ОТ - Отказ',
    IN_PROGRESS: 'ВР - В работе',
    REGISTRATION: 'ОД - Оформление документов',
    PAYMENT_WAITING: 'ОП - Ожидание платежа',
    PAYED: 'ОП - Оплачено',
    INDEBTED: 'ЗД - Задолженность',
    FINISHED: 'ГО - Готово',
};

const eventStatusesSelector = state => state.event;

const statusesSelector = createSelector(eventStatusesSelector, items => items.eventStatuses);

export function useEventStatuses() {
    const eventStatuses = useSelector(statusesSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEventStatuses());
    }, []);

    return eventStatuses;
}
