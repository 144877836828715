import {
    GET_ALL_PROMOTIONS_REQUEST,
    GET_ALL_PROMOTIONS_SUCCESS,
    GET_ALL_PROMOTIONS_FAILURE,
    GET_PROMOTIONS_FILTERED_AMOUNT_REQUEST,
    GET_PROMOTIONS_FILTERED_AMOUNT_SUCCESS,
    GET_PROMOTIONS_FILTERED_AMOUNT_FAILURE,
} from '../constants/promotions';

const initialState = {
    isLoading: false,
    error: null,
    all: null,
    categorySales: null,
    filteredAmount: 0
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PROMOTIONS_REQUEST:
        case GET_PROMOTIONS_FILTERED_AMOUNT_REQUEST:
            return { ...state, isLoading: true };
        case GET_ALL_PROMOTIONS_FAILURE:
        case GET_PROMOTIONS_FILTERED_AMOUNT_FAILURE:
            return { ...state, error: action.payload, isLoading: false };
        case GET_ALL_PROMOTIONS_SUCCESS:
            return { ...state, all: action.payload };
        case GET_PROMOTIONS_FILTERED_AMOUNT_SUCCESS:
            return { ...state, filteredAmount: action.payload };
        default:
            return state;
    }
};

export default reducer;
