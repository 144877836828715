import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import serviceStation from './serviceStation';
import common from './common';
import addCarPage from './addCarPage';
import mainPage from './mainPage';
import garage from './garage';
import spares from './spares';
import profile from './profile';
import autoservices from './autoservices';
import calendar from './calendar';
import jobTree from './jobTree';
import users from './users';
import mileage from './mileage';
import promotions from './promotions';
import journal from './journal';
import works from './works';
import refueling from './refueling';
import event from './event';
import auto from './auto';
import eventHistory from './eventHistory';
import { LOGOUT_SUCCESS } from '../constants/authFlow';

export default (commonPersistConfig, sparesPersistConfig, addCarPagePersistConfig, garagePersistConfig) => {
    const appReducer = combineReducers({
        serviceStation,
        common: persistReducer(commonPersistConfig, common),
        addCarPage: persistReducer(addCarPagePersistConfig, addCarPage),
        mainPage,
        spares: persistReducer(sparesPersistConfig, spares),
        garage: persistReducer(garagePersistConfig, garage),
        profile,
        autoservices,
        calendar,
        jobTree,
        users,
        mileage,
        promotions,
        journal,
        works,
        refueling,
        event,
        auto,
        eventHistory,
    });
    const rootReducer = (state, action) => {
        if (action.type === LOGOUT_SUCCESS) {
            // eslint-disable-next-line no-param-reassign
            state = undefined;
        }

        return appReducer(state, action);
    };

    return rootReducer;
};
