import { createSelector } from 'reselect';
import { get } from 'lodash';

export const carReportLastRefuelingSelector = createSelector(
    state => state.eventHistory,
    eventHistory => eventHistory.lastRefueling,
);

export const carReportRefuelingStatisticsInfoSelector = createSelector(
    state => state.eventHistory,
    eventHistory => {
        return [
            eventHistory.avgSpending || '-',
            get(eventHistory.statistics, 'liters', '-'),
            get(eventHistory.mileageStatistics, 'mileage', '-'),
            get(eventHistory.moneySpendingStatistics, 'cost', '-'),
        ];
    },
);

export const carReportLinkSelector = createSelector(
    state => state.eventHistory,
    eventHistory => eventHistory.carReportLink,
);

export const carReportAttachmentsSelector = createSelector(
    state => state.eventHistory,
    eventHistory => {
        return {
            attachments: eventHistory.attachments,
            isLoadingAttachments: eventHistory.isLoadingAttachments,
            attachmentsError: eventHistory.attachmentsError,
        };
    },
);

export const carForReportSelector = createSelector(
    state => state.eventHistory,
    eventHistory => {
        return {
            isLoadingCarInfo: eventHistory.isLoadingCarInfo,
            carInfoError: eventHistory.carInfoError,
            carInfo: eventHistory.carInfo,
        };
    },
);

export const carMileageStatisticsForReportSelector = createSelector(
    state => state.eventHistory,
    eventHistory => {
        return {
            isLoadingCarMileageStatistics: eventHistory.isLoadingCarMileageStatistics,
            carMileageStatisticsError: eventHistory.carMileageStatisticsError,
            carMileageStatistics: eventHistory.carMileageStatistics,
        };
    },
);

export const carMileageForReportSelector = createSelector(
    state => state.eventHistory,
    eventHistory => {
        return {
            isLoadingCarMileage: eventHistory.isLoadingCarMileage,
            carMileageError: eventHistory.carMileageError,
            carMileage: eventHistory.carMileage,
        };
    },
);

export const carEventsForReportSelector = createSelector(
    state => state.eventHistory,
    eventHistory => {
        return {
            isLoadingCarEvents: eventHistory.isLoadingCarEvents,
            carEventsError: eventHistory.carEventsError,
            carEvents: eventHistory.carEvents,
        };
    },
);

export const carFieldsForReportSelector = createSelector(
    state => state.eventHistory,
    eventHistory => {
        return {
            isLoadingCarFields: eventHistory.isLoadingCarFields,
            carFieldsError: eventHistory.carFieldsError,
            carFields: eventHistory.carFields,
        };
    },
);

export const carInfoWithMileageForReportSelector = createSelector(
    carForReportSelector,
    carMileageStatisticsForReportSelector,
    carMileageForReportSelector,
    carEventsForReportSelector,
    carFieldsForReportSelector,
    carReportAttachmentsSelector,
    (carInfo, carMileageStatistics, carMileage, carEvents, carFields, carAttachments) => ({
        ...carInfo,
        ...carMileageStatistics,
        ...carMileage,
        ...carEvents,
        ...carFields,
        ...carAttachments,
    }),
);
