import {
  GET_SPARES_BRANDS,
  ADD_SPARES_REVIEW_COMMENT,
  GET_SPARES_REVIEW_COMMENTS
} from '../constants/spares';

const initialState = { brands: [] };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPARES_BRANDS:
      return { ...state, brands: action.brands };
    case GET_SPARES_REVIEW_COMMENTS:
      return { ...state, reviews: action.comments };
    case ADD_SPARES_REVIEW_COMMENT:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

export default reducer;
