import React from 'react';

export function FuelIcon({ width = 31, height = 31, color = '#3AAAB9' }) {
    return (
        <svg width={width} height={height} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={width} height={height} fill="none" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M88.5071 26.7714C88.4068 26.6414 88.2963 26.5168 88.1757 26.3985L70.2863 8.85875C68.8692 7.46941 66.5942 7.49187 65.2049 8.9089C63.8156 10.3259 63.838 12.601 65.255 13.9903L75.4178 23.9545L75.3582 23.9422C70.7436 25.7022 67.4545 30.1022 67.4545 35.3333C67.4545 42.08 72.9527 47.5556 79.7273 47.5556C81.4945 47.5556 83.1145 47.1644 84.6364 46.5289V81.7778C84.6364 84.4667 82.4273 86.6667 79.7273 86.6667C77.0273 86.6667 74.8182 84.4667 74.8182 81.7778V55.3041C74.8182 49.9984 71.491 49.999 66.216 50L66 50H60.0909V15.7778C60.0909 10.4 55.6727 6 50.2727 6H20.8182C15.4182 6 11 10.4 11 15.7778V83H60.0909V57.3333H67.4545V81.7778C67.4545 88.5244 72.9527 94 79.7273 94C86.5018 94 92 88.5244 92 81.7778V35.3333C92 32.0064 90.663 28.9649 88.5071 26.7714ZM21 15H50V41H21V15ZM63 86H8V94H63V86Z"
                fill={color}
            />
        </svg>
    );
}
