import axios from 'axios';
import { get } from 'lodash';
import Cookies from 'js-cookie';
import axiosRetry from 'axios-retry';
// import { setupCache } from 'axios-cache-adapter';
import { EMPTY_RESPONSE } from '../constants';
import {
    CONFLICT_STATUS_CODE,
    MULTIPLE_CHOICES_STATUS_CODE,
    NOT_FOUND,
    UNAUTHORIZED_STATUS_CODE,
} from './apiConstants';
import { store } from '../store';
import { restoreToken } from '../actions/authFlow';

// // Create `axios-cache-adapter` instance
// const cache = setupCache({
//     maxAge: 15 * 60 * 1000,
//     exclude: { methods: ['put', 'patch', 'delete', 'post'], query: false },
// });

const api = axios.create({
    responseType: 'json',
    timeout: 45000,
    withCredentials: true,
    credentials: 'include',
});

axiosRetry(api, {
    retries: 2,
    shouldResetTimeout: true,
    retryCondition: error => {
        if (error.message.includes('401')) {
            return true;
        }
        return false;
    },
    retryDelay: retryCount => {
        return retryCount * 1000;
    },
});

export const updateAuthTokens = async () => {
    return restoreToken()(store.dispatch);
};

api.interceptors.response.use(
    response => {
        if (get(response, 'status', EMPTY_RESPONSE) >= MULTIPLE_CHOICES_STATUS_CODE) {
            return Promise.reject(response.data.messages ? response.data.messages : 'api.incorrectResponse');
        }
        if (get(response, 'status', EMPTY_RESPONSE) === CONFLICT_STATUS_CODE) {
            return Promise.reject(response.data.messages ? response.data.messages : 'api.alreadyExists');
        }
        if (get(response, 'status', EMPTY_RESPONSE) === NOT_FOUND) {
            return Promise.reject(response.data.messages ? response.data.messages : 'api.emailNotExist');
        }
        return response;
    },
    async error => {
        const originalRequest = error.config;
        if (get(error, 'response.status', EMPTY_RESPONSE) === UNAUTHORIZED_STATUS_CODE && !originalRequest.isRetry) {
            originalRequest.isRetry = true;
            const isSuccess = await restoreToken()(store.dispatch);
            if (!isSuccess) {
                return Promise.reject(error);
            }

            return api({
                ...error.config,
                json: true,
            });
        }
        return Promise.reject(error);
    },
);

api.interceptors.request.use(config => {
    const newConfig = { ...config };
    const token = Cookies.get('authKey');
    if (token) {
        newConfig.headers.Authorization = token;
    }

    return newConfig;
});

const authorizeApi = (token = null) => {
    api.defaults.headers.common.Authorization = token;
};

export { api, authorizeApi };
