import { useMediaQuery } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { lazy, Suspense, useEffect } from 'react';
import { withCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import { FavoriteCarProvider } from 'providers';
import { connect, useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { parseJson } from 'utils/json';
import { authorizeApi } from '../api';
import { getUserInfo, loginWithMobile } from '../actions/authFlow';
import { getToken, onMessageListener } from '../services/firebase';
import GlobalSlider from '../components/GlobalSlider';
import CustomSwitch from '../components/Router/Switch';
import InitialRoute from '../components/Routes/InitialRoute';
import PrivateRoute from '../components/Routes/PrivateRoute';
import ScrollToTop from '../components/ScrollToTop';
import SuspenseLoader from '../components/SuspenseLoader';
import Footer from './footer/Footer';
import Header from './header/Header';
import ErrorBoundary from '../components/ErrorBoundary';

const AddCar = lazy(() => import('./add-car/AddCar'));
const Promotions = lazy(() => import('./promotions'));
const Autoservices = lazy(() => import('./autoservices'));
const CarInfoPage = lazy(() => import('./car-info-section/page-section'));
const ComingSoon = lazy(() => import('./comingSoon/ComingSoon'));
const NotFound = lazy(() => import('./notFound'));
const FeedbackCars = lazy(() => import('./feedback/feedback-cars/FeedbackCars'));
const FeedbackSpares = lazy(() => import('./feedback/feedback-spares'));
const FeedbackRules = lazy(() => import('./feedback-rules/FeedbackRules'));
const Garage = lazy(() => import('./garage'));
const Invite = lazy(() => import('./registration/invite/Invite'));
const EventPage = lazy(() => import('./journal/event-journal'));
const JournalPage = lazy(() => import('./journal/journal-page/Page'));
const MainPage = lazy(() => import('./main-page/MainPage'));
const MainPageForBusiness = lazy(() => import('./main-page/MainPageForBusiness'));
const Profile = lazy(() => import('./profile'));
const Registration = lazy(() => import('./registration/Registration'));
const ForgotPassword = lazy(() => import('./forgot-password'));
const ResetPassword = lazy(() => import('./reset-password'));
const ServiceStation = lazy(() => import('./service-station'));
const TermsOfUse = lazy(() => import('./footer/footer-links/TermsOfUse'));
const PrivacyPolicy = lazy(() => import('./footer/footer-links/PrivacyPolicy'));
const PublicAgreement = lazy(() => import('./footer/footer-links/PublicAgreement'));
const ContactUs = lazy(() => import('./footer/footer-links/ContactUs'));
const AboutProject = lazy(() => import('./footer/footer-links/AboutProject'));
const WorkEvent = lazy(() => import('./works/Event'));
const EditWorkEvent = lazy(() => import('./works/EditEvent'));
const WorkRefill = lazy(() => import('./works/Refill'));
const WorkEditRefill = lazy(() => import('./works/EditRefill'));
const CarReport = lazy(() => import('./car-report/CarReport'));

const App = ({ cookies, isAuth, cars, servicePhotos, garagePhotos }) => {
    const dispatch = useDispatch();
    const isDesktop = useMediaQuery('(min-width:600px)');

    useEffect(() => {
        window.addEventListener('message', event => {
            if (get(parseJson(event.data), 'token')) {
                dispatch(loginWithMobile(JSON.parse(event.data)));
            }
        });
    }, []);

    useEffect(() => {
        authorizeApi(Cookies.get('authKey'));
    }, [Cookies, Cookies.get('authKey')]);

    useEffect(() => {
        (async () => {
            const authKey = Cookies.get('authKey');
            if (authKey && isAuth) {
                dispatch(getUserInfo(authKey));
                const fbToken = await getToken();
                if (fbToken) {
                    onMessageListener()
                        .then(payload => {
                            const hasAction =
                                payload.notification.actions &&
                                payload.notification.actions.length &&
                                payload.notification.actions[0].action;
                            NotificationManager.info(
                                payload.notification.body,
                                payload.notification.title,
                                hasAction ? 15000 : 5000,
                                () => {
                                    if (hasAction) {
                                        window.open(payload.notification.actions[0].action, '_blank');
                                    }
                                },
                            );
                        })
                        .catch(err => console.log('failed: ', err));
                }
            }
        })();
    }, [isAuth, cookies, Cookies.get('authKey')]);

    return (
        <Router>
            <Route
                render={({ location }) => (
                    <>
                        {!location.pathname.startsWith('/car/report') && (
                            <ScrollToTop isAuth={isAuth} garagePhotos={garagePhotos} />
                        )}
                        {!location.pathname.startsWith('/car/report') && isDesktop && (
                            <GlobalSlider isAuth={isAuth} servicePhotos={servicePhotos} garagePhotos={garagePhotos} />
                        )}
                        {!location.pathname.startsWith('/car/report') && <Header cookies={cookies} />}
                        <TransitionGroup>
                            <CSSTransition key={location.key} timeout={750} classNames="fade">
                                <Suspense fallback={SuspenseLoader}>
                                    <ErrorBoundary>
                                        <FavoriteCarProvider isAuth={isAuth}>
                                            <CustomSwitch location={location}>
                                                <InitialRoute
                                                    cars={cars}
                                                    isAuth={isAuth}
                                                    exact
                                                    path="/"
                                                    component={MainPage}
                                                />
                                                <Route exact path="/b2b" component={MainPageForBusiness} />
                                                <Route path="/service/station/:id" component={ServiceStation} />
                                                <Route
                                                    exact
                                                    path="/registration"
                                                    render={() => <Registration cookies={cookies} />}
                                                />
                                                <Route exact path="/services" component={Autoservices} />
                                                <Route
                                                    path="/feedback/cars"
                                                    render={() => <FeedbackCars cookies={cookies} />}
                                                />
                                                <PrivateRoute path="/garage" component={Garage} isAuth={isAuth} />
                                                <PrivateRoute path="/car/add" component={AddCar} isAuth={isAuth} />
                                                <PrivateRoute
                                                    path="/car/info"
                                                    component={CarInfoPage}
                                                    isAuth={isAuth}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path="/service/journal"
                                                    component={JournalPage}
                                                    isAuth={isAuth}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path="/service/journal/repair"
                                                    component={EventPage}
                                                    isAuth={isAuth}
                                                />
                                                <PrivateRoute
                                                    path="/feedback/rules"
                                                    component={FeedbackRules}
                                                    isAuth={isAuth}
                                                />
                                                <Route path="/about" component={AboutProject} />
                                                <Route path="/terms" component={TermsOfUse} />
                                                <Route path="/privacy" component={PrivacyPolicy} />
                                                <Route path="/contact" component={ContactUs} />
                                                <Route path="/agreement" component={PublicAgreement} />
                                                <PrivateRoute path="/profile" component={Profile} isAuth={isAuth} />
                                                <Route path="/information" component={ComingSoon} />
                                                <Route path="/news" component={ComingSoon} />
                                                <Route
                                                    path="/feedback/spares"
                                                    render={() => <FeedbackSpares cookies={cookies} />}
                                                />
                                                <Route exact path="/password/forgot" component={ForgotPassword} />
                                                <Route path="/password/reset" component={ResetPassword} />
                                                <Route path="/invite" render={() => <Invite cookies={cookies} />} />
                                                <Route exact path="/promotions" component={Promotions} />
                                                <PrivateRoute
                                                    exact
                                                    path="/works/refill"
                                                    component={WorkRefill}
                                                    isAuth={isAuth}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path="/works/refill/edit"
                                                    component={WorkEditRefill}
                                                    isAuth={isAuth}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path="/works/event"
                                                    component={WorkEvent}
                                                    isAuth={isAuth}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path="/works/event/edit"
                                                    component={EditWorkEvent}
                                                    isAuth={isAuth}
                                                />
                                                <Route path="/car/report/:token" component={CarReport} />
                                                <Route path="*" component={NotFound} />
                                            </CustomSwitch>
                                        </FavoriteCarProvider>
                                    </ErrorBoundary>
                                    {!location.pathname.startsWith('/car/report') && <Footer />}
                                </Suspense>
                            </CSSTransition>
                        </TransitionGroup>
                    </>
                )}
            />
        </Router>
    );
};

App.propTypes = {
    cookies: PropTypes.objectOf(PropTypes.any),
};

App.defaultProps = {
    cookies: {},
};

const mapStateToProps = state => {
    return {
        isAuth: state.common.isAuth,
        cars: state.garage.cars,
        servicePhotos: state.serviceStation.photos,
        garagePhotos: get(state, 'garage.gallery.content', []),
    };
};

export default withCookies(connect(mapStateToProps)(App));
