export const GET_USERS_VEHICLES = 'GET_USER_VEHICLES';
export const CHANGE_FAVORITE_CAR = 'CHANGE_FAVORITE_CAR';

export const GET_AUTO_ATTACHMENT_REQUEST = 'GET_AUTO_ATTACHMENT_REQUEST';
export const GET_AUTO_ATTACHMENT_FAILURE = 'GET_AUTO_ATTACHMENT_FAILURE';
export const GET_AUTO_ATTACHMENT_SUCCESS = 'GET_AUTO_ATTACHMENT_SUCCESS';

export const ADD_AUTO_ATTACHMENT_REQUEST = 'ADD_AUTO_ATTACHMENT_REQUEST';
export const ADD_AUTO_ATTACHMENT_FAILURE = 'ADD_AUTO_ATTACHMENT_FAILURE';
export const ADD_AUTO_ATTACHMENT_SUCCESS = 'ADD_AUTO_ATTACHMENT_SUCCESS';

export const DELETE_AUTO_ATTACHMENT_REQUEST = 'DELETE_AUTO_ATTACHMENT_REQUEST';
export const DELETE_AUTO_ATTACHMENT_FAILURE = 'DELETE_AUTO_ATTACHMENT_FAILURE';
export const DELETE_AUTO_ATTACHMENT_SUCCESS = 'DELETE_AUTO_ATTACHMENT_SUCCESS';

export const DELETE_AUTO_REQUEST = 'DELETE_AUTO_REQUEST';
export const DELETE_AUTO_FAILURE = 'DELETE_AUTO_FAILURE';
export const DELETE_AUTO_SUCCESS = 'DELETE_AUTO_SUCCESS';

export const SELL_AUTO_REQUEST = 'SELL_AUTO_REQUEST';
export const SELL_AUTO_FAILURE = 'SELL_AUTO_FAILURE';
export const SELL_AUTO_SUCCESS = 'SELL_AUTO_SUCCESS';
