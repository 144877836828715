import React from 'react';

export function HomeIcon({ width = 31, height = 31, color = '#3AAAB9' }) {
    return (
        <svg width={width} height={height} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={height} height={height} fill="none" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M91.4477 53.8328C92.5252 52.6505 92.4855 50.8308 91.3575 49.6966L51.7646 9.88454C50.5918 8.70515 48.6832 8.70515 47.5103 9.88454L7.87284 49.7414C6.7631 50.8573 6.70428 52.6411 7.7381 53.8276L9.04155 55.3237C10.1854 56.6365 12.2028 56.703 13.4306 55.4684L47.5103 21.2001C48.6832 20.0207 50.5918 20.0207 51.7646 21.2001L85.7685 55.3921C86.9776 56.6079 88.958 56.5648 90.113 55.2974L91.4477 53.8328Z"
                fill={color}
            />
            <path
                d="M51.0339 25.5662C50.2571 24.8087 49.018 24.8087 48.2412 25.5662L20.3536 52.7619C19.9676 53.1383 19.7499 53.6546 19.7499 54.1937V88.0403C19.7499 89.1448 20.6454 90.0403 21.7499 90.0403H39.5215C40.6261 90.0403 41.5215 89.1448 41.5215 88.0403V66.4375C41.5215 65.3329 42.417 64.4375 43.5215 64.4375H55.7536C56.8582 64.4375 57.7536 65.3329 57.7536 66.4375V88.0403C57.7536 89.1448 58.649 90.0403 59.7536 90.0403H77.5252C78.6298 90.0403 79.5252 89.1448 79.5252 88.0403V54.1937C79.5252 53.6546 79.3075 53.1383 78.9215 52.7619L51.0339 25.5662Z"
                fill={color}
            />
        </svg>
    );
}
