import React from 'react';
import { components } from 'react-select';
import useStyles from './styles';

const Option = ({ children, ...props }) => {
  const classes = useStyles();

  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = Object.assign(props, { innerProps: rest });
  return (
    <components.Option {...newProps} className={classes.option}>
      {children}
    </components.Option>
  );
};

export default Option;
