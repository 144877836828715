import { createSelector } from 'reselect';

export const carOptionsSelector = createSelector(
    state => state.addCarPage,
    addCarPage => {
        return {
            carMarks: addCarPage.marks,
            carModels: addCarPage.models,
            carGenerations: addCarPage.generations,
            carSeries: addCarPage.series,
            carModifications: addCarPage.modifications,
        };
    },
);
