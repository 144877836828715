import { Avatar, Button, Icon, ListItemText, Box } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { usePopupState, bindTrigger, bindMenu, bindHover } from 'material-ui-popup-state/hooks';
import { CustomText } from '../Text';
import TEXT from '../../constants/text';
import { PROFILE_DROPDOWN } from '../../constants/dropdown';
import { StyledMenu, StyledMenuItem } from './StyledMenu';
import useStyles from './styles';

function ProfileDropdown({ clientNumber, avatar, iconStyle, logout }) {
    const popupState = usePopupState({ variant: 'popover', popupId: 'profileMenu' });

    const classes = useStyles();

    const anchorEl = React.useRef(null);
    const [state, setState] = React.useState({ timer: null, isHovering: false, isScrolled: false });

    function changeRef(element) {
        anchorEl.current = element;
    }

    React.useEffect(() => {
        if (!state.isHovering && !state.isScrolled && state.timer) {
            clearTimeout(state.timer);
            popupState.close();
        }
    }, [state]);

    function popupClose() {
        clearTimeout(state.timer);
        setState({ ...state, isHovering: false, isScrolled: false });
        popupState.close();
    }

    function buttonOut() {
        const newTimer = setTimeout(() => popupClose(), 200);
        setState({ ...state, timer: newTimer, isHovering: true });
    }

    function clearTimer() {
        !state.isScrolled && clearTimeout(state.timer);
    }

    function mouseEnter(event) {
        state.timer && clearTimeout(state.timer);
        popupState.open(event);
    }

    function onScroll(event) {
        if (event.nativeEvent.wheelDelta <= 0) {
            setState({ ...state, isScrolled: true });
            popupState.close();
        }
    }

    return (
        <div onMouseLeave={popupState.close}>
            <Button
                ref={changeRef}
                onMouseOver={mouseEnter}
                onBlur={() => {}}
                onFocus={() => {}}
                onWheel={onScroll}
                onMouseOut={buttonOut}
                classes={{ root: state.isHovering ? classes.hover : classes.out }}>
                <Icon
                    {...bindHover(popupState)}
                    {...bindTrigger(popupState)}
                    className={`${classes.icon} ${iconStyle} fas fa-user-circle account-icon`}
                    style={popupState.isOpen ? { color: '#0dabc2' } : { color: '#00606D' }}
                />
            </Button>
            <Box className={classes.menuContainer} {...bindHover(popupState)}>
                <StyledMenu
                    {...bindMenu(popupState)}
                    MenuListProps={{ onMouseLeave: popupClose, onMouseOver: clearTimer }}
                    className={classes.menu}>
                    <StyledMenuItem disableRipple className={classes.profileIconWithName}>
                        {avatar ? (
                            <Avatar alt="Avatar" src={avatar} />
                        ) : (
                            <Icon
                                {...bindHover(popupState)}
                                {...bindTrigger(popupState)}
                                className={`${classes.icon} ${iconStyle} fas fa-user-circle account-icon`}
                                style={popupState.isOpen ? { color: '#0dabc2' } : { color: '#00606D' }}
                            />
                        )}
                        <CustomText className={classes.clientNumber} text={clientNumber} />
                    </StyledMenuItem>
                    {PROFILE_DROPDOWN.map(option => (
                        <StyledMenuItem key={option.title} className={classes.optionalTab}>
                            <Link className={classes.link} to={option.linkTo} onClick={popupState.close}>
                                <ListItemText primary={option.title} />
                            </Link>
                        </StyledMenuItem>
                    ))}
                    <Link className={classes.text} to="/" onClick={logout}>
                        <StyledMenuItem className={classes.optionalTab}>
                            <ListItemText primary={TEXT.logout} />
                        </StyledMenuItem>
                    </Link>
                </StyledMenu>
            </Box>
        </div>
    );
}

export default ProfileDropdown;
