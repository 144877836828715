import {
    GET_JOB_HIERARCHY_COMPANY_FAILURE,
    GET_JOB_HIERARCHY_COMPANY_REQUEST,
    GET_JOB_HIERARCHY_COMPANY_SUCCESS,
    GET_JOB_HIERARCHY_FAILURE,
    GET_JOB_HIERARCHY_REQUEST,
    GET_JOB_HIERARCHY_SUCCESS,
    GET_ROOT_CATEGORIES_REQUEST,
    GET_ROOT_CATEGORIES_FAILURE,
    GET_ROOT_CATEGORIES_SUCCESS,
} from '../constants/jobTree';

const initialState = {
    full: [],
    company: [],
    isLoading: false,
    rootCategories: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_JOB_HIERARCHY_REQUEST:
        case GET_JOB_HIERARCHY_COMPANY_REQUEST:
        case GET_ROOT_CATEGORIES_REQUEST:
            return { ...state, isLoading: true };
        case GET_JOB_HIERARCHY_FAILURE:
        case GET_ROOT_CATEGORIES_FAILURE:
        case GET_JOB_HIERARCHY_COMPANY_FAILURE:
            return { ...state, error: action.error };
        case GET_JOB_HIERARCHY_SUCCESS:
            return { ...state, full: action.payload };
        case GET_JOB_HIERARCHY_COMPANY_SUCCESS:
            return { ...state, company: action.payload };
        case GET_ROOT_CATEGORIES_SUCCESS:
            return { ...state, rootCategories: action.payload };
        default:
            return state;
    }
};

export default reducer;
