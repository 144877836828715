import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    option: {
        '&:hover': {
            backgroundColor: 'rgb(0, 96, 109)',
        },
    },
});

export default useStyles;
