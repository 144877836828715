import React from 'react';
import { Paper, Zoom, Icon } from '@material-ui/core';
import useStyles from './styles';

export default function SimpleZoom({ isOpen = true, iconBackgroundColor, icon, iconSize = 50 }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Zoom in={isOpen} style={{ transitionDelay: '500ms' }}>
        <Paper
          className={classes.paper}
          style={{
            backgroundColor: iconBackgroundColor
          }}
        >
          <div className={classes.circle}>
            <Icon className={`${classes.closeIcon} ${icon}`} style={{ fontSize: iconSize }} />
          </div>
        </Paper>
      </Zoom>
    </div>
  );
}
