import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TreeSelect } from 'antd';
import { isEmpty } from 'lodash';
import React, { useState, useReducer } from 'react';
import '../../styles/ant-design.css';
import { init, initialState, reducer } from './reducer';

export function TreeDropdown({
    value,
    onChange,
    treeData,
    multiple = true,
    placeholder = 'Выбрать услугу',
    dropdownStyle,
    style,
    isSingleSelect = false,
    disabled = false,
}) {
    const [state, dispatch] = useReducer(reducer, initialState, init());
    const [searchValue, setSearchValue] = useState(null);

    function onTreeExpand(expandedKey) {
        dispatch({
            type: 'expandedKeys',
            payload: expandedKey,
        });
        if (isSingleSelect) {
            dispatch({
                type: 'isDropdownVisible',
                payload: true,
            });
        }
    }

    function onSelect(treeValue, node) {
        if (node.isLeaf) {
            const payload = [...state.selectedJobs, treeValue];
            dispatch({
                type: 'selectedJobs',
                payload,
            });
            if (isSingleSelect) {
                dispatch({
                    type: 'isDropdownVisible',
                    payload: false,
                });
                onChange(treeValue, [node.title]);
            } else {
                onChange(payload);
            }
            setSearchValue('');
        } else {
            if (isSingleSelect) {
                dispatch({
                    type: 'isDropdownVisible',
                    payload: true,
                });
            }
            dispatch({
                type: 'expandedKeys',
                payload: state.expandedKeys.includes(node.props.value)
                    ? state.expandedKeys.filter(key => key !== node.props.value)
                    : [...state.expandedKeys, treeValue],
            });
        }
    }

    function onDeselect(treeValue, node) {
        if (node.isLeaf) {
            const payload = state.selectedJobs.filter(job => job !== treeValue);
            dispatch({
                type: 'selectedJobs',
                payload,
            });
            if (isSingleSelect) {
                dispatch({
                    type: 'isDropdownVisible',
                    payload: false,
                });
                onChange(treeValue);
            } else {
                onChange(payload);
            }
        }
    }

    const filterTreeNode = (filterValue, node) => {
        return node.title?.toLowerCase().includes(filterValue.toLowerCase());
    };

    return (
        <TreeSelect
            getPopupContainer={trigger => trigger.parentNode}
            showSearch
            multiple={multiple}
            autoClearSearchValue={false}
            searchValue={searchValue}
            onSearch={val => {
                setSearchValue(val);
            }}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onTreeExpand={onTreeExpand}
            treeExpandedKeys={state.expandedKeys}
            defaultExpandAll={false}
            treeNodeFilterProp="title"
            filterTreeNode={filterTreeNode}
            value={value}
            treeData={!isEmpty(treeData) ? treeData : [{ title: 'Нет данных', key: 0 }]}
            dropdownStyle={dropdownStyle}
            style={style}
            placeholder={placeholder}
            showArrow
            suffixIcon={<ExpandMoreIcon />}
            disabled={disabled}
        />
    );
}
