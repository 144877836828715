import React from 'react';
import { api } from '../api';
import { CompanySelectorItem } from '../components/CompanySelectItem';
import {
    GET_ALL_MARKS_FAILURE,
    GET_ALL_MARKS_REQUEST,
    GET_ALL_MARKS_SUCCESS,
    GET_ALL_SERVICES_FAILURE,
    GET_ALL_SERVICES_REQUEST,
    GET_ALL_SERVICES_SUCCESS,
    GET_CATEGORY_ITEMS_FAILURE,
    GET_CATEGORY_ITEMS_REQUEST,
    GET_CATEGORY_ITEMS_SUCCESS,
    GET_COMPANIES_FAILURE,
    GET_COMPANIES_REQUEST,
    GET_COMPANIES_SUCCESS,
    GET_SELECTABLE_COMPANIES_FAILURE,
    GET_SELECTABLE_COMPANIES_REQUEST,
    GET_SELECTABLE_COMPANIES_SUCCESS,
} from '../constants/autoservices';
import { javaRoot, root } from '../constants/urls';

export const getCompanies =
    ({ page, pageSize = 6, markIds = [], serviceIds = [], workIds = [] }) =>
    dispatch => {
        dispatch({
            type: GET_COMPANIES_REQUEST,
        });
        return api
            .get(`${javaRoot}/organizations?page=${page}&size=${pageSize}`, {
                params: {
                    markIds: markIds.join(','),
                    serviceIds: serviceIds.join(','),
                    workIds: workIds.join(','),
                },
            })
            .then(response => {
                dispatch({
                    type: GET_COMPANIES_SUCCESS,
                    payload: response.data,
                });
                return response.data;
            })
            .catch(error => {
                dispatch({
                    type: GET_COMPANIES_FAILURE,
                    error,
                });
                throw error;
            });
    };

export const getSelectableCompanies =
    ({ page, pageSize = 12, params = null, withAddress = false }) =>
    dispatch => {
        dispatch({
            type: GET_SELECTABLE_COMPANIES_REQUEST,
        });
        return api
            .get(`${javaRoot}/organizations?page=${page}&size=${pageSize}`, { params })
            .then(({ data }) => {
                const selectableCompanies = { ...data };
                selectableCompanies.content = (selectableCompanies.content || []).map(company => ({
                    ...company,
                    label: withAddress ? <CompanySelectorItem {...company} /> : company.name,
                    value: company.id,
                }));
                dispatch({
                    type: GET_SELECTABLE_COMPANIES_SUCCESS,
                    payload: selectableCompanies,
                });
                return selectableCompanies;
            })
            .catch(error => {
                dispatch({
                    type: GET_SELECTABLE_COMPANIES_FAILURE,
                    error,
                });
                throw error;
            });
    };

export const getAllServices = () => dispatch => {
    dispatch({
        type: GET_ALL_SERVICES_REQUEST,
    });
    return api
        .get(`${javaRoot}/organizations/services`)
        .then(({ data }) => {
            dispatch({
                type: GET_ALL_SERVICES_SUCCESS,
                payload: data,
            });
            return data;
        })
        .catch(error => {
            dispatch({
                type: GET_ALL_SERVICES_FAILURE,
                error,
            });
        });
};

export const getAllMarks = () => dispatch => {
    dispatch({
        type: GET_ALL_MARKS_REQUEST,
    });
    return api
        .get(`${root}/car-info-mark/?format=json`)
        .then(({ data }) => {
            const marks = data.map(item => ({
                ...item,
                id: item.id_car_mark,
                name: item.name,
                label: item.name,
                value: item.id_car_mark,
            }));
            dispatch({
                type: GET_ALL_MARKS_SUCCESS,
                payload: marks,
            });
            return marks;
        })
        .catch(error => {
            dispatch({
                type: GET_ALL_MARKS_FAILURE,
                error,
            });
        });
};

export const getCategoryItems =
    (id = 4) =>
    dispatch => {
        dispatch({
            type: GET_CATEGORY_ITEMS_REQUEST,
        });
        return api
            .get(`${javaRoot}/tree`)
            .then(({ data }) => {
                const payload = data.find(item => item.id === id);

                dispatch({
                    type: GET_CATEGORY_ITEMS_SUCCESS,
                    payload,
                });
            })
            .catch(error => {
                dispatch({
                    type: GET_CATEGORY_ITEMS_FAILURE,
                    error,
                });
            });
    };
