export const GET_MARKS = '@addCarPage//GET_MARKS';
export const GET_MODELS = '@addCarPage//GET_MODELS';
export const GET_GENERATION = '@addCarPage//GET_GENERATION';
export const GET_SERIES = '@addCarPage//GET_SERIES';
export const GET_MODIFICATIONS = '@addCarPage//GET_MODIFICATIONS';

export const ADD_CAR_SUCCESS = '@addCarPage//ADD_CAR_SUCCESS';
export const ADD_CAR_REQUEST = '@addCarPage//ADD_CAR_REQUEST';
export const ADD_CAR_FAILURE = '@addCarPage//ADD_CAR_FAILURE';

export const UPDATE_CAR_SUCCESS = '@addCarPage//UPDATE_CAR_SUCCESS';
export const UPDATE_CAR_REQUEST = '@addCarPage//UPDATE_CAR_REQUEST';
export const UPDATE_CAR_FAILURE = '@addCarPage//UPDATE_CAR_FAILURE';

export const AUTO_ASSIGNMENT_SUCCESS = '@addCarPage//AUTO_ASSIGNMENT_SUCCESS';
export const AUTO_ASSIGNMENT_REQUEST = '@addCarPage//AUTO_ASSIGNMENT_REQUEST';
export const AUTO_ASSIGNMENT_FAILURE = '@addCarPage//AUTO_ASSIGNMENT_FAILURE';

export const CHECK_VIN = '@addCarPage//CHECK_VIN';
export const ADD_REVIEW_COMMENT = '@addCarPage//ADD_REVIEW_COMMENT';
export const GET_REVIEWS_COMMENTS = '@addCarPage//GET_REVIEWS_COMMENTS';
export const GET_TOP_RATED_MARKS = '@addCarPage//GET_TOP_RATED_MARKS';
export const GENERATE_AUTO_NUMBER = '@addCarPage//GENERATE_AUTO_NUMBER';
