import { createMergedObject } from '../../utils/object';

export function init(initialValues) {
    return initialState => {
        try {
            const newState = createMergedObject(initialState, initialValues);
            return newState;
        } catch (error) {
            return initialState;
        }
    };
}

export const initialState = {
    selectedJobs: [],
    expandedKeys: [],
    isDropdownVisible: false
};

export function reducer(state, action) {
    switch (action.type) {
        case 'expandedKeys': {
            return {
                ...state,
                expandedKeys: action.payload,
            };
        }
        case 'selectedJobs': {
            return {
                ...state,
                selectedJobs: action.payload,
                companies: [],
                company: null,
            };
        }
        default:
            return {
                ...state,
                [action.type]: action.payload,
            };
    }
}
