import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    dropdown: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '& .MuiButton-text': {
            paddingLeft: 0,
        },
    },
    menu: {
        '& .MuiList-padding': {
            paddingTop: 0,
        },
    },
    menuItem: {
        display: 'flex',
        flexBasis: 'auto',
        justifyContent: 'space-between',
    },
    icon: {
        color: 'rgb(0, 96, 109)',
        marginRight: '10px',
        '@media(max-width:400px)': {
            fontSize: '1.2rem',
        },
    },
    link: {
        width: '100%',
        height: '100%',
        margin: '-12px -16px',
        padding: '12px 16px',
        textDecoration: 'none',
    },
});

export default useStyles;
