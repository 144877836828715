import React, { useState } from 'react';
import { FavoriteCarContext } from 'context';
import { SelectAutoModal } from 'components/SelectAutoModal';

export function FavoriteCarProvider({ children, isAuth }) {
    const [hasFavoriteCar, setHasFavoriteCar] = useState(true);

    return (
        <FavoriteCarContext.Provider value={{ hasFavoriteCar, setHasFavoriteCar }}>
            {children}
            {isAuth && !hasFavoriteCar && <SelectAutoModal shouldBeOpen />}
        </FavoriteCarContext.Provider>
    );
}
