import {
    GET_AUTO_WORKS_FAILURE,
    GET_AUTO_WORKS_REQUEST,
    GET_AUTO_WORKS_SUCCESS,
    GET_AUTO_EVENTS_FAILURE,
    GET_AUTO_EVENTS_REQUEST,
    GET_AUTO_EVENTS_SUCCESS,
    GET_SPECIFIC_EVENT_REQUEST,
    GET_SPECIFIC_EVENT_SUCCESS,
    GET_SPECIFIC_EVENT_FAILURE,
    GET_SPECIFIC_EVENT_WORKS_REQUEST,
    GET_SPECIFIC_EVENT_WORKS_SUCCESS,
    GET_SPECIFIC_EVENT_WORKS_FAILURE,
    GET_EVENT_MILEAGE_REQUEST,
    GET_EVENT_MILEAGE_SUCCESS,
    GET_EVENT_MILEAGE_FAILURE,
    GET_SPECIFIC_EVENT_SPARES_REQUEST,
    GET_SPECIFIC_EVENT_SPARES_SUCCESS,
    GET_SPECIFIC_EVENT_SPARES_FAILURE,
    GET_SPECIFIC_EVENT_WRITTEN_SPARES_REQUEST,
    GET_SPECIFIC_EVENT_WRITTEN_SPARES_SUCCESS,
    GET_SPECIFIC_EVENT_WRITTEN_SPARES_FAILURE,
    GET_EVENT_WORK_FIELDS_REQUEST,
    GET_EVENT_WORK_FIELDS_SUCCESS,
    GET_EVENT_WORK_FIELDS_FAILURE,
} from '../constants/journal';

const initialState = { works: [], events: [], info: {}, eventMileage: null, spares: [], writtenSpares: [] };

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AUTO_WORKS_REQUEST:
        case GET_AUTO_EVENTS_REQUEST:
        case GET_SPECIFIC_EVENT_REQUEST:
        case GET_SPECIFIC_EVENT_WORKS_REQUEST:
        case GET_SPECIFIC_EVENT_SPARES_REQUEST:
        case GET_SPECIFIC_EVENT_WRITTEN_SPARES_REQUEST:
        case GET_EVENT_WORK_FIELDS_REQUEST:
            return { ...state, isLoading: true };
        case GET_AUTO_WORKS_FAILURE:
        case GET_AUTO_EVENTS_FAILURE:
        case GET_SPECIFIC_EVENT_FAILURE:
        case GET_SPECIFIC_EVENT_WORKS_FAILURE:
        case GET_SPECIFIC_EVENT_SPARES_FAILURE:
        case GET_SPECIFIC_EVENT_WRITTEN_SPARES_FAILURE:
        case GET_EVENT_WORK_FIELDS_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
        case GET_EVENT_MILEAGE_FAILURE:
            return { ...state, isLoading: false, error: action.payload, eventMileage: null };
        case GET_EVENT_MILEAGE_REQUEST:
            return { ...state, isLoading: true, eventMileage: null };
        case GET_AUTO_WORKS_SUCCESS:
            return { ...state, works: action.payload, isLoading: false };
        case GET_SPECIFIC_EVENT_WORKS_SUCCESS:
            return { ...state, eventJob: action.payload, isLoading: false };
        case GET_SPECIFIC_EVENT_SUCCESS:
            return { ...state, info: action.payload, isLoading: false };
        case GET_EVENT_MILEAGE_SUCCESS:
            return { ...state, eventMileage: action.payload, isLoading: false };
        case GET_SPECIFIC_EVENT_SPARES_SUCCESS:
            return { ...state, spares: action.payload, isLoading: false };
        case GET_AUTO_EVENTS_SUCCESS:
            return { ...state, events: action.payload, isLoading: false };
        case GET_SPECIFIC_EVENT_WRITTEN_SPARES_SUCCESS:
            return { ...state, writtenSpares: action.payload, isLoading: false };
        case GET_EVENT_WORK_FIELDS_SUCCESS:
            return { ...state, fields: action.payload, isLoading: false };
        default:
            return state;
    }
};

export default reducer;
