import {
    GET_REFUELING_TYPES_REQUEST,
    GET_REFUELING_TYPES_SUCCESS,
    GET_REFUELING_TYPES_FAILURE,
    GET_REFUELING_ORGANIZATIONS_REQUEST,
    GET_REFUELING_ORGANIZATIONS_SUCCESS,
    GET_REFUELING_ORGANIZATIONS_FAILURE,
    ADD_REFUELING_REQUEST,
    ADD_REFUELING_SUCCESS,
    ADD_REFUELING_FAILURE,
    GET_REFUELING_TYPES_ROOT_REQUEST,
    GET_REFUELING_TYPES_ROOT_SUCCESS,
    GET_REFUELING_TYPES_ROOT_FAILURE,
    GET_REFUELING_STATISTICS_REQUEST,
    GET_REFUELING_STATISTICS_SUCCESS,
    GET_REFUELING_STATISTICS_FAILURE,
    GET_REFUELING_SPENDING_REQUEST,
    GET_REFUELING_SPENDING_SUCCESS,
    GET_REFUELING_SPENDING_FAILURE,
    GET_LAST_REFUELING_REQUEST,
    GET_LAST_REFUELING_FAILURE,
    GET_LAST_REFUELING_SUCCESS,
    GET_REFUELING_FAILURE,
    GET_REFUELING_REQUEST,
    GET_REFUELING_SUCCESS,
    GET_REFUELING_CHARTS_DATA_FAILURE,
    GET_REFUELING_CHARTS_DATA_REQUEST,
    GET_REFUELING_CHARTS_DATA_SUCCESS,
    GET_MILEAGE_STATISTICS_REQUEST,
    GET_MILEAGE_STATISTICS_SUCCESS,
    GET_MILEAGE_STATISTICS_FAILURE,
    GET_REFUELING_MONEY_SPENDING_STATISTICS_REQUEST,
    GET_REFUELING_MONEY_SPENDING_STATISTICS_SUCCESS,
    GET_REFUELING_MONEY_SPENDING_STATISTICS_FAILURE,
} from '../constants/refueling';

const initialState = {
    types: [],
    organizations: [],
    root: [],
    statistics: null,
    avgSpending: null,
    lastRefueling: null,
    refuelingEvents: null,
    mileageStatistics: null,
    moneySpendingStatistics: null,
    statisticsChartsData: [],
    avgSpendingChartsData: [],
    moneySpendingChartsData: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REFUELING_TYPES_REQUEST:
        case GET_REFUELING_ORGANIZATIONS_REQUEST:
        case ADD_REFUELING_REQUEST:
        case GET_REFUELING_TYPES_ROOT_REQUEST:
        case GET_REFUELING_STATISTICS_REQUEST:
        case GET_REFUELING_SPENDING_REQUEST:
        case GET_LAST_REFUELING_REQUEST:
        case GET_REFUELING_REQUEST:
        case GET_REFUELING_CHARTS_DATA_REQUEST:
        case GET_MILEAGE_STATISTICS_REQUEST:
        case GET_REFUELING_MONEY_SPENDING_STATISTICS_REQUEST:
            return { ...state, isLoading: true };
        case GET_REFUELING_TYPES_FAILURE:
        case GET_REFUELING_ORGANIZATIONS_FAILURE:
        case ADD_REFUELING_FAILURE:
        case GET_REFUELING_TYPES_ROOT_FAILURE:
        case GET_LAST_REFUELING_FAILURE:
        case GET_REFUELING_FAILURE:
        case GET_REFUELING_CHARTS_DATA_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
        case GET_REFUELING_TYPES_SUCCESS:
            return { ...state, types: action.payload, isLoading: false };
        case GET_REFUELING_ORGANIZATIONS_SUCCESS:
            return { ...state, isLoading: false, organizations: action.payload };
        case GET_REFUELING_TYPES_ROOT_SUCCESS:
            return { ...state, isLoading: false, root: action.payload };
        case GET_REFUELING_STATISTICS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                statistics: action.payload.statistics,
                statisticsChartsData: action.payload.statisticsChartsData,
            };
        case GET_REFUELING_SPENDING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                avgSpending: action.payload.spending,
                avgSpendingChartsData: action.payload.avgSpendingChartsData,
            };
        case GET_LAST_REFUELING_SUCCESS:
            return { ...state, isLoading: false, lastRefueling: action.payload };
        case GET_REFUELING_SUCCESS:
            return { ...state, isLoading: false, events: action.payload };
        case ADD_REFUELING_SUCCESS:
            return { ...state, isLoading: false };
        case GET_REFUELING_CHARTS_DATA_SUCCESS:
            return { ...state, isLoading: false, chartsData: action.payload };
        case GET_MILEAGE_STATISTICS_SUCCESS:
            return { ...state, isLoading: false, mileageStatistics: action.payload };
        case GET_REFUELING_MONEY_SPENDING_STATISTICS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                moneySpendingStatistics: action.payload.moneySpendingStatistics,
                moneySpendingChartsData: action.payload.moneySpendingChartsData,
            };
        case GET_REFUELING_STATISTICS_FAILURE:
            return { ...state, isLoading: false, error: action.payload, statistics: null, statisticsChartsData: [] };
        case GET_REFUELING_SPENDING_FAILURE:
            return { ...state, isLoading: false, avgSpending: null, error: action.payload, avgSpendingChartsData: [] };
        case GET_MILEAGE_STATISTICS_FAILURE:
            return { ...state, isLoading: false, error: action.payload, mileageStatistics: null };
        case GET_REFUELING_MONEY_SPENDING_STATISTICS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                moneySpendingStatistics: null,
                moneySpendingChartsData: [],
            };
        default:
            return state;
    }
};

export default reducer;
