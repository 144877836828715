export const GET_SPECIAL_OFFERS = '@serviceStation//GET_SPECIAL_OFFERS';
export const GET_COMPANY = '@serviceStation//GET_COMPANY';
export const GET_COMPANY_STATISTICS = '@serviceStation//GET_COMPANY_STATISTICS';
export const GET_COMPANY_REVIEWS = '@serviceStation//GET_COMPANY_REVIEWS';
export const ADD_STATION_REVIEW_COMMENT = '@serviceStation//ADD_STATION_REVIEW_COMMENT';

export const GET_COMPANY_SLIDER_PHOTOS_REQUEST = '@serviceStation//GET_COMPANY_SLIDER_PHOTOS_REQUEST';
export const GET_COMPANY_SLIDER_PHOTOS_SUCCESS = '@serviceStation//GET_COMPANY_SLIDER_PHOTOS_SUCCESS';
export const GET_COMPANY_SLIDER_PHOTOS_FAILURE = '@serviceStation//GET_COMPANY_SLIDER_PHOTOS_FAILURE';

export const GET_COMPANY_WORK_RESULT_PHOTOS_REQUEST = '@serviceStation//GET_COMPANY_WORK_RESULT_PHOTOS_REQUEST';
export const GET_COMPANY_WORK_RESULT_PHOTOS_SUCCESS = '@serviceStation//GET_COMPANY_WORK_RESULT_PHOTOS_SUCCESS';
export const GET_COMPANY_WORK_RESULT_PHOTOS_FAILURE = '@serviceStation//GET_COMPANY_WORK_RESULT_PHOTOS_FAILURE';

export const GET_COMFORT_SERVICES_REQUEST = '@serviceStation//GET_COMFORT_SERVICES_REQUEST';
export const GET_COMFORT_SERVICES_SUCCESS = '@serviceStation//GET_COMFORT_SERVICES_SUCCESS';
export const GET_COMFORT_SERVICES_FAILURE = '@serviceStation//GET_COMFORT_SERVICES_FAILURE';

export const GET_AUTO_SERVICES_JOBS_REQUEST = '@serviceStation//GET_AUTO_SERVICES_JOBS_REQUEST';
export const GET_AUTO_SERVICES_JOBS_SUCCESS = '@serviceStation//GET_AUTO_SERVICES_JOBS_SUCCESS';
export const GET_AUTO_SERVICES_JOBS_FAILURE = '@serviceStation//GET_AUTO_SERVICES_JOBS_FAILURE';

export const GET_AUTO_SERVICES_SCHEDULE_REQUEST = '@serviceStation//GET_AUTO_SERVICES_SCHEDULE_REQUEST';
export const GET_AUTO_SERVICES_SCHEDULE_SUCCESS = '@serviceStation//GET_AUTO_SERVICES_SCHEDULE_SUCCESS';
export const GET_AUTO_SERVICES_SCHEDULE_FAILURE = '@serviceStation//GET_AUTO_SERVICES_SCHEDULE_FAILURE';

export const GET_AUTO_SERVICES_DAYSOFF_REQUEST = '@serviceStation//GET_AUTO_SERVICES_DAYSOFF_REQUEST';
export const GET_AUTO_SERVICES_DAYSOFF_SUCCESS = '@serviceStation//GET_AUTO_SERVICES_DAYSOFF_SUCCESS';
export const GET_AUTO_SERVICES_DAYSOFF_FAILURE = '@serviceStation//GET_AUTO_SERVICES_DAYSOFF_FAILURE';

export const GET_AUTO_SERVICES_CONTACTS_REQUEST = '@serviceStation//GET_AUTO_SERVICES_CONTACTS_REQUEST';
export const GET_AUTO_SERVICES_CONTACTS_SUCCESS = '@serviceStation//GET_AUTO_SERVICES_CONTACTS_SUCCESS';
export const GET_AUTO_SERVICES_CONTACTS_FAILURE = '@serviceStation//GET_AUTO_SERVICES_CONTACTS_FAILURE';
