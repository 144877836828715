import {
    GENERATE_CAR_REPORT_LINK_FAILURE,
    GENERATE_CAR_REPORT_LINK_REQUEST,
    GENERATE_CAR_REPORT_LINK_SUCCESS,
    GET_CAR_REPORT_LINK_REQUEST,
    GET_CAR_REPORT_LINK_SUCCESS,
    GET_CAR_REPORT_LINK_FAILURE,
    GET_CAR_INFO_FAILURE,
    GET_CAR_INFO_REQUEST,
    GET_CAR_INFO_SUCCESS,
    GET_CAR_MILEAGE_FAILURE,
    GET_CAR_MILEAGE_REQUEST,
    GET_CAR_MILEAGE_SUCCESS,
    GET_CAR_MILEAGE_STATISTICS_REQUEST,
    GET_CAR_MILEAGE_STATISTICS_SUCCESS,
    GET_CAR_MILEAGE_STATISTICS_FAILURE,
    GET_CAR_REPORT_JOURNAL_EVENTS_REQUEST,
    GET_CAR_REPORT_JOURNAL_EVENTS_SUCCESS,
    GET_CAR_REPORT_JOURNAL_EVENTS_FAILURE,
    GET_CAR_REPORT_FIELDS_REQUEST,
    GET_CAR_REPORT_FIELDS_SUCCESS,
    GET_CAR_REPORT_FIELDS_FAILURE,
    // Refueling
    GET_CAR_REPORT_REFUELING_STATISTICS_SUCCESS,
    GET_CAR_REPORT_REFUELING_SPENDING_SUCCESS,
    GET_CAR_REPORT_REFUELING_SUCCESS,
    GET_CAR_REPORT_REFUELING_MONEY_SPENDING_STATISTICS_SUCCESS,
    GET_CAR_REPORT_GET_MONTH_MILEAGE_MONTH_DIFFERENCE_SUCCESS,
    // ATTACHMENTS
    GET_CAR_REPORT_ATTACHMENTS_REQUEST,
    GET_CAR_REPORT_ATTACHMENTS_SUCCESS,
    GET_CAR_REPORT_ATTACHMENTS_FAILURE,
} from '../constants/eventHistory';

const initialState = {
    carReportLink: null,
    isLoading: false,
    isLoadingCarInfo: false,
    ///
    carInfo: null,
    carInfoError: null,
    error: null,
    //
    carMileageStatistics: null,
    isLoadingCarMileageStatistics: false,
    carMileageStatisticsError: null,
    //
    isLoadingCarMileage: false,
    carMileage: null,
    carMileageError: null,
    //
    isLoadingCarEvents: false,
    carEvents: null,
    carEventsError: null,
    //
    isLoadingCarFields: false,
    carFields: null,
    carFieldsError: null,
    //
    lastRefueling: null,
    avgSpending: null,
    statistics: null,
    mileageStatistics: null,
    moneySpendingStatistics: null,
    //
    attachments: [],
    isLoadingAttachments: false,
    attachmentsError: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GENERATE_CAR_REPORT_LINK_REQUEST:
            return { ...state, isLoading: true };
        case GET_CAR_INFO_REQUEST:
            return { ...state, isLoadingCarInfo: true };
        case GET_CAR_MILEAGE_STATISTICS_REQUEST:
            return { ...state, isLoadingCarMileageStatistics: true };
        case GENERATE_CAR_REPORT_LINK_FAILURE:
        case GET_CAR_REPORT_LINK_FAILURE:
            return { ...state, error: action.error, isLoading: false };
        case GET_CAR_INFO_FAILURE:
            return { ...state, carInfoError: action.error, isLoadingCarInfo: false };
        case GET_CAR_MILEAGE_STATISTICS_FAILURE:
            return { ...state, carMileageStatisticsError: action.error, isLoadingCarMileageStatistics: false };
        case GENERATE_CAR_REPORT_LINK_SUCCESS:
        case GET_CAR_REPORT_LINK_SUCCESS:
            return { ...state, carReportLink: action.payload };
        case GET_CAR_REPORT_LINK_REQUEST:
            return { ...state, carReportLink: null };
        case GET_CAR_INFO_SUCCESS:
            return { ...state, carInfo: action.payload, carInfoError: null, isLoadingCarInfo: false };
        case GET_CAR_MILEAGE_STATISTICS_SUCCESS:
            return {
                ...state,
                carMileageStatistics: action.payload,
                carMileageStatisticsError: null,
                isLoadingCarMileageStatistics: false,
            };
        case GET_CAR_MILEAGE_SUCCESS:
            return {
                ...state,
                carMileage: action.payload,
                carMileageError: null,
                isLoadingCarMileage: false,
            };
        case GET_CAR_MILEAGE_FAILURE:
            return { ...state, carMileageError: action.error, isLoadingCarMileage: false };
        case GET_CAR_MILEAGE_REQUEST:
            return { ...state, isLoadingCarMileage: true };
        case GET_CAR_REPORT_JOURNAL_EVENTS_SUCCESS:
            return {
                ...state,
                carEvents: action.payload,
                carEventsError: null,
                isLoadingCarEvents: false,
            };
        case GET_CAR_REPORT_JOURNAL_EVENTS_FAILURE:
            return { ...state, carEventsError: action.error, isLoadingCarEvents: false };
        case GET_CAR_REPORT_JOURNAL_EVENTS_REQUEST:
            return { ...state, isLoadingCarEvents: true };
        case GET_CAR_REPORT_FIELDS_SUCCESS:
            return {
                ...state,
                carFields: action.payload,
                carFieldsError: null,
                isLoadingCarFields: false,
            };
        case GET_CAR_REPORT_FIELDS_FAILURE:
            return { ...state, carFieldsError: action.error, isLoadingCarFields: false };
        case GET_CAR_REPORT_FIELDS_REQUEST:
            return { ...state, isLoadingCarFields: true };

        case GET_CAR_REPORT_REFUELING_SUCCESS:
            return { ...state, isLoading: false, lastRefueling: action.payload };
        case GET_CAR_REPORT_REFUELING_SPENDING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                avgSpending: action.payload.spending,
                avgSpendingChartsData: action.payload.avgSpendingChartsData,
            };

        case GET_CAR_REPORT_REFUELING_STATISTICS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                statistics: action.payload.statistics,
                statisticsChartsData: action.payload.statisticsChartsData,
            };
        case GET_CAR_REPORT_REFUELING_MONEY_SPENDING_STATISTICS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                moneySpendingStatistics: action.payload.moneySpendingStatistics,
                moneySpendingChartsData: action.payload.moneySpendingChartsData,
            };
        case GET_CAR_REPORT_GET_MONTH_MILEAGE_MONTH_DIFFERENCE_SUCCESS:
            return { ...state, isLoading: false, mileageStatistics: action.payload };
        // ATTACHMENTS
        case GET_CAR_REPORT_ATTACHMENTS_REQUEST:
            return { ...state, isLoadingAttachments: true, attachmentsError: null };
        case GET_CAR_REPORT_ATTACHMENTS_SUCCESS:
            return { ...state, attachments: action.payload, isLoadingAttachments: false, attachmentsError: null };
        case GET_CAR_REPORT_ATTACHMENTS_FAILURE:
            return { ...state, attachments: null, isLoadingAttachments: false, attachmentsError: action.payload };
        default:
            return state;
    }
};

export default reducer;
