import { AppBar, Container, useMediaQuery , Box, Tab, ButtonBase} from '@material-ui/core';
import React from 'react';
import { DropdownMenu } from '../../../components/Dropdown';
import { NavBar } from '../../../components/NavBar';
import { NavDropdownMenu } from '../../../components/NavDropdown';
import RegistrationForServiceModal from '../../../components/RegistrationForServiceModal';
import { CustomText } from '../../../components/Text';
import { ADD_DROPDOWN, NAVIGATION_DROPDOWN, NAVIGATION_DROPDOWN_AUTH } from '../../../constants/dropdown';
import TEXT from '../../../constants/text';
import { getNavBarMaxWidth } from '../../../utils/screen';
import useStyles from './styles';

const MenuHeader = ({ isAuth, history }) => {
    const classes = useStyles();
    const [isOpenModal, setOpenModal] = React.useState(false);

    const navBarMaxWidth = getNavBarMaxWidth(isAuth);
    const matches = useMediaQuery(`(min-width:${navBarMaxWidth}px)`);

    return (
        <AppBar position="static" className={classes.appBar}>
            <Container className={classes.container}>
                {matches ? (
                    <NavBar isAuth={isAuth} history={history} />
                ) : (
                    <NavDropdownMenu
                        menuIcon="fas fa-bars"
                        title={TEXT.reviews}
                        options={isAuth ? NAVIGATION_DROPDOWN_AUTH : NAVIGATION_DROPDOWN}
                        isAuth={isAuth}
                        titleStyle={classes.text}
                        textStyle={classes.dropdownItemText}
                        buttonStyle={classes.reviewsDropdownButton}
                    />
                )}
                <Box display="flex" flexGrow={0}>
                <ButtonBase onClick={() => setOpenModal(true)}>
                    <Tab
                        disableFocusRipple
                        textColor="primary"
                        label={<CustomText className={classes.text} text="Записаться" />}
                    />
                </ButtonBase>
                {isAuth && (
                    <DropdownMenu
                        title={TEXT.add}
                        options={ADD_DROPDOWN}
                        dropdownStyle={classes.addDropdown}
                        titleStyle={classes.text}
                        textStyle={classes.dropdownItemText}
                        buttonStyle={classes.reviewsDropdownButton}
                    />
                )}
                </Box>
            </Container>
            <RegistrationForServiceModal isOpen={isOpenModal} setShowModal={setOpenModal}/>
        </AppBar>
    );
};

export default MenuHeader;
