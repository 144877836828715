import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    dialog: {
        zIndex: '100000 !important',
        '& .MuiDialog-paperFullScreen': {
            justifyContent: 'center',
        },
        '& .MuiDialogTitle-root': {
            padding: '0px 16px 24px',
        },
    },
    title: {
        fontFamily: 'Roboto-Bold',
        color: '#00454e',
    },
});

export default useStyles;
