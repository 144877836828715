import React from 'react';
import { CircularProgress, Button } from '@material-ui/core';

import { useStyles } from './styles';
import { AppleIcon, GoogleIcon } from '../Icons';

function getIcon(social) {
    switch (social) {
        case 'GOOGLE': {
            return GoogleIcon;
        }

        case 'APPLE': {
            return AppleIcon;
        }

        case 'PHONE': {
            return <></>;
        }

        default:
            return GoogleIcon;
    }
}

function getLabel(social) {
    switch (social) {
        case 'GOOGLE': {
            return 'Google';
        }

        case 'APPLE': {
            return 'Apple';
        }

        case 'PHONE': {
            return 'Телефона';
        }

        default:
            return '';
    }
}

export const SocialButton = props => {
    const classes = useStyles();
    const { social, label, onClick, disabled } = props;

    const Icon = getIcon(social);

    const socialLabel = getLabel(social);

    const SocialText = label ?? (socialLabel ? `Войти с помощью ${socialLabel}` : '');

    const SocialIcon = <Icon />;

    return (
        <Button
            className={classes.button}
            fullWidth
            variant="outlined"
            {...(social !== 'PHONE' && { startIcon: SocialIcon })}
            size={props.size}
            disabled={disabled || props.loading}
            onClick={onClick}>
            {props.loading ? <CircularProgress /> : <>{SocialText}</>}
        </Button>
    );
};
