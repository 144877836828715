import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    menuContainer: {
        height: 50,
        width: '100%',
        position: 'absolute',
        zIndex: 1,
    },
    dropdown: {
        display: 'flex',
        alignItems: 'center',
        flexBasis: 'auto',
        position: 'relative',
    },
    menu: {
        marginTop: 6,
        '& .MuiList-root': {
            backgroundColor: 'rgba(248, 248, 248, 0.6)',
        },
        '& .MuiList-padding': {
            paddingTop: 0,
        },
        '& .MuiPaper-rounded': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
        },
    },
    link: {
        width: '100%',
        height: '100%',
        margin: '-12px -16px',
        padding: '12px 16px',
        textDecoration: 'none',
    },
    linkContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexBasis: 'auto',
        justifyContent: 'space-between',
    },
    icon: {
        marginLeft: '10px',
    },
    img: {
        marginLeft: '10px',
        width: 24,
        height: 24,
    },
    hover: {
        zIndex: 5000,
    },
    out: {
        position: 'relative',
        zIndex: 1000,
    },
});

export default useStyles;
