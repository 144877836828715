import { useDeepCompareEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { getFavoriteCar } from 'actions/mainPage';
import { getUserVehicle } from 'api/autos';
import { useFavoriteCarContext } from 'hooks';
import { userWithCarSelector } from 'selectors';

export function useFavoriteCar() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { setHasFavoriteCar } = useFavoriteCarContext();
    const userWithCar = useSelector(userWithCarSelector);

    useDeepCompareEffect(() => {
        if (get(userWithCar, 'user.id')) {
            (async () => {
                try {
                    const favoriteAuto = await dispatch(getFavoriteCar());
                    if (favoriteAuto) {
                        setHasFavoriteCar(true);
                    } else {
                        const data = await getUserVehicle(userWithCar.user);
                        if (get(data, 'totalElements') > 0) {
                            setHasFavoriteCar(false);
                        } else {
                            history.push('/garage');
                        }
                    }
                } catch (err) {
                    history.push('/garage');
                }
            })();
        }
    }, [userWithCar]);

    return get(userWithCar, 'favoriteCar', null);
}
