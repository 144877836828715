import { GET_EVENT_STATUSES_SUCCESS, GET_EVENT_STATUSES_FAILURE } from '../constants/event';

const initialState = {
    eventStatuses: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_STATUSES_FAILURE:
            return { ...state, error: action.error };
        case GET_EVENT_STATUSES_SUCCESS:
            return { ...state, eventStatuses: action.payload };
        default:
            return state;
    }
};

export default reducer;
