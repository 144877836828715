import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
    apiKey: 'AIzaSyDBjmHvoGZluPn9bXhA2SUYKWEnjut-Lao',
    authDomain: 'b2b-taexel.firebaseapp.com',
    projectId: 'b2b-taexel',
    storageBucket: 'b2b-taexel.appspot.com',
    messagingSenderId: '180378332335',
    appId: '1:180378332335:web:2cfb49e46d6d6cea5c785a',
};

firebase.initializeApp(firebaseConfig);
let messaging = null;
if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
}

export const getToken = () => {
    return new Promise(resolve => {
        if (!messaging) {
            resolve(null);
        } else {
            messaging
                .getToken({
                    vapidKey: 'BPY020u1HUXdekGeGb98tHilGEFmOZwcDX9PIOBj9dS4Q1K9TXmjaRJDz8WINynyd4izMfRB3S1o0QcKvWTxfyE',
                })
                .then(currentToken => {
                    if (currentToken) {
                        resolve(currentToken);
                    } else {
                        resolve(null);
                    }
                })
                .catch(err => {
                    resolve(null);
                    console.error('An error occurred while retrieving token. ', err);
                });
        }
    });
};

export const onMessageListener = () =>
    new Promise(resolve => {
        messaging.onMessage(payload => {
            resolve(payload);
        });
    });
