import { Box, Button, Divider, List, ListItem, Radio, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAllUserVehicles } from 'api/autos';
import { userSelector } from 'selectors';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    listRoot: {
        width: '100%',
        height: '350px',
        overflowY: 'scroll',
        marginBottom: 16,
        '& .MuiDivider-root': {
            '@media(max-width:600px)': {
                marginInline: 8,
            },
        },
    },
    textWithLabel: {
        fontSize: 15,
        fontFamily: 'Roboto-Medium',
        color: '#02404b',
        textAlign: 'start !important',
        '& > span': {
            fontFamily: 'Roboto-Light',
            fontSize: 13,
            color: '#000',
            marginRight: 4,
        },
    },
    lawNumberText: {
        '& > span': {
            textTransform: 'none',
        },
        textTransform: 'uppercase',
    },
    goBackBtn: {
        textTransform: 'none',
        '@media(hover:hover)': {
            '&:hover': {
                color: '#fff',
                backgroundColor: '#0e444d',
            },
        },
    },
    setFavoriteAutoBtn: {
        backgroundColor: '#00afc1',
        color: '#fff',
        textTransform: 'none',
        '@media(hover:hover)': {
            '&:hover': {
                backgroundColor: '#0e444d',
            },
        },
    },
});

export function FavoriteCarSelectorList({ setOpen, handleCallback, withRedirectOnClose, onClose }) {
    const classes = useStyles();
    const history = useHistory();

    const [selectedIndex, setSelectedIndex] = useState();
    const user = useSelector(userSelector);
    const [autos, setAutos] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                if (Number.isInteger(get(user, 'id'))) {
                    const { content } = await getAllUserVehicles(user);
                    setAutos(content);
                }
            } catch (err) {
                NotificationManager.error('Ошибка получения автомобиля');
            }
        })();
    }, [user]);
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };
    function navigateToGarage() {
        setOpen(false);
        onClose && onClose();
        if (withRedirectOnClose) {
            history.push('/garage');
        }
    }

    function handleFavoriteCar() {
        if (autos.length > 0 && Number.isInteger(selectedIndex)) {
            handleCallback({ autoId: autos[selectedIndex].id, userId: user.id });
        }
    }

    return (
        <>
            <List className={classes.listRoot}>
                {!isEmpty(autos) &&
                    autos.map((item, index) => {
                        return (
                            <>
                                <ListItem
                                    button
                                    alignItems="flex-start"
                                    selected={selectedIndex === index}
                                    onClick={event => handleListItemClick(event, index)}>
                                    <Box display="flex" flex={1} justifyContent="space-between" alignItems="center">
                                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                                            <Typography className={classes.textWithLabel}>
                                                <span>VIN:</span>
                                                {item.vin}
                                            </Typography>
                                            <Typography className={classes.textWithLabel}>
                                                <span>Автомобиль:</span>
                                                {`${get(item, 'mark.label', '')} ${get(item, 'model.label', '')} ${get(
                                                    item,
                                                    'generation.label',
                                                    '',
                                                )} ${get(item, 'series.label', '')} ${get(
                                                    item,
                                                    'modification.label',
                                                    '',
                                                )}`}
                                            </Typography>
                                            <Typography className={`${classes.textWithLabel} ${classes.lawNumberText}`}>
                                                <span>Гос. номер:</span>
                                                {item.lawNumber}
                                            </Typography>
                                        </Box>
                                        <Radio
                                            color="primary"
                                            checked={selectedIndex === index}
                                            value={index}
                                            name="radio-button-demo"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                    </Box>
                                </ListItem>
                                {index !== autos.length - 1 && <Divider component="li" />}
                            </>
                        );
                    })}
                <ListItem alignItems="flex-start" />
            </List>
            <Box display="flex" justifyContent="space-between">
                <Button onClick={navigateToGarage} variant="contained" className={classes.goBackBtn}>
                    Назад
                </Button>
                <Button
                    onClick={handleFavoriteCar}
                    variant="contained"
                    className={classes.setFavoriteAutoBtn}
                    disabled={!Number.isInteger(selectedIndex)}>
                    Выбрать автомобиль
                </Button>
            </Box>
        </>
    );
}
