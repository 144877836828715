import { Box, CardMedia, Icon, Typography, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo-f.png';
import TEXT from '../../constants/text';
import useStyles, { styles } from './styles';

const Footer = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const classes = useStyles({ isMobile });

    const isLargeScreen = useMediaQuery('(min-width:1415px)');
    const isMediumScreen = useMediaQuery('(min-width:1041px)');
    const isSmallScreen = useMediaQuery('(min-width:727px)');
    const isMobileScreen = useMediaQuery('(min-width:350px)');
    const privacyPolicyStyle = isMobileScreen ? classes.textLink : classes.privacyPolicy;

    return (
        <Box className={classes.container} key={0}>
            <Box className={classes.logoContainer} style={styles.logo(isSmallScreen)}>
                <CardMedia
                    classes={{ root: classes.logo, media: classes.media }}
                    component="img"
                    src={logo}
                    alt="logo"
                />
                <Typography className={classes.logoCaption}>{TEXT.footer.logoCaption}</Typography>
            </Box>
            <Box className={classes.content}>
                <Box className={classes.footerLinks} style={styles.footerLinks(isLargeScreen, isMediumScreen)}>
                    <Box style={styles.linksInlineStyle(isLargeScreen)}>
                        <Link to="/about" className={classes.textLink}>
                            {TEXT.footer.aboutProject}
                        </Link>
                        <Link to="/collaboration" className={classes.textLink}>
                            {TEXT.footer.advertisingAndCollaboration}
                        </Link>
                        <Link to="/add-company" className={classes.textLink}>
                            {TEXT.footer.placeCompany}
                        </Link>
                        <Link to="/contact" className={classes.textLink}>
                            {TEXT.footer.contactUs}
                        </Link>
                    </Box>
                    <Box style={styles.linksInlineStyle(isLargeScreen)}>
                        <Link to="/privacy" className={privacyPolicyStyle}>
                            {TEXT.footer.privacyPolicy}
                        </Link>
                        <Link to="/terms" className={classes.textLink}>
                            {TEXT.footer.termsOfUse}
                        </Link>
                        <Link to="/agreement" className={classes.textLink}>
                            {TEXT.footer.publicContract}
                        </Link>
                    </Box>
                </Box>
                <Box className={classes.socialNetworksContainer} style={styles.socialNetworksStyle(isSmallScreen)}>
                    <Box className={classes.iconWrapper}>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/taex.ltd">
                            <Icon className={`${classes.facebook} fab fa-facebook-f`} />
                        </a>
                    </Box>
                    <Box className={classes.iconWrapper}>
                        <a target="_blank" rel="noopener noreferrer" href="https://vk.com/taexel">
                            <Icon className={`${classes.vk} fab fa-vk`} />
                        </a>
                    </Box>
                    <Box className={classes.iconWrapper}>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.youtube.com/channel/UC0aSWs6RL2rLzxv118EAouQ">
                            <Icon className={`${classes.youtube} fab fa-youtube`} />
                        </a>
                    </Box>
                    <div className={classes.iconWrapper}>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/taex.el">
                            <Icon className={`${classes.instagram} fab fa-instagram`} />
                        </a>
                    </div>
                    <div className={classes.iconWrapper}>
                        <a target="_blank" rel="noopener noreferrer" href="https://t.me/taex_ltd">
                            <Icon className={`${classes.telegram} fab fa-telegram`} />
                        </a>
                    </div>
                </Box>
            </Box>
        </Box>
    );
};

export default Footer;
