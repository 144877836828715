import React from 'react';
import { LinkContext } from '../context';

export function useErrorState() {
    const context = React.useContext(LinkContext);
    if (context === undefined) {
        return null;
    }
    return context;
}
