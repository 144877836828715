import { NotificationManager } from 'react-notifications';
import { api } from '../api';
import { GET_EVENT_STATUSES_FAILURE, GET_EVENT_STATUSES_SUCCESS } from '../constants/event';
import { javaRootPrivate } from '../constants/urls';

export const getEventStatuses = () => async dispatch => {
    return api
        .get(`${javaRootPrivate}/events/types`)
        .then(({ data }) => {
            dispatch({ type: GET_EVENT_STATUSES_SUCCESS, payload: data });
        })
        .catch(() => {
            const error = 'Ошибка запроса статусов работ';
            dispatch({ type: GET_EVENT_STATUSES_FAILURE, payload: error });
            NotificationManager.error(error);
        });
};
