import {
    GET_ALL_SERVICES_FAILURE,
    GET_ALL_SERVICES_REQUEST,
    GET_ALL_SERVICES_SUCCESS,
    GET_ALL_MARKS_REQUEST,
    GET_ALL_MARKS_SUCCESS,
    GET_ALL_MARKS_FAILURE,
    GET_CATEGORY_ITEMS_FAILURE,
    GET_CATEGORY_ITEMS_REQUEST,
    GET_CATEGORY_ITEMS_SUCCESS,
    GET_COMPANIES_REQUEST,
    GET_COMPANIES_FAILURE,
    GET_COMPANIES_SUCCESS,
    GET_SELECTABLE_COMPANIES_REQUEST,
    GET_SELECTABLE_COMPANIES_FAILURE,
    GET_SELECTABLE_COMPANIES_SUCCESS,
} from '../constants/autoservices';

const initialState = {
    services: [],
    isLoading: false,
    categoryItems: [],
    rootCategories: [],
    companies: [
        {
            content: [],
            empty: false,
            first: true,
            last: false,
            number: 0,
            numberOfElements: 0,
            pageable: { pageSize: 1, pageNumber: 0, offset: 0, unpaged: false },
            size: 1,
            sort: { sorted: false, unsorted: true, empty: true },
            totalElements: 0,
            totalPages: 0,
        },
    ],
    selectableCompanies: [
        {
            content: [],
            empty: false,
            first: true,
            last: false,
            number: 0,
            numberOfElements: 0,
            pageable: { pageSize: 1, pageNumber: 0, offset: 0, unpaged: false },
            size: 1,
            sort: { sorted: false, unsorted: true, empty: true },
            totalElements: 0,
            totalPages: 0,
        },
    ],
    marks: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CATEGORY_ITEMS_REQUEST:
        case GET_COMPANIES_REQUEST:
        case GET_ALL_SERVICES_REQUEST:
        case GET_ALL_MARKS_REQUEST:
        case GET_SELECTABLE_COMPANIES_REQUEST:
            return { ...state, isLoading: true };
        case GET_CATEGORY_ITEMS_FAILURE:
        case GET_ALL_SERVICES_FAILURE:
        case GET_COMPANIES_FAILURE:
        case GET_ALL_MARKS_FAILURE:
        case GET_SELECTABLE_COMPANIES_FAILURE:
            return { ...state, error: action.error };
        case GET_ALL_SERVICES_SUCCESS:
            return { ...state, services: action.payload };
        case GET_ALL_MARKS_SUCCESS:
            return { ...state, marks: action.payload };
        case GET_CATEGORY_ITEMS_SUCCESS:
            return { ...state, categoryItems: action.payload };
        case GET_COMPANIES_SUCCESS:
            return { ...state, companies: action.payload };
        case GET_SELECTABLE_COMPANIES_SUCCESS:
            return { ...state, selectableCompanies: action.payload };
        default:
            return state;
    }
};

export default reducer;
