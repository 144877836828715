import {
    SET_MILEAGE,
    GET_FAVORITE_CAR_REQUEST,
    GET_FAVORITE_CAR_FAILURE,
    GET_FAVORITE_CAR_SUCCESS,
    UPDATE_FAVORITE_CAR_REQUEST,
    UPDATE_FAVORITE_CAR_SUCCESS,
    UPDATE_FAVORITE_CAR_FAILURE,
    GET_AUTO_FIELDS_REQUEST,
    GET_AUTO_FIELDS_SUCCESS,
    GET_AUTO_FIELDS_FAILURE,
    GET_FAVORITE_AUTO_PAYMENTS_REQUEST,
    GET_FAVORITE_AUTO_PAYMENTS_SUCCESS,
    GET_FAVORITE_AUTO_PAYMENTS_FAILURE,
    GET_AUTO_CATEGORY_SPARES_REQUEST,
    GET_AUTO_CATEGORY_SPARES_SUCCESS,
    GET_AUTO_CATEGORY_SPARES_FAILURE,
} from '../constants/mainPage';

const initialState = {
    mileage: 0,
    isLoading: false,
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MILEAGE:
            return { ...state, mileage: action.payload };
        case GET_FAVORITE_CAR_REQUEST:
        case GET_AUTO_FIELDS_REQUEST:
        case UPDATE_FAVORITE_CAR_REQUEST:
        case GET_FAVORITE_AUTO_PAYMENTS_REQUEST:
            return { ...state, isLoading: true };
        case GET_FAVORITE_CAR_FAILURE:
        case GET_AUTO_FIELDS_FAILURE:
        case UPDATE_FAVORITE_CAR_FAILURE:
        case GET_FAVORITE_AUTO_PAYMENTS_FAILURE:
            return { ...state, error: action.payload, isLoading: false };
        case GET_FAVORITE_CAR_SUCCESS:
            return { ...state, favoriteCar: action.payload, isLoading: false };
        case UPDATE_FAVORITE_CAR_SUCCESS:
            return { ...state, isLoading: false };
        case GET_AUTO_FIELDS_SUCCESS:
            return { ...state, fields: action.payload, isLoading: false };
        case GET_FAVORITE_AUTO_PAYMENTS_SUCCESS:
            return { ...state, payments: action.payload };
        case GET_AUTO_CATEGORY_SPARES_SUCCESS:
            return { ...state, spares: action.payload, isLoading: false };
        case GET_AUTO_CATEGORY_SPARES_REQUEST:
            return { ...state, isLoading: true };
        case GET_AUTO_CATEGORY_SPARES_FAILURE:
            return { ...state, isLoading: false, spares: { content: [] } };
        default:
            return state;
    }
};

export default reducer;
