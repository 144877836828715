export const GET_WORK_TIMES_REQUEST = '@@calendar/GET_WORK_TIMES_REQUEST';
export const GET_WORK_TIMES_SUCCESS = '@@calendar/GET_WORK_TIMES_SUCCESS';
export const GET_WORK_TIMES_FAILURE = '@@calendar/GET_WORK_TIMES_FAILURE';

export const GET_EVENTS_REQUEST = '@@calendar/GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = '@@calendar/GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILURE = '@@calendar/GET_EVENTS_FAILURE';

export const GET_EVENT_PLACES_REQUEST = '@@calendar/GET_EVENT_PLACES_REQUEST';
export const GET_EVENT_PLACES_SUCCESS = '@@calendar/GET_EVENT_PLACES_SUCCESS';
export const GET_EVENT_PLACES_FAILURE = '@@calendar/GET_EVENT_PLACES_FAILURE';

export const GET_WORK_TYPES_REQUEST = '@@calendar/GET_WORK_TYPES_REQUEST';
export const GET_WORK_TYPES_SUCCESS = '@@calendar/GET_WORK_TYPES_SUCCESS';
export const GET_WORK_TYPES_FAILURE = '@@calendar/GET_WORK_TYPES_FAILURE';

export const GET_JOBS_BY_PLACE_REQUEST = '@@calendar/GET_JOBS_BY_PLACE_REQUEST';
export const GET_JOBS_BY_PLACE_SUCCESS = '@@calendar/GET_JOBS_BY_PLACE_SUCCESS';
export const GET_JOBS_BY_PLACE_FAILURE = '@@calendar/GET_JOBS_BY_PLACE_FAILURE';

export const SAVE_EVENTS_REQUEST = '@@calendar/SAVE_EVENTS_REQUEST';
export const SAVE_EVENTS_SUCCESS = '@@calendar/SAVE_EVENTS_SUCCESS';
export const SAVE_EVENTS_FAILURE = '@@calendar/SAVE_EVENTS_FAILURE';
