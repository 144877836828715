import {
    GET_AUTO_MILEAGE_FAILURE,
    GET_AUTO_MILEAGE_REQUEST,
    GET_AUTO_MILEAGE_SUCCESS,
    CREATE_AUTO_MILEAGE_FAILURE,
    CREATE_AUTO_MILEAGE_REQUEST,
    CREATE_AUTO_MILEAGE_SUCCESS,
} from '../constants/mileage';

const initialState = {
    carMileage: 0,
    error: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AUTO_MILEAGE_FAILURE:
        case CREATE_AUTO_MILEAGE_FAILURE:
            return { ...state, error: action.payload, isLoading: false };
        case GET_AUTO_MILEAGE_REQUEST:
        case CREATE_AUTO_MILEAGE_REQUEST:
            return { ...state, isLoading: true };
        case GET_AUTO_MILEAGE_SUCCESS:
        case CREATE_AUTO_MILEAGE_SUCCESS:
            return { ...state, carMileage: action.payload, isLoading: false };
        default:
            return state;
    }
};

export default reducer;
