export const GET_ALL_SERVICES_REQUEST = '@autoservice//GET_ALL_SERVICES_REQUEST';
export const GET_ALL_SERVICES_SUCCESS = '@autoservice//GET_ALL_SERVICES_SUCCESS';
export const GET_ALL_SERVICES_FAILURE = '@autoservice//GET_ALL_SERVICES_FAILURE';

export const GET_ALL_MARKS_REQUEST = '@autoservice//GET_ALL_MARKS_REQUEST';
export const GET_ALL_MARKS_SUCCESS = '@autoservice//GET_ALL_MARKS_SUCCESS';
export const GET_ALL_MARKS_FAILURE = '@autoservice//GET_ALL_MARKS_FAILURE';

export const GET_CATEGORY_ITEMS_REQUEST = '@autoservice//GET_CATEGORY_ITEMS_REQUEST';
export const GET_CATEGORY_ITEMS_SUCCESS = '@autoservice//GET_CATEGORY_ITEMS_SUCCESS';
export const GET_CATEGORY_ITEMS_FAILURE = '@autoservice//GET_CATEGORY_ITEMS_FAILURE';

export const GET_COMPANIES_REQUEST = '@autoservice//GET_COMPANIES_REQUEST';
export const GET_COMPANIES_FAILURE = '@autoservice//GET_COMPANIES_FAILURE';
export const GET_COMPANIES_SUCCESS = '@autoservice//GET_COMPANIES_SUCCESS';

export const GET_SELECTABLE_COMPANIES_REQUEST = '@autoservice//GET_SELECTABLE_COMPANIES_REQUEST';
export const GET_SELECTABLE_COMPANIES_FAILURE = '@autoservice//GET_SELECTABLE_COMPANIES_FAILURE';
export const GET_SELECTABLE_COMPANIES_SUCCESS = '@autoservice//GET_SELECTABLE_COMPANIES_SUCCESS';
