import axios from 'axios';
import Cookies from 'js-cookie';
import { get } from 'lodash';
import { NotificationManager } from 'react-notifications';
import { WEBVIEW_ACTIONS } from 'constants/webviewActions';
import { getToken } from '../services/firebase';
import { api, authorizeApi } from '../api';
import {
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CLOSE_MODAL_AFTER_REGISTRATION,
    COMPLETE_REGISTRATION,
    COMPLETE_REGISTRATION_FAILURE,
    GET_USER_INFO_FAILURE,
    GET_USER_INFO_REQUEST,
    GET_USER_INFO_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    REGISTRATION_CLEAR,
    REGISTRATION_FAILURE,
    REGISTRATION_REQUEST,
    REGISTRATION_SUCCESS,
    GET_SIGNUP_INFO,
    SEND_EMAIL_FAILURE,
    SEND_EMAIL_REQUEST,
    SEND_EMAIL_SUCCESS,
    RESTORE_TOKEN_REQUEST,
    RESTORE_TOKEN_FAILURE,
    RESTORE_TOKEN_SUCCESS,
    GET_USER_LAW_INFO_FAILURE,
    GET_USER_LAW_INFO_SUCCESS,
    GET_USER_LAW_INFO_REQUEST,
    UPDATE_USER_LAW_INFO_FAILURE,
    UPDATE_USER_LAW_INFO_SUCCESS,
    UPDATE_USER_LAW_INFO_REQUEST,
} from '../constants/authFlow';
import { javaRoot, javaRootPrivate, nodeRoot, nodeRootPrivate } from '../constants/urls';

export const registration = user => dispatch => {
    dispatch({
        type: REGISTRATION_REQUEST,
    });

    console.log('--user', user);
    return new Promise((resolve, reject) => {
        api.post(`${nodeRoot}/registration`, user)
            .then(resp => {
                console.log('--resp', resp);
                if (resp.status < 300) {
                    dispatch({
                        type: REGISTRATION_SUCCESS,
                        payload: resp.data,
                    });
                    resolve(true);
                } else {
                    dispatch({
                        type: REGISTRATION_FAILURE,
                        payload: resp,
                    });
                    resolve(resp.statusText);
                }
            })
            .catch(error => {
                dispatch({
                    type: REGISTRATION_FAILURE,
                    payload: error.response,
                });
                reject(error.response.data);
            });
    });
};

export const registrationClient = user => dispatch => {
    dispatch({
        type: REGISTRATION_REQUEST,
    });

    return new Promise((resolve, reject) => {
        axios
            .post(`${javaRoot}/registration/client`, { ...user })
            .then(resp => {
                if (resp.status < 300) {
                    dispatch({
                        type: REGISTRATION_SUCCESS,
                        payload: resp.data,
                    });
                    resolve(true);
                } else {
                    dispatch({
                        type: REGISTRATION_FAILURE,
                        payload: resp,
                    });
                    resolve(resp.statusText);
                }
            })
            .catch(error => {
                dispatch({
                    type: REGISTRATION_FAILURE,
                    payload: error,
                });
                reject(error.response.data.email || error.response.data.username);
            });
    });
};

export const login = user => dispatch => {
    dispatch({
        type: LOGIN_REQUEST,
    });
    return new Promise(resolve => {
        api.post(`${nodeRoot}/auth/sign-in/email`, { email: user.username, ...user })
            .then(resp => {
                localStorage.setItem('isAuth', 'true');
                authorizeApi(resp.data.accessToken);
                Cookies.set('authKey', resp.data.accessToken, {
                    path: '/',
                    expires: 7,
                    secure: true,
                    sameSite: 'Lax',
                });

                Cookies.set('refreshToken', resp.data.refreshToken, {
                    path: '/',
                    expires: 7,
                    secure: true,
                    sameSite: 'Lax',
                });
                if (resp.status === 201) {
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: resp,
                    });
                    resolve({
                        token: resp.data.accessToken,
                        secondsRemain: 0,
                    });
                } else {
                    dispatch({
                        type: LOGIN_FAILURE,
                        payload: resp.statusText,
                    });
                    resolve({
                        token: false,
                        secondsRemain: 0,
                    });
                }
            })
            .catch(error => {
                console.log('---error', error);
                dispatch({
                    type: LOGIN_FAILURE,
                    payload: error,
                });
                if (get(error, 'response.status') === 429) {
                    resolve({
                        token: false,
                        secondsRemain: get(error, 'response.data.secondsRemain', 0),
                    });
                } else {
                    resolve({
                        token: false,
                        secondsRemain: 0,
                    });
                }
            });
    });
};

export const loginWithOAuth = payload => dispatch => {
    console.log('---payload', payload);
    localStorage.setItem('isAuth', 'true');
    authorizeApi(payload.accessToken);
    Cookies.set('authKey', payload.accessToken, {
        path: '/',
        expires: 7,
        secure: true,
        sameSite: 'Lax',
    });

    Cookies.set('refreshToken', payload.refreshToken, {
        path: '/',
        expires: 7,
        secure: true,
        sameSite: 'Lax',
    });
    console.log('---success', payload);
    dispatch({
        type: LOGIN_SUCCESS,
        payload,
    });
    return payload;
};

// export const signInWithGoogle = accessToken => dispatch => {
//     dispatch({
//         type: LOGIN_REQUEST,
//     });
//     return api
//         .post(`${nodeRoot}/auth/google/sign-in`, { accessToken })
//         .then(resp => {
//             localStorage.setItem('isAuth', 'true');
//             authorizeApi(resp.data.authorizationToken);
//             Cookies.set('authKey', resp.data.authorizationToken, {
//                 path: '/',
//                 expires: 7,
//                 secure: true,
//                 sameSite: 'Lax',
//             });

//             Cookies.set('refreshToken', resp.data.refreshToken, {
//                 path: '/',
//                 expires: 7,
//                 secure: true,
//                 sameSite: 'Lax',
//             });
//             if (resp.status === 200) {
//                 dispatch({
//                     type: LOGIN_SUCCESS,
//                     payload: resp,
//                 });
//                 return resp.data.authorizationToken;
//             } else {
//                 dispatch({
//                     type: LOGIN_FAILURE,
//                     payload: resp.statusText,
//                 });
//                 throw new Error('Ошибка входа через Google');
//             }
//         })
//         .catch(error => {
//             dispatch({
//                 type: LOGIN_FAILURE,
//                 payload: error,
//             });
//             throw new Error('Ошибка входа через Google');
//         });
// };

export const signInWithGoogle = ({ email, accessToken }) => dispatch => {
    dispatch({
        type: LOGIN_REQUEST,
    });

    return api
        .post(`${javaRoot}/google/registration`, { email, token: accessToken })
        .then(resp => {
            localStorage.setItem('isAuth', 'true');
            authorizeApi(resp.data.authorizationToken);
            Cookies.set('authKey', resp.data.authorizationToken, {
                path: '/',
                expires: 7,
                secure: true,
                sameSite: 'Lax',
            });

            Cookies.set('refreshToken', resp.data.refreshToken, {
                path: '/',
                expires: 7,
                secure: true,
                sameSite: 'Lax',
            });
            if (resp.status === 200) {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: resp,
                });
                return resp.data.authorizationToken;
            } else {
                dispatch({
                    type: LOGIN_FAILURE,
                    payload: resp.statusText,
                });
                throw new Error('Ошибка входа через Google');
            }
        })
        .catch(error => {
            dispatch({
                type: LOGIN_FAILURE,
                payload: error,
            });
            throw new Error('Ошибка входа через Google');
        });
};

export const logout = () => {
    Cookies.remove('authKey', { path: '/' });
    Cookies.remove('refreshToken', { path: '/' });
    localStorage.clear();
    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ action: WEBVIEW_ACTIONS.logout }));
    }
    return dispatch => {
        dispatch({
            type: LOGOUT_SUCCESS,
        });
    };
};

export const getUserInfo = authKey => async dispatch => {
    dispatch({
        type: GET_USER_INFO_REQUEST,
    });
    const fbToken = await getToken();
    return api
        .get(`${javaRootPrivate}/users/info`, {
            headers: {
                Accept: 'application/json;',
                Authorization: authKey,
                'fb-token': fbToken,
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
            },
        })
        .then(({ status, data }) => {
            if (!(status === 403)) {
                dispatch({ type: GET_USER_INFO_SUCCESS, payload: data });
            } else {
                dispatch({ type: GET_USER_INFO_FAILURE, payload: data });
            }
            return data;
        })
        .catch(err => {
            dispatch({ type: GET_USER_INFO_FAILURE, payload: err });
        });
};

export const getUserLawInfo = () => dispatch => {
    dispatch({
        type: GET_USER_LAW_INFO_REQUEST,
    });
    return api
        .get(`${javaRootPrivate}/users/law-client`)
        .then(({ data }) => {
            dispatch({ type: GET_USER_LAW_INFO_SUCCESS, payload: data });
            return data;
        })
        .catch(err => {
            dispatch({ type: GET_USER_LAW_INFO_FAILURE, payload: err });
        });
};

export const updateUserLawInfo = body => dispatch => {
    dispatch({
        type: UPDATE_USER_LAW_INFO_REQUEST,
    });
    return api
        .put(`${javaRootPrivate}/users/law-client`, body)
        .then(({ data }) => {
            dispatch({ type: UPDATE_USER_LAW_INFO_SUCCESS, payload: data });
            return data;
        })
        .catch(err => {
            dispatch({ type: UPDATE_USER_LAW_INFO_FAILURE, payload: err });
        });
};

export const closeModalAfterRegistration = () => dispatch => {
    dispatch({
        type: CLOSE_MODAL_AFTER_REGISTRATION,
    });
};

export const sendEmail = email => dispatch => {
    dispatch({
        type: SEND_EMAIL_REQUEST,
    });
    return api
        .post(`${nodeRoot}/password`, {
            email
        })
        .then(resp => {
            console.log('--resp',resp);
            if (resp.status < 300) {
                dispatch({
                    type: SEND_EMAIL_SUCCESS,
                });
            } else {
                dispatch({
                    type: SEND_EMAIL_FAILURE,
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SEND_EMAIL_FAILURE,
            });
            throw error;
        });
};

export const restorePassword = ({ email, token, password }) => dispatch => {
    dispatch({
        type: CHANGE_PASSWORD_REQUEST,
    });
    const formData = new FormData();
    formData.append('email', email);
    formData.append('token', token);
    formData.append('password', password);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return api
        .put(`${javaRoot}/password`, formData, config)
        .then(() => {
            dispatch({
                type: CHANGE_PASSWORD_SUCCESS,
            });
        })
        .catch(error => {
            dispatch({
                type: CHANGE_PASSWORD_FAILURE,
            });
            throw error;
        });
};

export const clearSendingEmail = () => dispatch => {
    dispatch({
        type: SEND_EMAIL_FAILURE,
    });
};

export const clearChangingPassword = () => dispatch => {
    dispatch({
        type: CHANGE_PASSWORD_FAILURE,
    });
};

export const clearRegistration = () => dispatch => {
    dispatch({
        type: REGISTRATION_CLEAR,
    });
};

export const changePassword = password => dispatch => {
    dispatch({
        type: CHANGE_PASSWORD_REQUEST,
    });
    return api
        .put(`${nodeRootPrivate}/users/password`, {
            ...password,
        })
        .then(() => {
            dispatch({
                type: CHANGE_PASSWORD_SUCCESS,
            });
            NotificationManager.success('Пароль изменён!', '', 3500);
        })
        .catch(error => {
            dispatch({
                type: CHANGE_PASSWORD_FAILURE,
            });
            throw error;
        });
};

export const completeRegistration = token => dispatch => {
    const formData = new FormData();
    formData.append('token', token);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    axios
        .put(`${javaRoot}/registration`, formData, config)
        .then(resp => {
            if (resp.status < 300) {
                dispatch({
                    type: COMPLETE_REGISTRATION,
                });
            } else {
                dispatch({
                    type: COMPLETE_REGISTRATION_FAILURE,
                });
            }
        })
        .catch(() => {
            dispatch({
                type: COMPLETE_REGISTRATION_FAILURE,
            });
        });
};

export const validatePasswordToken = token => () => {
    return api
        .get(`${javaRoot}/password`, {
            params: {
                token,
            },
        })
        .then(() => {
            return true;
        })
        .catch(() => {
            return false;
        });
};

export const checkEmail = email => {
    return axios
        .get(`${javaRoot}/users/emails`, { params: { email } })
        .then(response => response)
        .catch(error => error);
};

export const getSignupInfo = token => dispatch => {
    return axios
        .get(`${javaRoot}/registration?token=${token}`)
        .then(response => {
            dispatch({
                type: GET_SIGNUP_INFO,
                payload: response.data,
            });
            return response.data;
        })
        .catch(error => {
            throw error;
        });
};

export const restoreToken = () => dispatch => {
    dispatch({
        type: RESTORE_TOKEN_REQUEST,
    });
    const authorizationToken = Cookies.get('authKey');
    const refreshToken = Cookies.get('refreshToken');
    if (refreshToken === 'undefined' || authorizationToken === 'undefined' || !authorizationToken || !refreshToken) {
        dispatch({
            type: RESTORE_TOKEN_FAILURE,
        });
        dispatch(logout());
        return Promise.resolve(false);
    }
    return api
        .post(`${javaRoot}/auth/refresh`, {
            authorizationToken,
            refreshToken,
        })
        .then(({ data }) => {
            localStorage.setItem('isAuth', 'true');
            authorizeApi(data.authorizationToken);
            Cookies.set('authKey', data.authorizationToken, {
                path: '/',
                expires: 7,
                secure: true,
                sameSite: 'Lax',
            });
            Cookies.set('refreshToken', data.refreshToken, {
                path: '/',
                expires: 7,
                secure: true,
                sameSite: 'Lax',
            });
            dispatch({
                type: RESTORE_TOKEN_SUCCESS,
            });
            return true;
        })
        .catch(() => {
            dispatch({
                type: RESTORE_TOKEN_FAILURE,
            });
            dispatch(logout());
            return false;
        });
};

export const loginWithMobile = ({ refreshToken, token }) => async dispatch => {
    try {
        dispatch({
            type: LOGIN_REQUEST,
        });
        const { data } = await api.post(`${javaRoot}/auth/refresh`, {
            authorizationToken: token,
            refreshToken,
        });
        localStorage.setItem('isAuth', 'true');
        authorizeApi(data.authorizationToken);
        Cookies.set('authKey', data.authorizationToken, {
            path: '/',
            expires: 7,
            secure: true,
            sameSite: 'Lax',
        });
        Cookies.set('refreshToken', data.refreshToken, {
            path: '/',
            expires: 7,
            secure: true,
            sameSite: 'Lax',
        });
        const userData = await dispatch(getUserInfo(data.authorizationToken));
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ action: WEBVIEW_ACTIONS.loginSuccess }));
        }

        dispatch({
            type: LOGIN_SUCCESS,
            payload: {
                userData,
                authKey: data.authorizationToken,
                data: {
                    token: data.authorizationToken,
                },
            },
        });
    } catch (err) {
        dispatch({
            type: LOGIN_FAILURE,
        });
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ action: WEBVIEW_ACTIONS.loginError }));
        }
    }
};
