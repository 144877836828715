import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    navbar: {
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        width: 280,
        minWidth: 280,
        height: '100%',
        zIndex: 4,
        transition: theme.transitions.create(['width', 'min-width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter,
        }),
        boxShadow: theme.shadows[3],
    },
    profileButton: {
        justifyContent: 'flex-start',
    },
    drawerLink: {
        padding: '8px 6px',
        margin: '4px 0px',
        justifyContent: 'flex-start',
        fontSize: 16,
        fontFamily: 'Roboto-Light',
        textTransform: 'none',
        whiteSpace: 'nowrap',
        '@media(max-height: 840px)': {
            fontSize: 14,
            margin: 0,
        },
        '@media(max-height: 700px)': {
            fontSize: 13,
            margin: 0,
        },
        '@media(max-height: 600px)': {
            fontSize: 11,
            margin: 0,
        },
    },
    avatar: {
        height: 70,
        width: 70,
        '@media(max-height: 700px)': {
            height: 50,
            width: 50,
        },
    },
    avatarIcon: {
        fontSize: 70,
        color: '#00606D',
        '@media(max-height: 700px)': {
            fontSize: 50,
        },
    },
    clientNumberTitle: {
        fontSize: 15,
        fontFamily: 'Roboto-Light',
    },
    clientNumberText: {
        fontSize: 19,
        fontFamily: 'Roboto-Light',
        '@media(max-height: 700px)': {
            fontSize: 17,
        },
    },
    addWorkSectionTitle: {
        margin: '24px 0px 12px',
        color: '#0e444d',
        fontFamily: 'Roboto-Bold',
        '@media(max-height: 700px)': {
            margin: '12px 0px',
        },
        '@media(max-height: 600px)': {
            margin: '6px 0px',
        },
    },
    bottomNavigation: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        padding: 0,
        zIndex: 100000,
        background: '#fff',
        borderRadius: 0,
        boxShadow: '0px -1px 5px 0px rgba(0,0,0,0.2)',
        paddingBottom: 0,
        '& .MuiBottomNavigationAction-root': {
            '@media(max-width:420px)': {
                minWidth: 70,
            },
            '@media(max-width:360px)': {
                minWidth: 60,
            },
        },
    },
    bottomNavigationIcon: {
        height: 31,
        width: 31,
        imageRendering: 'optimizequality',
    },
    promotionIconSelected: {
        color: '#0DABC2',
        fontSize: 22,
    },
    promotionIcon: {
        color: '#0E444D',
        fontSize: 22,
    },
}));

export default useStyles;
