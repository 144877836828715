import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    height: 50
  },
  paper: {
    margin: theme.spacing(1),
    height: 100,
    width: 100,
    borderRadius: '50%',
    top: '-25%',
    position: 'absolute',
  },
  svg: {
    width: 100,
    height: 100
  },
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 2,
    width: 100,
    height: 100,
    borderRadius: '50%'
  },
  closeIcon: {
    cursor: 'pointer',
    color: 'rgba(255,255,255, 0.9)',
    width: 'fit-content'
  }
}));

export default useStyles;
