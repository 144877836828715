export default {
    main: 'Главная',
    myGarage: 'Мой гараж',
    reviews: 'Отзывы',
    carServices: 'Автосервисы',
    promotions: 'Акции',
    info: 'Информация',
    refueling: 'Заправка',
    washing: 'Мойка',
    repairs: 'Обслуживание',
    other: 'Другое',
    entry: 'Войти',
    signup: 'Зарегистрироваться',
    carReviews: 'Отзывы об автомобилях',
    sparesReviews: 'Отзывы о запчастях',
    garagesReviews: 'Отзывы об автосервисах',
    profile: 'Профиль',
    settings: 'Настройки',
    logout: 'Выход',
    add: 'Добавить',
    addReview: 'Добавить отзыв',
    footer: {
        aboutProject: 'О проекте',
        advertisingAndCollaboration: 'Реклама и сотрудничество',
        placeCompany: 'Разместить компанию',
        sitePolicy: 'Правила сайта',
        privacyPolicy: 'Политика обработки персональных данных',
        termsOfUse: 'Пользовательское соглашение',
        publicContract: 'Публичный договор',
        logoCaption: '©2016-2022 taexel.by',
        contactUs: 'Связаться с нами',
    },
    mobileReviewsNavBar: {
        top: 'Топ 16',
        filters: 'Фильтры',
    },
    select: {
        carType: {
            car: {
                label: 'Легковой',
                value: 'car',
            },
            truck: {
                label: 'Грузовой',
                value: 'truck',
            },
        },
    },
    selector: {
        placeholder: {
            country: 'Страна',
            region: 'Регион',
            city: 'Город',
            type: 'Тип',
            mark: 'Марка',
            model: 'Модель',
            generation: 'Поколение',
            series: 'Серия',
            modification: 'Модификация',
            brand: 'Производитель',
            product: 'Запчасть',
            units: 'Выберите подходящий вариант',
            fuel: 'Топливо',
        },
    },
    messages: {
        emptyCar: 'Отзывы на данный автомобиль отсутствуют',
        emptySpares: 'Отзывы на данный бренд отсутствуют',
        addCar: 'Вы успешно добавили автомобиль. Ожидайте модерации.',
        addOwner: 'Вы успешно добавили владельца. Ожидайте модерации.',
        addReport: 'Жалоба успешно отправлена. Ожидайте модерации.',
        addReportSuccess: 'Жалоба успешно отправлена. Ожидайте модерации.',
        deleteAccountSuccess: ' Профиль успешно удален.',
        addCarQuestion: 'Вы хотите добавить автомобиль?',
        addOwnerQuestion: 'Вы хотите добавить владельца?',
        editContact: 'Контакт успешно обновлен',
        deleteContact: 'Контакт успешно удален',
        editGallery: 'Редактирование галереи автомобиля',
        editGallerySuccess: 'Галерея автомобиля изменена',
        saveEvent: 'Вы успешно записались. Ожидайте модерации.',
        editAvatar: 'Редактирование фотографии пользователя',
        editAvatarSuccess: 'Фотография пользователя изменена',
    },
    profileHelper: {
        contacts:
            'Список Ваших контактов формируется автосервисами и дополняется в том случае, если Вы обращаетесь с другого номера. Редактирование доступно только Вам.', // TODO: Пожалуйста, периодически просматривайте и редактируйте (если это необходимо) список контактов.',
        personal:
            'Данные Вашего адреса требуются для автоматического формирования документов технического обслуживания. В случае посещения автосервиса, они будут заполнены сотрудниками автосервиса.',
    },
    garageHelper: {
        sell:
            'Продажа автомобиля, подразумевает смену владельца. После данного действия, вся история обслуживания автомобиля будет передана указанному владельцу. Автомобиль удалиться из Вашего гаража и будет не доступен для просмотра.',
        sellAttention: 'В случае ошибочного действия требуется обратиться к модератору ресурса.',
    },
    mileageHelper:
        'Одометр – прибор для измерения пройденного пути автомобиля. Измеряется в km (километрах) или в mi (мили).',
    days: {
        MONDAY: 'Понедельник',
        TUESDAY: 'Вторник',
        WEDNESDAY: 'Среда',
        THURSDAY: 'Четверг',
        FRIDAY: 'Пятница',
        SATURDAY: 'Суббота',
        SUNDAY: 'Воскресенье',
        dayOff: 'Выходной',
    },
    calendarDays: {
        понедельник: 'пн',
        вторник: 'вт',
        среда: 'ср',
        четверг: 'чт',
        пятница: 'пт',
        суббота: 'сб',
        воскресенье: 'вс',
    },
};
