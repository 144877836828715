export function mergeObjects(initialObject, mergeObject) {
    const newObject = initialObject;
    Object.keys(initialObject).forEach(key => {
        if ({}.hasOwnProperty.call(mergeObject, key)) {
            newObject[key] = mergeObject[key] ? mergeObject[key] : initialObject[key];
        }
    });
}

export function createMergedObject(initialObject, mergeObject) {
    const newObject = { ...initialObject };
    Object.keys(initialObject).forEach(key => {
        if ({}.hasOwnProperty.call(mergeObject, key)) {
            newObject[key] = mergeObject[key] ? mergeObject[key] : initialObject[key];
        }
    });
    return newObject;
}
