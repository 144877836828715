import React from 'react';
import { FavoriteCarContext } from '../context';

export function useFavoriteCarContext() {
    const context = React.useContext(FavoriteCarContext);
    if (context === undefined) {
        return null;
    }
    return context;
}
