import React from 'react';
import { Button, Menu, MenuItem, Icon, Box } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { usePopupState, bindMenu } from 'material-ui-popup-state/hooks';
import { colors } from 'styles/colors';
import { CustomText } from '../Text';
import useStyles from './styles';

export function DropdownMenu({
    options = [],
    titleStyle = null,
    textStyle = null,
    title = 'Добавить',
    buttonLink = null,
    menuIcon = null,
    dropdownStyle = '',
    buttonStyle = '',
}) {
    const classes = useStyles();
    const history = useHistory();

    const popupState = usePopupState({ variant: 'popover', popupId: 'dropdownMenu' });

    const anchorEl = React.useRef(null);
    const [state, setState] = React.useState({
        timer: null,
        isHovering: false,
        isClicked: false,
        isScrolled: false,
    });

    function changeRef(element) {
        anchorEl.current = element;
    }

    function popupClose() {
        setState({ ...state, isHovering: false, isClicked: false, isScrolled: false });
        popupState.close();
    }

    function handleMenuItemClick(event) {
        event.preventDefault();
        popupClose();
    }

    function buttonOut() {
        if (!state.isClicked) {
            const newTimer = setTimeout(popupClose, 200);
            setState({ ...state, timer: newTimer, isHovering: true, isClicked: false });
        }
    }

    function onScroll(event) {
        if (event.nativeEvent.wheelDelta <= 0) {
            setState({ ...state, isScrolled: true });
            popupState.close();
        }
    }

    function clearTimer() {
        setState({ ...state, isHovering: true });
        !state.isScrolled && clearTimeout(state.timer);
    }

    function mouseEnter(event) {
        state.timer && clearTimeout(state.timer);
        popupState.open(event);
        setState({ ...state, isHovering: true });
    }

    function buttonClicked(event) {
        event.preventDefault();
        state.timer && clearTimeout(state.timer);
        setState({ ...state, isClicked: true, isScrolled: false });
        popupState.open(event);
    }

    function handleButton() {
        if (buttonLink) history.push(buttonLink);
    }

    return (
        <Box className={`${classes.dropdown} ${dropdownStyle}`}>
            <Button
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                ref={changeRef}
                className={buttonStyle}
                onClick={handleButton}
                onTouchEnd={buttonClicked}
                onMouseOver={mouseEnter}
                onBlur={() => {}}
                onFocus={() => {}}
                onMouseOut={buttonOut}
                onWheel={onScroll}
                disableRipple={state.isClicked}
                classes={{ root: state.isHovering ? classes.hover : classes.out }}>
                {menuIcon && <Icon className={menuIcon} />}
                <CustomText className={titleStyle} text={title} />
            </Button>
            <Box className={classes.menuContainer}>
                <Menu
                    id="simple-menu"
                    elevation={0}
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    PaperProps={{
                        style: {
                            marginTop: -6,
                        },
                    }}
                    {...bindMenu(popupState)}
                    MenuListProps={{ onMouseLeave: popupClose, onMouseOver: clearTimer }}
                    className={classes.menu}>
                    {options.map((option, index) => (
                        <MenuItem key={option.title} onClick={event => handleMenuItemClick(event, index)}>
                            <Link className={classes.link} to={option.linkTo}>
                                <Box className={classes.linkContainer}>
                                    <CustomText className={textStyle} text={option.title} />
                                    {option.icon && <Icon className={`${classes.icon} ${option.icon}`} />}
                                    {option.img &&
                                        (option.isSvg ? (
                                            option.img({ width: 24, height: 24, color: colors.secondary })
                                        ) : (
                                            <img className={classes.img} alt="" src={option.img} />
                                        ))}
                                </Box>
                            </Link>
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
        </Box>
    );
}
