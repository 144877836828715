import React from 'react';

export function PercentIcon({ width = 31, height = 31, color = '#3AAAB9' }) {
    return (
        <svg width={width} height={height} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={width} height={height} fill="none" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.2609 33.5926C36.2525 33.5926 39.5217 30.3444 39.5217 26.2963C39.5217 22.2482 36.2525 19 32.2609 19C28.2693 19 25 22.2482 25 26.2963C25 30.3444 28.2693 33.5926 32.2609 33.5926ZM32.2609 42.5926C41.2415 42.5926 48.5217 35.2965 48.5217 26.2963C48.5217 17.2961 41.2415 10 32.2609 10C23.2802 10 16 17.2961 16 26.2963C16 35.2965 23.2802 42.5926 32.2609 42.5926ZM17.7402 81.8851C15.9799 80.1308 15.9719 77.2784 17.7225 75.5143L75.086 17.7053C76.8365 15.9411 79.6827 15.9332 81.443 17.6876C83.2033 19.442 83.2113 22.2943 81.4607 24.0585L24.0972 81.8675C22.3467 83.6316 19.5005 83.6395 17.7402 81.8851ZM75 73.7037C75 77.7518 71.7308 81 67.7391 81C63.7475 81 60.4783 77.7518 60.4783 73.7037C60.4783 69.6556 63.7475 66.4074 67.7391 66.4074C71.7308 66.4074 75 69.6556 75 73.7037ZM84 73.7037C84 82.7039 76.7198 90 67.7391 90C58.7585 90 51.4783 82.7039 51.4783 73.7037C51.4783 64.7035 58.7585 57.4074 67.7391 57.4074C76.7198 57.4074 84 64.7035 84 73.7037Z"
                fill={color}
            />
        </svg>
    );
}
