import { NotificationManager } from 'react-notifications';
import { get } from 'lodash';
import { format } from 'date-fns';
import {
    SET_MILEAGE,
    GET_FAVORITE_CAR_REQUEST,
    GET_FAVORITE_CAR_FAILURE,
    GET_FAVORITE_CAR_SUCCESS,
    UPDATE_FAVORITE_CAR_REQUEST,
    UPDATE_FAVORITE_CAR_SUCCESS,
    UPDATE_FAVORITE_CAR_FAILURE,
    GET_AUTO_FIELDS_REQUEST,
    GET_AUTO_FIELDS_SUCCESS,
    GET_AUTO_FIELDS_FAILURE,
    GET_FAVORITE_AUTO_PAYMENTS_REQUEST,
    GET_FAVORITE_AUTO_PAYMENTS_SUCCESS,
    GET_FAVORITE_AUTO_PAYMENTS_FAILURE,
    GET_AUTO_CATEGORY_SPARES_REQUEST,
    GET_AUTO_CATEGORY_SPARES_SUCCESS,
    GET_AUTO_CATEGORY_SPARES_FAILURE,
    GET_FAVORITE_REFUELING_PAYMENTS_REQUEST,
    GET_FAVORITE_REFUELING_PAYMENTS_SUCCESS,
    GET_FAVORITE_REFUELING_PAYMENTS_FAILURE,
} from '../constants/mainPage';
import { api } from '../api';
import { javaRootPrivate } from '../constants/urls';

export const getFavoriteCar = () => dispatch => {
    dispatch({
        type: GET_FAVORITE_CAR_REQUEST,
    });
    return api
        .get(`${javaRootPrivate}/autos/assignments/selected`)
        .then(({ data }) => {
            dispatch({
                type: GET_FAVORITE_CAR_SUCCESS,
                payload: data,
            });
            return data;
        })
        .catch(error => {
            dispatch({
                type: GET_FAVORITE_CAR_FAILURE,
                payload: error,
            });
            if (get(error, 'response.status') === 500) NotificationManager.error('Ошибка получения автомобиля');
            return null;
        });
};

export const updateFavoriteCar =
    ({ autoId, userId }) =>
    dispatch => {
        dispatch({
            type: UPDATE_FAVORITE_CAR_REQUEST,
        });
        return api
            .put(`${javaRootPrivate}/autos/assignments`, { autoId, userId })
            .then(() => {
                dispatch({
                    type: UPDATE_FAVORITE_CAR_SUCCESS,
                });
                return dispatch(getFavoriteCar());
            })
            .catch(error => {
                dispatch({
                    type: UPDATE_FAVORITE_CAR_FAILURE,
                    payload: error,
                });
                throw error;
            });
    };

export const getAutoFields = (autoId, workId) => dispatch => {
    dispatch({
        type: GET_AUTO_FIELDS_REQUEST,
    });
    return api
        .get(`${javaRootPrivate}/autos/${autoId}/fields`, {
            params: { workId },
        })
        .then(({ data }) => {
            dispatch({
                type: GET_AUTO_FIELDS_SUCCESS,
                payload: data,
            });
        })
        .catch(error => {
            dispatch({
                type: GET_AUTO_FIELDS_FAILURE,
                payload: error,
            });
            NotificationManager.error('Ошибка получения категорий');
        });
};

export const getFavoriteAutoPayments =
    ({ categoryId = null, date, id, periodType }) =>
    async dispatch => {
        try {
            dispatch({
                type: GET_FAVORITE_AUTO_PAYMENTS_REQUEST,
            });
            const [{ data: eventPayments }, { data: sparesPayments }] = await Promise.all([
                api.get(`${javaRootPrivate}/autos/${id}/payments`, {
                    params: { categoryId, date: format(date, 'yyyy-MM-dd'), periodType },
                }),
                api.get(`${javaRootPrivate}/autos/${id}/payments/spares`, {
                    params: { categoryId, date: format(date, 'yyyy-MM-dd'), periodType },
                }),
            ]);
            const data = [...eventPayments, ...sparesPayments];
            dispatch({
                type: GET_FAVORITE_AUTO_PAYMENTS_SUCCESS,
                payload: data,
            });
            return data;
        } catch (error) {
            dispatch({
                type: GET_FAVORITE_AUTO_PAYMENTS_FAILURE,
                payload: error,
            });
        }
    };

export const getFavoriteRefuelingPayments =
    ({ date, id, period }) =>
    dispatch => {
        dispatch({
            type: GET_FAVORITE_REFUELING_PAYMENTS_REQUEST,
        });
        return api
            .get(`${javaRootPrivate}/refueling/${id}/payments`, {
                params: { date: format(date, 'yyyy-MM-dd'), period },
            })
            .then(({ data }) => {
                dispatch({
                    type: GET_FAVORITE_REFUELING_PAYMENTS_SUCCESS,
                    payload: data,
                });
                return data;
            })
            .catch(error => {
                dispatch({
                    type: GET_FAVORITE_REFUELING_PAYMENTS_FAILURE,
                    payload: error,
                });
            });
    };

export const getFavoriteAutoSpares =
    ({ autoId, categoryId, page = 0 }) =>
    dispatch => {
        dispatch({
            type: GET_AUTO_CATEGORY_SPARES_REQUEST,
        });
        return api
            .get(`${javaRootPrivate}/autos/${autoId}/spares`, {
                params: { categoryId, page, size: 10 },
            })
            .then(({ data }) => {
                dispatch({
                    type: GET_AUTO_CATEGORY_SPARES_SUCCESS,
                    payload: data,
                });
            })
            .catch(error => {
                dispatch({
                    type: GET_AUTO_CATEGORY_SPARES_FAILURE,
                    payload: error,
                });
            });
    };

export const setMileage = value => ({
    type: SET_MILEAGE,
    payload: value,
});

export const getFields = (autoId, workId) => {
    return api
        .get(`${javaRootPrivate}/autos/${autoId}/fields`, {
            params: { workId },
        })
        .then(({ data }) => {
            return data;
        })
        .catch(() => {
            NotificationManager.error('Ошибка получения категорий');
        });
};
