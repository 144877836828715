import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: '10px',
        paddingBottom: '8px',
    },
    headerContainer: {
        flexDirection: 'column',
    },
    title: {
        fontFamily: 'Roboto-Bold',
        color: '#00454e',
    },
    logoHeader: {
        width: '12em',
        marginRight: 16,
    },
    logoUnauthHeader: {
        width: '14em',
        margin: '0px 1px',
        '@media(max-width:600px)': {
            margin: '0px 16px',
        },
    },
    loginButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&:hover $loginButtonText': {
            color: '#0dabc2',
        },
    },
    loginButtonText: {
        paddingRight: '0.35em',
        fontFamily: 'Roboto-Bold',
        fontSize: ' 1.15rem',
        color: '#00444e',
        textTransform: 'capitalize',
        '&:hover': {
            color: '#0dabc2',
        },
    },
    ownerButtonText: {
        paddingRight: 16,
    },
});

export default useStyles;
