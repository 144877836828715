export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export const GET_SIGNUP_INFO = 'GET_SIGNUP_INFO';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';

export const CLOSE_MODAL_AFTER_REGISTRATION = ' CLOSE_MODAL_AFTER_REGISTRATION';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

export const SEND_OPT_CODE_REQUEST = 'SEND_OPT_CODE_REQUEST';
export const SEND_OPT_CODE_SUCCESS = 'SEND_OPT_CODE_SUCCESS';
export const SEND_OPT_CODE_FAILURE = 'SEND_OPT_CODE_FAILURE';

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';

export const REGISTRATION_CLEAR = 'REGISTRATION_CLEAR';
export const COMPLETE_REGISTRATION = 'COMPLETE_REGISTRATION';
export const COMPLETE_REGISTRATION_FAILURE = 'COMPLETE_REGISTRATION_FAILURE';

export const RESTORE_TOKEN_REQUEST = 'RESTORE_TOKEN_REQUEST';
export const RESTORE_TOKEN_SUCCESS = 'RESTORE_TOKEN_SUCCESS';
export const RESTORE_TOKEN_FAILURE = 'RESTORE_TOKEN_FAILURE';

export const GET_USER_LAW_INFO_FAILURE = 'GET_USER_LAW_INFO_FAILURE';
export const GET_USER_LAW_INFO_SUCCESS = 'GET_USER_LAW_INFO_SUCCESS';
export const GET_USER_LAW_INFO_REQUEST = 'GET_USER_LAW_INFO_REQUEST';

export const UPDATE_USER_LAW_INFO_FAILURE = 'UPDATE_USER_LAW_INFO_FAILURE';
export const UPDATE_USER_LAW_INFO_SUCCESS = 'UPDATE_USER_LAW_INFO_SUCCESS';
export const UPDATE_USER_LAW_INFO_REQUEST = 'UPDATE_USER_LAW_INFO_REQUEST';
