import { javaRootPrivate, nodeRoot } from '../constants/urls';
import { OTP_TYPES_KEYS } from '../constants/otp';
import { api } from '.';

export const deleteUser = async (comment, id) => {
    return api
        .delete(`${javaRootPrivate}/users`)
        .then(data => {
            return data;
        })
        .catch(err => {
            throw err;
        });
};

export const sendOtpCode = async ({ otpType, otpDestination, lastName, firstName }) => {
    return api
        .post(`${nodeRoot}/registration/token`, {
            provider: {
                type: otpType,
                [OTP_TYPES_KEYS[otpType]]: otpDestination,
            },
            lastName,
            firstName,
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            throw err;
        });
};

export const sendResetEmail = async ({ email }) => {
    return api
        .post(`${nodeRoot}/auth/password/token`, {
            email,
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            throw err;
        });
};

export const checkResetEmailOtp = async ({ email, otp }) => {
    return api
        .get(`${nodeRoot}/auth/password/reset`, {
            params: {
                email,
                otp,
            },
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            throw err;
        });
};

export const updateResetPassword = async ({ email, otp, password }) => {
    return api
        .put(`${nodeRoot}/auth/password/reset`, {
            email,
            otp,
            password,
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            throw err;
        });
};

export const checkEmail = email => {
    return api
        .get(`${nodeRoot}/registration/email`, { params: { email } })
        .then(response => response)
        .catch(error => error);
};

export const checkPhone = phone => {
    return api
        .get(`${nodeRoot}/registration/phone`, { params: { phone } })
        .then(response => response)
        .catch(error => error);
};

export const signInWithGoogle = accessToken => {
    return api
        .post(`${nodeRoot}/auth/google/sign-in`, { accessToken })
        .then(({ data }) => data)
        .catch(error => {
            throw error.response;
        });
};

export const signInWitApple = idToken => {
    return api
        .post(`${nodeRoot}/auth/apple/sign-in`, { idToken })
        .then(({ data }) => data)
        .catch(error => {
            throw error.response;
        });
};

export const signInWithPhone = phone => {
    return api
        .post(`${nodeRoot}/auth/sign-in/phone`, { phone })
        .then(({ data }) => data)
        .catch(error => {
            throw error.response;
        });
};

export const verifyPhone = ({ phone, otp }) => {
    return api
        .post(`${nodeRoot}/auth/sign-in/phone/verify`, { phone, otp })
        .then(({ data }) => data)
        .catch(error => {
            throw error.response;
        });
};
