import { Box, Button, CircularProgress, Icon } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { TreeDropdown } from 'components/TreeDropdown';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import { getSelectableCompanies } from '../../actions/autoservices';
import { getJobTree } from '../../actions/jobTree';
import { selectorThemes } from '../../modules/works/selectorStyles';
import '../../styles/ant-design.css';
import { mapTree, updateTreeDataV2 } from '../../utils/tree';
import Option from '../ReactSelect/Option';
import { init, initialState, reducer } from './reducer';
import { asyncSelectorStyles, selectorFullHeightStyles } from './selector';
import useStyles from './styles';

function RegistrationForServiceModal({
    CustomComponent,
    isOpen = false,
    setShowModal,
    title,
    confirmText,
    cancelText,
    handleConfirm,
    successMessage,
    errorMessage,
    resolveFunction,
    paperWidth,
    totalCompanies,
    jobTree,
    ...props
}) {
    const [state, dispatch] = useReducer(reducer, initialState, init());
    const stateAsObj = useMemo(() => state, [state]);
    const classes = useStyles({ isSelectCompany: !!stateAsObj.company });
    const history = useHistory();
    const [open, setOpen] = useState(true);
    const [isLoadingJobs, setLoadingJobs] = useState(false);

    const treeData = useMemo(() => {
        return !isEmpty(jobTree) ? [...updateTreeDataV2(mapTree(jobTree, false))] : [];
    }, [jobTree]);

    useEffect(() => {
        (async () => {
            try {
                if (isEmpty(jobTree)) {
                    setLoadingJobs(true);
                }
                await props.getJobTree();
                setLoadingJobs(false);
            } catch (err) {
                setLoadingJobs(false);
            }
        })();
    }, []);

    useEffect(() => {
        setOpen(isOpen);
        if (!isOpen) {
            dispatch({ type: 'initial' });
        }
    }, [isOpen]);

    async function loadOptions(search, loadedOptions, { page }) {
        try {
            const params = {
                workIds: (isEmpty(stateAsObj.selectedJobs) ? [] : [...stateAsObj.selectedJobs]).join(','),
            };
            const response = await props.getCompanies({
                page,
                params,
                withAddress: true,
            });
            dispatch({
                type: 'companies',
                payload: {
                    companies: response.content,
                    requestedJobs: stateAsObj.selectedJobs,
                },
            });
            const updatedCompanies = [...stateAsObj.companies, ...response.content];

            return {
                options: updatedCompanies,
                hasMore: updatedCompanies < response.totalElements,
                additional: {
                    page: page + 1,
                },
            };
        } catch (err) {
            return {
                options: stateAsObj.companies,
                hasMore: false,
                additional: {
                    page: page + 1,
                },
            };
        }
    }

    const handleClose = () => {
        setOpen(false);
        setShowModal(false);
    };

    function handleSelectCompany(select) {
        if (get(select, 'label')) {
            dispatch({ type: 'company', payload: select });
        }
    }

    function goToServiceStation() {
        const serviceStationId = stateAsObj.company.id;
        if (!stateAsObj.company.calendar) {
            history.push(`/service/station/${serviceStationId}?type=contacts`);
        } else if (serviceStationId) {
            history.push(`/service/station/${serviceStationId}?type=schedule`);
        } else {
            NotificationManager.error('Произошла ошибка. Попробуйте позже.');
        }
        handleClose();
    }

    function onTreeChange(value) {
        dispatch({
            type: 'selectedJobs',
            payload: value,
        });
    }

    return (
        <div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
                disableAutoFocus>
                <div className={`${classes.paper} ${classes.treeSelect}`}>
                    <Icon className={`${classes.closeIcon} fas fa-times`} onClick={handleClose} />
                    <h2 id="simple-modal-title">Выберите работы</h2>
                    <Box className={classes.inputGroup}>
                        {isLoadingJobs ? (
                            <Box display="flex" flex={1} justifyContent="center">
                                <CircularProgress size={80} style={{ color: '#00606d' }} />
                            </Box>
                        ) : (
                            <>
                                <Box display="flex" flex={1} flexDirection="column">
                                    <TreeDropdown
                                        value={state.selectedJobs}
                                        placeholder="Выбрать услугу"
                                        treeData={treeData}
                                        dropdownStyle={{ maxHeight: '250px', zIndex: 10000000 }}
                                        style={{ minHeight: 45, marginBottom: 4 }}
                                        onChange={onTreeChange}
                                    />
                                </Box>
                                <AsyncPaginate
                                    key={JSON.stringify(stateAsObj.selectedJobs)}
                                    className={classes.formControl}
                                    value={stateAsObj.company}
                                    loadOptions={loadOptions}
                                    loadingMessage={() => '...Загрузка'}
                                    components={{ Option }}
                                    styles={{
                                        ...asyncSelectorStyles,
                                        ...(stateAsObj.company && selectorFullHeightStyles),
                                    }}
                                    theme={selectorThemes}
                                    onChange={handleSelectCompany}
                                    placeholder="Автосервис"
                                    isSearchable={false}
                                    isDisabled={isEmpty(stateAsObj.selectedJobs)}
                                    additional={{
                                        page: 0,
                                    }}
                                />
                            </>
                        )}
                    </Box>
                    <Box>
                        <Button
                            disabled={!Number.isInteger(get(stateAsObj, 'company.id'))}
                            className={`${classes.button} ${classes.confirmButton}`}
                            onClick={goToServiceStation}>
                            Перейти
                        </Button>
                    </Box>
                </div>
            </Modal>
        </div>
    );
}

const mapStateToProps = state => ({
    mileage: get(state, 'mileage.carMileage'),
    car: state.mainPage.favoriteCar,
    jobTree: state.jobTree.full,
    brands: state.spares.brands,
    contacts: get(state, 'profile.contacts', []),
    user: state.common.user,
    totalCompanies: get(state, 'autoservices.companies.totalElements', 0),
});

const mapDispatchToProps = dispatch => ({
    getCompanies: params => dispatch(getSelectableCompanies(params)),
    getJobTree: () => dispatch(getJobTree()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForServiceModal);
