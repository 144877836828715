import {
    GET_CITIES_FAILURE,
    GET_CITIES_REQUEST,
    GET_CITIES_SUCCESS,
    GET_CONTACTS_FAILURE,
    GET_CONTACTS_REQUEST,
    GET_CONTACTS_SUCCESS,
    GET_REGIONS_FAILURE,
    GET_REGIONS_REQUEST,
    GET_REGIONS_SUCCESS,
    USER_UPDATE_CONTACT_FAILURE,
    USER_UPDATE_CONTACT_REQUEST,
    USER_UPDATE_CONTACT_SUCCESS,
    USER_ADD_CONTACT_REQUEST,
    USER_ADD_CONTACT_SUCCESS,
    USER_ADD_CONTACT_FAILURE,
    GET_COUNTRIES_FAILURE,
    GET_COUNTRIES_REQUEST,
    GET_COUNTRIES_SUCCESS,
} from '../constants/profile';

const initialState = {
    countries: [],
    regions: [],
    cities: [],
    contacts: [],
    error: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REGIONS_FAILURE:
            return { ...state, error: action.payload, isLoading: false };
        case GET_REGIONS_REQUEST:
            return { ...state, isLoading: true };
        case GET_REGIONS_SUCCESS:
            return { ...state, regions: action.payload, isLoading: false };
        case GET_CITIES_FAILURE:
            return { ...state, error: action.payload, isLoading: false };
        case GET_CITIES_REQUEST:
            return { ...state, isLoading: true };
        case GET_CITIES_SUCCESS:
            return { ...state, cities: action.payload, isLoading: false };
        case GET_CONTACTS_FAILURE:
            return { ...state, error: action.payload, isLoading: false };
        case GET_CONTACTS_REQUEST:
            return { ...state, isLoading: true };
        case GET_CONTACTS_SUCCESS:
            return { ...state, contacts: action.payload, isLoading: false };
        case USER_UPDATE_CONTACT_FAILURE:
            return { ...state, error: action.payload, isLoading: false };
        case USER_UPDATE_CONTACT_REQUEST:
            return { ...state, isLoading: true };
        case USER_UPDATE_CONTACT_SUCCESS:
            return { ...state, isLoading: false };
        case USER_ADD_CONTACT_REQUEST:
            return { ...state, isLoading: true };
        case USER_ADD_CONTACT_SUCCESS:
            return { ...state, contacts: action.payload, isLoading: false };
        case USER_ADD_CONTACT_FAILURE:
            return { ...state, error: action.payload, isLoading: false };
        case GET_COUNTRIES_REQUEST:
            return { ...state, isLoading: true };
        case GET_COUNTRIES_SUCCESS:
            return { ...state, countries: action.countries };
        case GET_COUNTRIES_FAILURE:
            return { ...state, error: action.error, isLoading: false };
        default:
            return state;
    }
};

export default reducer;
