import { Box, Dialog, DialogTitle, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUserVehicles } from '../../actions/garage';
import { getUserInfo, login, logout } from '../../actions/authFlow';
import ModalContent from './modal-content/ModalContent';
import useStyles from './styles';

function AuthModal(props) {
    const {
        completeRegistration = false,
        hasRedirect = true,
        redirectUrl = '/',
        showModal = false,
        handleShowModal,
        history,
        cookies,
        successCallback,
        successFunction,
    } = props;
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [isLoading, setLoading] = useState(false);

    function handleOpenModal() {
        handleShowModal(true);
    }

    function handleCloseModal() {
        handleShowModal(false);
    }

    React.useEffect(() => {
        if (completeRegistration) {
            handleOpenModal();
        }
    }, [completeRegistration]);

    const handleClickEnter = async (username, password) => {
        let secondsRemaining = 0;
        try {
            setLoading(true);
            const { token, secondsRemain } = await props.login({ username, password }, cookies);
            secondsRemaining = secondsRemain;
            if (token) {
                const user = await props.getUserInfo(token);
                successCallback && (await successCallback(user));
                if (successFunction) successFunction();
                if (hasRedirect && redirectUrl) history.replace(redirectUrl);
                handleCloseModal();
                return {
                    isAuth: true,
                    secondsRemain: 0,
                };
            }
        } catch (error) {
            handleCloseModal();
        } finally {
            setLoading(false);
        }
        return {
            isAuth: false,
            secondsRemaining,
        };
    };

    return (
        <Dialog
            className={classes.dialog}
            onClose={!isLoading ? handleCloseModal : null}
            open={showModal}
            maxWidth="xs"
            fullWidth
            fullScreen={isMobile}>
            <Box padding={5} textAlign="center">
                <DialogTitle>
                    <Typography className={classes.title} variant={isMobile ? 'h5' : 'h4'} noWrap>
                        Представьтесь
                    </Typography>
                    {!isLoading && (
                        <Box position="absolute" top={0} right={0} mt={1} mr={1}>
                            <IconButton onClick={handleCloseModal}>
                                <CloseIcon fontSize="default" />
                            </IconButton>
                        </Box>
                    )}
                </DialogTitle>
                <ModalContent handleClickEnter={handleClickEnter} onClose={handleCloseModal} />
            </Box>
        </Dialog>
    );
}
const mapStateToProps = state => {
    return {
        user: state.common.user,
        completeRegistration: state.common.completeRegistration,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: (user, cookies) => dispatch(login(user, cookies)),
        logout: () => dispatch(logout()),
        getUserInfo: key => dispatch(getUserInfo(key)),
        getUserVehicles: user => dispatch(getUserVehicles(user)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthModal));
