import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        top: `50%`,
        left: `50%`,
        height: 500,
        transform: 'translate(-50%, -50%)',
        width: 500,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 15,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0, 4, 3),
        '@media(max-width:480px)': {
            width: 350,
            padding: theme.spacing(0, 2, 3),
        },
        '@media(max-width:400px)': {
            width: 300,
            padding: theme.spacing(0, 1, 3),
        },
        '& h2': {
            textAlign: 'center',
            color: 'rgb(77, 77, 77)',
        },
        '& p': {
            fontSize: 17,
            fontFamily: 'Roboto-Medium',
            textAlign: 'center',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    closeIcon: {
        cursor: 'pointer',
        top: 0,
        color: '#00afc1',
        right: 0,
        height: 40,
        margin: 10,
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            color: '#0e444d',
        },
    },
    buttonGroup: {
        display: 'flex',
    },
    button: {
        width: '100%',
        height: 45,
        color: '#fff',
        textAlign: 'center',
        '@media(max-width:460px)': {
            fontSize: 12,
        },
    },
    confirmButton: {
        marginLeft: 5,
        backgroundColor: '#42c24f',
        '&:hover': {
            backgroundColor: 'green',
        },
    },
    cancelButton: {
        marginRight: 5,
        backgroundColor: '#ff6666',
        '&:hover': {
            backgroundColor: '#ff3232',
        },
    },
    spinner: {
        color: '#0e444d',
        alignSelf: 'center',
        marginTop: 20,
    },
    waitingText: {
        marginTop: 40,
    },
}));

export default useStyles;
