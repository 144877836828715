import { Tabs, Tab } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { DropdownMenu } from '../Dropdown';
import { LinkTab } from '../LinkTab';
import { CustomText } from '../Text';
import { REVIEWS_DROPDOWN } from '../../constants/dropdown';
import TEXT from '../../constants/text';
import { getTabValue } from '../../utils/tab';
import useStyles from './styles';

export const NavBar = ({ isAuth }) => {
  const [tabValue, setTabValue] = useState(null);
  const changeTab = (event, value) => setTabValue(value);
  const location = useLocation();

  const classes = useStyles();

  useEffect(() => {
    setTabValue(getTabValue(location.pathname, isAuth).value);
  }, [location, isAuth]);

  return (
    <Tabs
      variant="fullWidth"
      onChange={changeTab}
      value={tabValue}
      textColor="primary"
      classes={{ flexContainer: classes.tabs, indicator: classes.indicator }}
    >
      <LinkTab
        label={<CustomText className={classes.text} text={TEXT.main} />}
        tabstyle={classes.tab}
        to="/"
      />
      {isAuth && (
        <LinkTab
          label={<CustomText className={classes.text} text={TEXT.myGarage} />}
          tabstyle={classes.tab}
          to="/garage"
        />
      )}
      <Tab
        disableFocusRipple
        className={classes.tab}
        classes={{
          root: classes.reviewTab,
        }}
        label={
          <DropdownMenu
            title={TEXT.reviews}
            buttonLink={REVIEWS_DROPDOWN[0].linkTo}
            options={REVIEWS_DROPDOWN}
            dropdownStyle={classes.reviewsDropdown}
            titleStyle={classes.text}
            textStyle={classes.dropdownItemText}
            buttonStyle={classes.reviewsDropdownButton}
          />
        }
      />
      <LinkTab
        label={<CustomText className={classes.text} text={TEXT.carServices} />}
        tabstyle={classes.tab}
        to="/services"
      />
      <LinkTab
        label={<CustomText className={classes.text} text={TEXT.promotions} />}
        tabstyle={classes.tab}
        to="/promotions"
      />
    </Tabs>
  );
};
