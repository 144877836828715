import { Box, useMediaQuery } from '@material-ui/core';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUserInfo as fetchUserInfo, login as fetchLogin, logout as fetchLogout } from '../../actions/authFlow';
import { getUserVehicles } from '../../actions/garage';
import { BottomNavbar } from './bottomNavigation';
import './Header.css';
import MenuHeader from './menu/Menu';
import SignInUp from './sign-in-up/SignInUp';
import useStyles from './styles';
import { UnauthHeader } from './UnauthHeader';

const Header = props => {
    const { cookies, isAuth, login, logout, error, user, completeRegistration, getUserInfo } = props;
    const { pathname } = useLocation();
    const classes = useStyles({ isUnAuthMainPage: !isAuth && (pathname === '/' || pathname === '/b2b') });
    const isMobile = useMediaQuery('(max-width:600px)');
    const headerRef = useRef(null);

    if (!isAuth && (pathname === '/' || pathname === '/b2b') && !isMobile) {
        return (
            <div className={classes.unauthHeader} ref={headerRef}>
                <UnauthHeader
                    isAuth={isAuth}
                    login={login}
                    logout={logout}
                    error={error}
                    cookies={cookies}
                    successCallback={props.getUserVehicles}
                    user={user}
                    getUserInfo={getUserInfo}
                    completeRegistration={completeRegistration}
                />
            </div>
        );
    }

    if (isMobile) {
        return (
            <>
                <div className={classes.unauthHeader} ref={headerRef}>
                    <UnauthHeader
                        isAuth={isAuth}
                        login={login}
                        logout={logout}
                        error={error}
                        cookies={cookies}
                        successCallback={props.getUserVehicles}
                        user={user}
                        getUserInfo={getUserInfo}
                        completeRegistration={completeRegistration}
                    />
                </div>
                <BottomNavbar user={user} isAuth={isAuth} />
            </>
        );
    }

    return (
        <>
            <div ref={headerRef}>
                <Box className={classes.container}>
                    <SignInUp
                        isAuth={isAuth}
                        login={login}
                        logout={logout}
                        error={error}
                        cookies={cookies}
                        successCallback={props.getUserVehicles}
                        user={user}
                        getUserInfo={getUserInfo}
                        completeRegistration={completeRegistration}
                    />
                </Box>
                <MenuHeader isAuth={isAuth} history={props.history} />
            </div>
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        isAuth: state.common.isAuth,
        error: state.common.errorResponse,
        cookies: ownProps.cookies,
        user: state.common.user,
        completeRegistration: state.common.completeRegistration,
        history: state.router,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: (user, cookies) => dispatch(fetchLogin(user, cookies)),
        logout: () => dispatch(fetchLogout()),
        getUserInfo: key => dispatch(fetchUserInfo(key)),
        getUserVehicles: user => dispatch(getUserVehicles(user)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
