import React from 'react';

export function ServicesIcon({ width = 31, height = 31, color = '#3AAAB9' }) {
    return (
        <svg width={width} height={height} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={width} height={height} fill="white" />
            <path
                d="M49.5604 38.2275C50.7563 35.4441 51.4186 32.3773 51.4186 29.1558C51.4186 16.4532 41.1211 6.15576 28.4186 6.15576C19.7005 6.15576 12.1153 11.0062 8.21459 18.1558H32.4186L36.4186 28.1558L32.4186 38.1558H7.24609C10.7489 46.3856 18.9098 52.1558 28.4186 52.1558C30.4619 52.1558 32.443 51.8893 34.329 51.3892L38.9971 55.6891L37.8076 56.8884L32.8547 52.3648L30.1572 55.3184L32.03 57.0288L8.44024 81.7199C8.41222 81.748 8.38433 81.7764 8.35668 81.8051L8.31963 81.8437C5.61468 84.6873 5.72625 89.1844 8.56872 91.8883C11.3787 94.5612 15.8056 94.481 18.5197 91.7316L18.5239 91.7357L42.9518 67.0039L45.0121 68.8855L47.7096 65.932L42.794 61.4425L43.9703 60.27L76.8459 90.5527L76.8516 90.5466C80.8406 94.2403 87.069 94.0735 90.8505 90.1385C94.6758 86.158 94.5486 79.8289 90.5665 76.0021C90.5271 75.9643 90.4876 75.9269 90.4478 75.8899C90.4192 75.8632 90.3904 75.8367 90.3614 75.8105L58.0851 46.0799L43.9683 60.2523L39.0066 55.6907L53.128 41.5138L49.5604 38.2275Z"
                fill={color}
            />
            <path
                d="M88.2075 6.29706L92.9788 11.0497L82.6352 27.3389H76.7535L58.0919 46.0801L53.1312 41.5122L71.9822 22.5853L71.982 17.1496L88.2075 6.29706Z"
                fill={color}
            />
        </svg>
    );
}
