export const SET_MILEAGE = 'SET_MILEAGE';

export const GET_FAVORITE_CAR_REQUEST = '@mainPage//GET_FAVORITE_CAR_REQUEST';
export const GET_FAVORITE_CAR_SUCCESS = '@mainPage//GET_FAVORITE_CAR_SUCCESS';
export const GET_FAVORITE_CAR_FAILURE = '@mainPage//GET_FAVORITE_CAR_FAILURE';

export const UPDATE_FAVORITE_CAR_REQUEST = '@mainPage//UPDATE_FAVORITE_CAR_REQUEST';
export const UPDATE_FAVORITE_CAR_SUCCESS = '@mainPage//UPDATE_FAVORITE_CAR_SUCCESS';
export const UPDATE_FAVORITE_CAR_FAILURE = '@mainPage//UPDATE_FAVORITE_CAR_FAILURE';

export const GET_AUTO_FIELDS_REQUEST = '@mainPage//GET_AUTO_FIELDS_REQUEST';
export const GET_AUTO_FIELDS_SUCCESS = '@mainPage//GET_AUTO_FIELDS_SUCCESS';
export const GET_AUTO_FIELDS_FAILURE = '@mainPage//GET_AUTO_FIELDS_FAILURE';

export const GET_FAVORITE_AUTO_PAYMENTS_REQUEST = '@mainPage//GET_FAVORITE_AUTO_PAYMENTS_REQUEST';
export const GET_FAVORITE_AUTO_PAYMENTS_SUCCESS = '@mainPage//GET_FAVORITE_AUTO_PAYMENTS_SUCCESS';
export const GET_FAVORITE_AUTO_PAYMENTS_FAILURE = '@mainPage//GET_FAVORITE_AUTO_PAYMENTS_FAILURE';

export const GET_FAVORITE_REFUELING_PAYMENTS_REQUEST = '@mainPage//GET_FAVORITE_REFUELING_PAYMENTS_REQUEST';
export const GET_FAVORITE_REFUELING_PAYMENTS_SUCCESS = '@mainPage//GET_FAVORITE_REFUELING_PAYMENTS_SUCCESS';
export const GET_FAVORITE_REFUELING_PAYMENTS_FAILURE = '@mainPage//GET_FAVORITE_REFUELING_PAYMENTS_FAILURE';

export const GET_AUTO_CATEGORY_SPARES_REQUEST = '@mainPage//GET_AUTO_CATEGORY_SPARES_REQUEST';
export const GET_AUTO_CATEGORY_SPARES_SUCCESS = '@mainPage//GET_AUTO_CATEGORY_SPARES_SUCCESS';
export const GET_AUTO_CATEGORY_SPARES_FAILURE = '@mainPage//GET_AUTO_CATEGORY_SPARES_FAILURE';
