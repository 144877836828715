import { NotificationManager } from 'react-notifications';
import { api } from '../api';
import {
    ADD_AUTO_ATTACHMENT_FAILURE,
    ADD_AUTO_ATTACHMENT_REQUEST,
    ADD_AUTO_ATTACHMENT_SUCCESS,
    CHANGE_FAVORITE_CAR,
    GET_AUTO_ATTACHMENT_FAILURE,
    GET_AUTO_ATTACHMENT_REQUEST,
    GET_AUTO_ATTACHMENT_SUCCESS,
    GET_USERS_VEHICLES,
    DELETE_AUTO_ATTACHMENT_FAILURE,
    DELETE_AUTO_ATTACHMENT_REQUEST,
    DELETE_AUTO_ATTACHMENT_SUCCESS,
    SELL_AUTO_FAILURE,
    SELL_AUTO_REQUEST,
    SELL_AUTO_SUCCESS,
} from '../constants/garage';
import { javaRootPrivate } from '../constants/urls';

export const getUserVehicles = (user, page = 0, size = 1) => dispatch => {
    return api
        .get(`${javaRootPrivate}/users/${user.id}/autos`, {
            params: { size, page },
        })
        .then(({ data }) => {
            dispatch({
                type: GET_USERS_VEHICLES,
                payload: data,
            });
            return data;
        })
        .catch(() => {
            NotificationManager.error('Ошибка получения автомобиля');
        });
};

export const getAllUserVehicles = (user, page = 0, size = 20) => dispatch => {
    api.get(`${javaRootPrivate}/users/${user.id}/autos`, {
        params: { size, page },
    })
        .then(response => {
            dispatch({
                type: GET_USERS_VEHICLES,
                payload: response.data,
            });
        })
        .catch(() => {
            NotificationManager.error('Ошибка получения автомобиля');
        });
};

export const changeFavoriteCar = favoriteCar => dispatch => {
    return Promise.resolve(
        dispatch({
            type: CHANGE_FAVORITE_CAR,
            payload: favoriteCar,
        }),
    );
};

export const getAutoAttachment = (id, page = 0, type = 'PHOTO') => dispatch => {
    dispatch({
        type: GET_AUTO_ATTACHMENT_REQUEST,
    });
    return api
        .get(`${javaRootPrivate}/autos/${id}/attachments`, {
            params: { page, size: 20, type },
        })
        .then(({ data }) => {
            dispatch({
                type: GET_AUTO_ATTACHMENT_SUCCESS,
                payload: data,
            });
            return data;
        })
        .catch(error => {
            dispatch({
                type: GET_AUTO_ATTACHMENT_FAILURE,
                payload: error,
            });
        });
};

export const addAutoAttachment = ({ autoId, base64, categoryId = null, type = 'GALLERY_PHOTO' }) => dispatch => {
    dispatch({
        type: ADD_AUTO_ATTACHMENT_REQUEST,
    });
    return api
        .post(`${javaRootPrivate}/autos/attachments`, {
            autoId,
            base64,
            categoryId,
            type,
        })
        .then(({ data }) => {
            dispatch({
                type: ADD_AUTO_ATTACHMENT_SUCCESS,
                payload: data,
            });
            return data;
        })
        .catch(error => {
            dispatch({
                type: ADD_AUTO_ATTACHMENT_FAILURE,
                payload: error,
            });
            return Promise.reject(error);
        });
};

export const deleteAutoAttachment = (autoId, attachmentId) => dispatch => {
    dispatch({
        type: DELETE_AUTO_ATTACHMENT_REQUEST,
    });
    return api
        .delete(`${javaRootPrivate}/autos/${autoId}/attachments/${attachmentId}`)
        .then(({ data }) => {
            dispatch({
                type: DELETE_AUTO_ATTACHMENT_SUCCESS,
                payload: data,
            });
            return data;
        })
        .catch(error => {
            dispatch({
                type: DELETE_AUTO_ATTACHMENT_FAILURE,
                payload: error,
            });
            return Promise.reject(error);
        });
};

export const sellAuto = (autoId, clientNumber) => dispatch => {
    dispatch({
        type: SELL_AUTO_REQUEST,
    });
    return api
        .post(`${javaRootPrivate}/autos/assignments/purchases`, { autoId, clientNumber })
        .then(({ data }) => {
            dispatch({
                type: SELL_AUTO_SUCCESS,
                payload: data,
            });
            return data;
        })
        .catch(error => {
            dispatch({
                type: SELL_AUTO_FAILURE,
                payload: error,
            });
            return Promise.reject(error);
        });
};

export const deleteAuto = (assignmentId, user) => dispatch => {
    return api
        .delete(`${javaRootPrivate}/autos/assignments`, { data: { assignmentId } })
        .then(() => {
            dispatch(getUserVehicles(user));
        })
        .catch(error => {
            return Promise.reject(error);
        });
};
