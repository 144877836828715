import React, { useState } from 'react';
import { useDeepCompareEffect, useInterval } from 'react-use';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import BackgroundSlider from 'react-background-slider';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { javaResources } from 'constants/urls';
import './styles.scss';

const BACKGROUND_COLORS = ['#00b1c314', '#00404a17', '#24459f14'];

export default function GlobalSlider({ isAuth, servicePhotos, garagePhotos }) {
    const { pathname } = useLocation();

    const [images, setImages] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [hasImages, setHasImages] = useState(false);

    function nextSlide() {
        if (activeIndex === 2) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index => index + 1);
        }
    }
    useInterval(nextSlide, 10000);

    useDeepCompareEffect(() => {
        if (window.location.href.includes('service/station') && !isEmpty(servicePhotos.content)) {
            setHasImages(true);
            setImages(servicePhotos.content.map(item => `${javaResources}${item.url}`));
        } else if (window.location.href.includes('service/station') && isEmpty(servicePhotos.content)) {
            setHasImages(false);
            setImages([]);
        } else {
            if (isAuth) {
                if (!isEmpty(garagePhotos)) {
                    setHasImages(true);
                    setImages(garagePhotos.map(item => `${javaResources}${item.url}`));
                } else {
                    setHasImages(false);
                    setImages([]);
                }
            } else {
                setHasImages(false);
                setImages([]);
            }
        }
    }, [pathname, servicePhotos, garagePhotos, isAuth]);

    if ((pathname === '/' || pathname === '/b2b') && !isAuth) return <></>;

    function ColorSlider() {
        return (
            <div
                className="full-screen-slider"
                style={{
                    backgroundColor: BACKGROUND_COLORS[activeIndex],
                }}
            />
        );
    }

    return (
        <>
            <Helmet>
                <html lang="ru" style="height: 100%" />
                <body style="height: 100%" />
            </Helmet>

            {hasImages ? (
                <BackgroundSlider key={images} images={images} duration={10} transition={2} />
            ) : (
                <TransitionGroup>
                    <CSSTransition key={activeIndex} classNames="alert" timeout={{ enter: 2000, exit: 2000 }}>
                        <div className="full-screen-slider color-slider">
                            <ColorSlider />
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            )}
        </>
    );
}
