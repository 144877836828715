import { createMergedObject } from '../../utils/object';

export function init(initialValues) {
    return initialState => {
        try {
            const newState = createMergedObject(initialState, initialValues);
            return newState;
        } catch (error) {
            return initialState;
        }
    };
}

export const initialState = {
    companies: [],
    jobs: [],
    selectedJobs: [],
    requestedJobs: [],
    company: null,
    filteredParams: null,
    expandedKeys: [],
};

export function reducer(state, action) {
    switch (action.type) {
        case 'initial': {
            return { ...initialState };
        }
        case 'companies': {
            return {
                ...state,
                companies: [...state.companies, ...action.payload.companies],
                requestedJobs: action.payload.requestedJobs,
            };
        }
        case 'expandedKeys': {
            return {
                ...state,
                expandedKeys: action.payload,
            };
        }
        case 'selectedJobs': {
            return {
                ...state,
                selectedJobs: action.payload,
                companies: [],
                company: null,
            };
        }
        case 'isDropdownVisible': {
            return {
                ...state,
                isDropdownVisible: action.payload,
            };
        }
        default:
            return {
                ...state,
                [action.type]: action.payload,
            };
    }
}
