import {
    ADD_EVENT_WORK_REQUEST,
    ADD_EVENT_WORK_SUCCESS,
    ADD_EVENT_WORK_FAILURE,
    GER_ADDITIONAL_FIELDS_REQUEST,
    GER_ADDITIONAL_FIELDS_SUCCESS,
    GER_ADDITIONAL_FIELDS_FAILURE,
} from '../constants/works';

const initialState = { event: [], isLoading: false, fields: [] };

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_EVENT_WORK_REQUEST:
        case GER_ADDITIONAL_FIELDS_REQUEST:
            return { ...state, isLoading: true };
        case ADD_EVENT_WORK_FAILURE:
        case GER_ADDITIONAL_FIELDS_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
        case ADD_EVENT_WORK_SUCCESS:
            return { ...state, event: action.payload, isLoading: false };
        case GER_ADDITIONAL_FIELDS_SUCCESS:
            return { ...state, fields: action.payload, isLoading: false };
        default:
            return state;
    }
};

export default reducer;
