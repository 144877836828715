import { api } from '../api';
import { javaRoot } from '../constants/urls';
import {
    GET_JOB_HIERARCHY_COMPANY_FAILURE,
    GET_JOB_HIERARCHY_COMPANY_REQUEST,
    GET_JOB_HIERARCHY_COMPANY_SUCCESS,
    GET_JOB_HIERARCHY_FAILURE,
    GET_JOB_HIERARCHY_REQUEST,
    GET_JOB_HIERARCHY_SUCCESS,
    GET_ROOT_CATEGORIES_REQUEST,
    GET_ROOT_CATEGORIES_FAILURE,
    GET_ROOT_CATEGORIES_SUCCESS,
} from '../constants/jobTree';

export const getJobTree = () => dispatch => {
    dispatch({ type: GET_JOB_HIERARCHY_REQUEST });
    return api
        .get(`${javaRoot}/tree`)
        .then(({ data }) => {
            dispatch({ type: GET_JOB_HIERARCHY_SUCCESS, payload: data });
            return data;
        })
        .catch(error => {
            dispatch({ type: GET_JOB_HIERARCHY_FAILURE, error });
        });
};

export const getJobCompanyTree = orgId => dispatch => {
    dispatch({ type: GET_JOB_HIERARCHY_COMPANY_REQUEST });
    api.get(`${javaRoot}/tree`, {
        orgId,
    })
        .then(({ data }) => {
            dispatch({ type: GET_JOB_HIERARCHY_COMPANY_SUCCESS, payload: data });
        })
        .catch(error => {
            dispatch({ type: GET_JOB_HIERARCHY_COMPANY_FAILURE, error });
        });
};

export const getRootCategories = () => dispatch => {
    dispatch({
        type: GET_ROOT_CATEGORIES_REQUEST,
    });
    return api
        .get(`${javaRoot}/tree`)
        .then(({ data }) => {
            dispatch({
                type: GET_ROOT_CATEGORIES_SUCCESS,
                payload: data,
            });
            return data;
        })
        .catch(error => {
            dispatch({
                type: GET_ROOT_CATEGORIES_FAILURE,
                error,
            });
        });
};
