import { createSelector } from 'reselect';
import { get } from 'lodash';

export * from './addCar';
export * from './mainPage';
export * from './journal';
export * from './mileage';
export * from './eventHistory';

export const favoriteCarSelector = createSelector(
    state => state.mainPage,
    mainPage => mainPage.favoriteCar,
);

export const userSelector = createSelector(
    state => state.common,
    common => common.user,
);

export const userWithCarSelector = createSelector(favoriteCarSelector, userSelector, (favoriteCar, user) => ({
    favoriteCar,
    user,
}));

export const serviceStationInfoSelector = createSelector(
    state => state.serviceStation,
    serviceStation => {
        return {
            jobs: serviceStation.jobs,
            comfortServices: serviceStation.comfortServices,
            company: serviceStation.company,
            reviews: get(serviceStation, 'reviews.content', []),
        };
    },
);
