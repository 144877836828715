import React from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import ProfileDropdown from '../../../../components/ProfileDropdown';
import { javaResources } from '../../../../constants/urls';
import useStyles from './styles';

const AccountContent = props => {
    const { user } = props;
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            {/* Icon className={`${classes.iconStyle} fas fa-comment`} /> */}
            <ProfileDropdown
                clientNumber={get(user, 'clientNumber', '')}
                avatar={get(user, 'avatar') ? `${javaResources}${user.avatar}` : null}
                iconStyle={classes.iconStyle}
                logout={props.logout}
            />
            {/* <Icon className={`${classes.iconStyle} fas fa-envelope`} /> */}
        </Box>
    );
};

const mapStateToProps = ({ common }) => {
    return {
        user: common.user,
    };
};

export default connect(mapStateToProps)(withRouter(AccountContent));
