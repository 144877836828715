import {
    REGISTRATION_REQUEST,
    REGISTRATION_FAILURE,
    REGISTRATION_SUCCESS,
    GET_SIGNUP_INFO,
    LOGIN_REQUEST,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    CLOSE_MODAL_AFTER_REGISTRATION,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_FAILURE,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_FAILURE,
    SEND_EMAIL_REQUEST,
    REGISTRATION_CLEAR,
    COMPLETE_REGISTRATION,
    COMPLETE_REGISTRATION_FAILURE,
    GET_USER_INFO_SUCCESS,
    RESTORE_TOKEN_SUCCESS,
    RESTORE_TOKEN_FAILURE,
    RESTORE_TOKEN_REQUEST,
    GET_USER_LAW_INFO_FAILURE,
    GET_USER_LAW_INFO_SUCCESS,
    GET_USER_LAW_INFO_REQUEST,
    UPDATE_USER_LAW_INFO_FAILURE,
    UPDATE_USER_LAW_INFO_SUCCESS,
    UPDATE_USER_LAW_INFO_REQUEST,
} from '../constants/authFlow';
import {
    USER_UPDATE_INFO_SUCCESS,
    USER_UPDATE_INFO_FAILURE,
    USER_UPDATE_INFO_REQUEST,
    USER_UPDATE_AVATAR_REQUEST,
    USER_UPDATE_AVATAR_SUCCESS,
    USER_UPDATE_AVATAR_FAILURE,
} from '../constants/profile';

const initialState = {
    user: {},
    isAuth: false,
    errorResponse: undefined,
    completedRegistration: false,
    sendSuccessEmail: false,
    isChangedPassword: false,
    registered: false,
    completedRegistrationError: false,
    favoriteCar: 0,
    isTokenRefreshing: false,
    isRefreshingSuccess: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTRATION_REQUEST:
        case USER_UPDATE_AVATAR_REQUEST:
            return { ...state, isLoading: true };
        case REGISTRATION_FAILURE:
            return { ...state, isLoading: false, errorResponse: action.payload };
        case REGISTRATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                registered: true,
                errorResponse: undefined,
            };
        case REGISTRATION_CLEAR:
            return {
                ...state,
                registered: false,
            };
        case GET_SIGNUP_INFO:
            return {
                ...state,
                signupInfo: action.payload,
            };
        case COMPLETE_REGISTRATION:
            return {
                ...state,
                completedRegistration: true,
                completedRegistrationError: false,
            };
        case COMPLETE_REGISTRATION_FAILURE:
            return {
                ...state,
                completedRegistration: false,
                completedRegistrationError: true,
            };
        case LOGIN_REQUEST:
            return { ...state, isLoading: true };
        case LOGIN_FAILURE:
            return { ...state, isLoading: false, errorResponse: action.payload };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: {
                    clientNumber: action.payload.number,
                    id: action.payload.userID,
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                    email: action.payload?.email,
                },
                token: action.payload?.accessToken || action.payload?.data?.accessToken,
                // authKey: action.payload.authKey,
                isAuth: true,
                errorResponse: undefined,
            };
        case CLOSE_MODAL_AFTER_REGISTRATION:
            return {
                ...state,
                completeRegistration: false,
            };
        case SEND_EMAIL_REQUEST:
            return {
                ...state,
                sendSuccessEmail: false,
            };
        case SEND_EMAIL_SUCCESS:
            return {
                ...state,
                sendSuccessEmail: true,
            };
        case SEND_EMAIL_FAILURE:
            return {
                ...state,
                sendSuccessEmail: false,
            };
        case CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                isChangedPassword: true,
            };
        case CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                isChangedPassword: false,
            };
        case GET_USER_INFO_SUCCESS:
            return {
                ...state,
                user: action.payload,
            };
        case USER_UPDATE_AVATAR_SUCCESS:
            return {
                ...state,
                user: { ...state.user, avatar: action.payload },
                isLoading: false,
            };
        case USER_UPDATE_INFO_REQUEST:
        case GET_USER_LAW_INFO_REQUEST:
        case UPDATE_USER_LAW_INFO_REQUEST:
            return { ...state, isLoading: false };
        case USER_UPDATE_INFO_SUCCESS:
            return { ...state, user: action.payload, isLoading: false };
        case USER_UPDATE_INFO_FAILURE:
        case USER_UPDATE_AVATAR_FAILURE:
        case GET_USER_LAW_INFO_FAILURE:
        case UPDATE_USER_LAW_INFO_FAILURE:
            return { ...state, error: action.payload, isLoading: false };
        case RESTORE_TOKEN_REQUEST:
            return { ...state, isTokenRefreshing: true, isRefreshingSuccess: false };
        case RESTORE_TOKEN_SUCCESS:
            return { ...state, isTokenRefreshing: false, isRefreshingSuccess: true };
        case RESTORE_TOKEN_FAILURE:
            return { ...state, isTokenRefreshing: false, isRefreshingSuccess: false };
        case GET_USER_LAW_INFO_SUCCESS:
        case UPDATE_USER_LAW_INFO_SUCCESS:
            return { ...state, lawInfo: action.payload };
        default:
            return state;
    }
};

export default reducer;
