export const GET_AUTO_WORKS_REQUEST = '@journal//GET_AUTO_WORKS_REQUEST';
export const GET_AUTO_WORKS_SUCCESS = '@journal//GET_AUTO_WORKS_SUCCESS';
export const GET_AUTO_WORKS_FAILURE = '@journal//GET_AUTO_WORKS_FAILURE';

export const GET_AUTO_EVENTS_REQUEST = '@journal//GET_AUTO_EVENTS_REQUEST';
export const GET_AUTO_EVENTS_SUCCESS = '@journal//GET_AUTO_EVENTS_SUCCESS';
export const GET_AUTO_EVENTS_FAILURE = '@journal//GET_AUTO_EVENTS_FAILURE';

export const GET_SPECIFIC_EVENT_REQUEST = '@journal//GET_SPECIFIC_EVENT_REQUEST';
export const GET_SPECIFIC_EVENT_SUCCESS = '@journal//GET_SPECIFIC_EVENT_SUCCESS';
export const GET_SPECIFIC_EVENT_FAILURE = '@journal//GET_SPECIFIC_EVENT_FAILURE';

export const GET_EVENT_MILEAGE_REQUEST = '@journal//GET_EVENT_MILEAGE_REQUEST';
export const GET_EVENT_MILEAGE_SUCCESS = '@journal//GET_EVENT_MILEAGE_SUCCESS';
export const GET_EVENT_MILEAGE_FAILURE = '@journal//GET_EVENT_MILEAGE_FAILURE';

export const GET_SPECIFIC_EVENT_WORKS_REQUEST = '@journal//GET_SPECIFIC_EVENT_WORKS_REQUEST';
export const GET_SPECIFIC_EVENT_WORKS_SUCCESS = '@journal//GET_SPECIFIC_EVENT_WORKS_SUCCESS';
export const GET_SPECIFIC_EVENT_WORKS_FAILURE = '@journal//GET_SPECIFIC_EVENT_WORKS_FAILURE';

export const GET_SPECIFIC_EVENT_SPARES_REQUEST = '@journal//GET_SPECIFIC_EVENT_SPARES_REQUEST';
export const GET_SPECIFIC_EVENT_SPARES_SUCCESS = '@journal//GET_SPECIFIC_EVENT_SPARES_SUCCESS';
export const GET_SPECIFIC_EVENT_SPARES_FAILURE = '@journal//GET_SPECIFIC_EVENT_SPARES_FAILURE';

export const GET_SPECIFIC_EVENT_WRITTEN_SPARES_REQUEST = '@journal//GET_SPECIFIC_EVENT_WRITTEN_SPARES_REQUEST';
export const GET_SPECIFIC_EVENT_WRITTEN_SPARES_SUCCESS = '@journal//GET_SPECIFIC_EVENT_WRITTEN_SPARES_SUCCESS';
export const GET_SPECIFIC_EVENT_WRITTEN_SPARES_FAILURE = '@journal//GET_SPECIFIC_EVENT_WRITTEN_SPARES_FAILURE';

export const GET_EVENT_WORK_FIELDS_REQUEST = '@journal//GET_EVENT_WORK_FIELDS_REQUEST';
export const GET_EVENT_WORK_FIELDS_SUCCESS = '@journal//GET_EVENT_WORK_FIELDS_SUCCESS';
export const GET_EVENT_WORK_FIELDS_FAILURE = '@journal//GET_EVENT_WORK_FIELDS_FAILURE';
