import React from 'react';
import { CircularProgress, CardMedia, Box } from '@material-ui/core';
import logo from '../../assets/logo.png';

export default (
    <div
        style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            backgroundColor: 'white',
            flex: 4,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <CardMedia component="img" src={logo} alt="logo" style={{ height: 55, width: 'fit-content' }} />
            <CircularProgress size={70} style={{ color: '#00606d' }} />
        </Box>
    </div>
);
