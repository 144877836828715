import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    unauthHeader: {
        height: 76,
        '@media(max-width:920px)': {
            height: ({ isUnAuthMainPage }) => (isUnAuthMainPage ? 100 : 54),
        },
    },
    container: {
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        zIndex: 2000,
    },
});

export default useStyles;
