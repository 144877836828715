import rename from 'deep-rename-keys';
import { isEmpty, includes } from 'lodash';

function updateTreeData(list, key, children, parentTitle, childrenCount = 0) {
    return list.map(node => {
        if (node.children && childrenCount === 0) {
            return {
                ...node,
                isLeaf: isEmpty(node.children),
                children: updateTreeData(node.children, key, children, node.title, childrenCount + 1),
            };
        }
        return {
            ...node,
            title: includes(node.title, parentTitle) ? node.title : `${parentTitle}: ${node.title}`,
            isLeaf: true,
        };
    });
}

function updateTreeDataV2(list, key, children) {
    return (list ?? []).map(node => {
        return {
            ...node,
            isLeaf: isEmpty(node.children),
            children: updateTreeDataV2(node.children, key, children),
        };
    });
}

function searchTree(element, value) {
    if (element.value === value) {
        return element;
    } else if (element.children != null) {
        let i;
        let result = null;
        for (i = 0; result == null && i < element.children.length; i += 1) {
            result = searchTree(element.children[i], value);
        }
        return result;
    }
    return null;
}

function searchTreeParent(element, value, parent = {}) {
    let parentNode = parent;
    if (element.parent === 440) {
        parentNode = element;
    }

    if (element.value === value) {
        return parentNode;
    } else if (element.children != null) {
        let i;
        let result = null;
        for (i = 0; result == null && i < element.children.length; i += 1) {
            result = searchTreeParent(element.children[i], value, parentNode);
        }
        return result;
    }
    return null;
}

export function treeDeepFind(list, value) {
    for (let i = 0; i < list.length; i += 1) {
        const result = searchTreeParent(list[i], Number(value));
        if (result) return result;
    }
    return null;
}

export function treeFind(list, value) {
    for (let i = 0; i < list.length; i += 1) {
        const result = searchTree(list[i], value);
        if (result) return result;
    }
    return null;
}

function mapTree(data, withPreviousId = true) {
    return rename(data, key => {
        if (key === 'name') {
            return 'title';
        } else if (key === 'child') {
            return 'children';
        } else if (key === 'id') {
            return 'value';
        } else if (key === 'previous_id' && withPreviousId) {
            return 'value';
        } else if (key === 'normHour') {
            return 'norm_hour';
        } else if (key === 'parentId') {
            return 'parent';
        } else {
            return key;
        }
    });
}

export { updateTreeData, mapTree, updateTreeDataV2 };
