import { useMediaQuery } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useDeepCompareEffect } from 'react-use';

function vh(v) {
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (v * h) / 100;
}

export default function ScrollToTop({ isAuth, garagePhotos }) {
    const isDesktop = useMediaQuery('(min-width:600px)');
    const { pathname } = useLocation();

    useDeepCompareEffect(() => {
        if (pathname === '/service/journal' || pathname === '/service/journal/repair' || pathname === '/car/info') {
            const pixels = isDesktop ? vh(10) : 0;
            document.body.scrollTop = pixels;
            window.scrollTo({
                top: pixels,
                left: 0,
                behavior: 'auto',
            });
        } else if (pathname === '/' && isAuth) {
            const pixels = isDesktop && !isEmpty(garagePhotos) ? vh(10) : 0;
            document.body.scrollTop = pixels;
            window.scrollTo({
                top: pixels,
                left: 0,
                behavior: 'auto',
            });
        } else {
            document.body.scrollTop = 0;
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'auto',
            });
        }
    }, [pathname, garagePhotos]);

    return null;
}
