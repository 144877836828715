export const OTP_TYPES = {
    EMAIL: 'EMAIL',
    PHONE: 'PHONE',
    GOOGLE: 'GOOGLE',
    APPLE: 'APPLE',
};

export const OTP_TYPES_KEYS = {
    EMAIL: 'email',
    PHONE: 'phone',
    APPLE: 'apple',
    GOOGLE: 'google',
};
