import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 5000,
    },
    iconStyle: {
        fontSize: '2rem',
        color: 'rgb(0, 96, 109)',
        cursor: 'pointer',
        lineHeight: '35px',
        '&:hover': {
            color: '#0dabc2',
        },
    },
});

export default useStyles;
