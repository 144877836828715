import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Rating } from '@material-ui/lab';

const useStyles = makeStyles({
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& > span': {
            whiteSpace: 'normal',
        },
        '& .MuiRating-root': {
            color: '#ffb400 !important',
        },
        '& .MuiRating-icon': {
            color: '#ffb400 !important',
        },
    },
});

export function CompanySelectorItem({ name, city, address, house, flat, rate = 0 }) {
    const classes = useStyles();

    return (
        <Box className={classes.item}>
            <span>{name}</span>
            <span>
                Адрес: {city}, {address} {house} {flat}
            </span>
            <Rating
                className="rating-block"
                name="half-rating"
                value={rate}
                precision={0.5}
                readOnly
                icon={<i className="fas fa-star" />}
                emptyIcon={<i className="far fa-star" />}
            />
        </Box>
    );
}
