import { makeStyles } from '@material-ui/core/styles';

export const styles = {
    logo(isLargeScreen) {
        return isLargeScreen ? { marginRight: 10 } : { width: '100%' };
    },
    footerLinks(isLargeScreen, isMediumScreen) {
        const mediumScreenStyle = isMediumScreen ? { alignItems: 'flex-start' } : { order: 2 };
        return isLargeScreen ? { alignItems: 'center' } : mediumScreenStyle;
    },
    linksInlineStyle(isLargeScreen) {
        return isLargeScreen
            ? { display: 'flex', flexWrap: 'no-wrap', justifyContent: 'center' }
            : { display: 'flex', flexWrap: 'wrap' };
    },
    socialNetworksStyle(isSmallScreen) {
        return isSmallScreen
            ? {}
            : {
                  width: '100%',
                  order: 1,
                  marginLeft: 0,
                  justifyContent: 'center',
              };
    },
};

const useStyles = makeStyles({
    container: {
        display: 'flex',
        backgroundColor: 'black',
        minHeight: 100,
        flexDirection: 'row',
        flex: 1,
        padding: ({ isMobile }) => (isMobile ? '20px 16% 70px' : '20px 16%'),
        flexWrap: 'wrap',
    },
    content: {
        display: 'flex',
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        flexBasis: 'auto',
        justifyContent: 'center',
        flexDirection: 'column',
        flexWrap: 'nowrap',
    },
    logo: {
        height: '3em',
    },
    media: {
        width: 'auto',
    },
    logoCaption: {
        color: 'white',
        width: '100%',
        fontSize: '0.8em',
        textAlign: 'center',
        '&:hover': {
            color: 'gold',
        },
    },
    footerLinks: {
        fontSize: '0.9em',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    textLink: {
        textDecoration: 'none',
        color: '#fafafa',
        whiteSpace: 'nowrap',
        padding: 5,
        paddingLeft: 0,
        height: '100%',
        '&:hover': {
            color: 'gold',
        },
    },
    privacyPolicy: {
        textDecoration: 'none',
        color: '#fafafa',
        whiteSpace: 'wrap',
        padding: 5,
        paddingLeft: 0,
        height: '100%',
        '&:hover': {
            color: 'gold',
        },
    },
    socialNetworksContainer: {
        display: 'flex',
        alignSelf: 'center',
        marginLeft: 10,
    },
    iconWrapper: {
        '& .MuiIcon-root': {
            width: '100%',
            height: '34px',
            fontSize: '2rem',
            color: '#6c6e6e',
            marginRight: 6,
            marginLeft: 6,
        },
    },
    facebook: {
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            color: '#3C5A99',
        },
    },
    youtube: {
        '&:hover': {
            color: '#FF0000',
        },
    },
    vk: {
        '&:hover': {
            color: '#4680C2',
        },
    },
    instagram: {
        '&:hover': {
            color: '#E1306C',
        },
    },
    telegram: {
        '&:hover': {
            color: '#0088cc',
        },
    },
});

export default useStyles;
