import {
    Avatar,
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Button,
    ButtonBase,
    Icon,
    SwipeableDrawer,
    Typography,
} from '@material-ui/core';
import { logout as fetchLogout } from 'actions/authFlow';
import { HomeIcon } from 'assets/icons/navigation/Home';
import { PercentIcon } from 'assets/icons/navigation/Percent';
import { FuelIcon } from 'assets/icons/navigation/Fuel';
import { ServicesIcon } from 'assets/icons/navigation/Services';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RegistrationForServiceModal from 'components/RegistrationForServiceModal';
import { javaResources } from 'constants/urls';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { colors } from 'styles/colors';
import useStyles from './styles';

export const BottomNavbar = ({ user, isAuth }) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [isOpen, setOpen] = useState(false);
    const [isOpenServiceRegistrationModal, setOpenServiceRegistrationModal] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const handleRoute = path => () => {
        setOpen(false);
        history.push(path);
    };

    const handleOpenDrawer = () => {
        setOpen(true);
    };

    const handleCloseDrawer = () => {
        setOpen(false);
    };

    const handleToggleDrawer = () => {
        setOpen(drawerStatus => !drawerStatus);
    };

    useEffect(() => {
        switch (location.pathname) {
            case '/':
                setValue(0);
                break;
            case '/services':
                setValue(1);
                break;
            case '/promotions':
                setValue(2);
                break;
            case '/works/refill':
                setValue(3);
                break;
            default:
                setValue(0);
                break;
        }
    }, [location]);

    return (
        <>
            <SwipeableDrawer
                anchor="left"
                variant="temporary"
                open={isOpen}
                classes={{
                    paper: classes.navbar,
                }}
                onClose={handleCloseDrawer}
                onOpen={handleOpenDrawer}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}>
                <Box display="flex" flexDirection="column" flex={1} pb={7} padding={3}>
                    {isAuth && (
                        <>
                            <ButtonBase className={classes.profileButton} onClick={handleRoute('/profile')}>
                                <Box display="flex" alignItems="center" mb={1}>
                                    {get(user, 'avatar') ? (
                                        <Avatar
                                            className={classes.avatar}
                                            alt="Avatar"
                                            src={`${javaResources}${user.avatar}`}
                                        />
                                    ) : (
                                        <Icon className={`${classes.avatarIcon} fas fa-user-circle account-icon`} />
                                    )}
                                    <Box display="flex" flexDirection="column" alignItems="flex-start" marginLeft={1}>
                                        <Typography className={classes.clientNumberTitle}>Карта клиента</Typography>
                                        <Typography className={classes.clientNumberText}>
                                            {get(user, 'clientNumber')}
                                        </Typography>
                                    </Box>
                                </Box>
                            </ButtonBase>
                            <Button onClick={handleRoute('/garage')} className={classes.drawerLink}>
                                Мой Гараж
                            </Button>
                        </>
                    )}
                    <Button onClick={handleRoute('/services')} className={classes.drawerLink}>
                        Автосервисы
                    </Button>
                    <Button onClick={handleRoute('/promotions')} className={classes.drawerLink}>
                        Акции
                    </Button>
                    <Button onClick={handleRoute('/feedback/cars')} className={classes.drawerLink}>
                        Отзывы об автомобилях
                    </Button>
                    <Button onClick={handleRoute('/feedback/spares')} className={classes.drawerLink}>
                        Отзывы о запчастях
                    </Button>
                    <Button
                        onClick={() => {
                            setOpen(false);
                            setOpenServiceRegistrationModal(true);
                        }}
                        className={classes.drawerLink}>
                        Записаться на обслуживание
                    </Button>

                    {isAuth && (
                        <>
                            <Button onClick={handleRoute('/service/journal')} className={classes.drawerLink}>
                                Журнал обслуживания
                            </Button>
                            <Button
                                onClick={handleRoute('/car/info?category=Топливо&tab=0')}
                                className={classes.drawerLink}>
                                Статистика топлива
                            </Button>
                            <Typography className={classes.addWorkSectionTitle}>Добавить:</Typography>
                            <Button onClick={handleRoute('/works/event')} className={classes.drawerLink}>
                                Обслуживание
                            </Button>
                            <Button onClick={handleRoute('/works/refill')} className={classes.drawerLink}>
                                Заправка
                            </Button>
                            <Box display="flex" flex={1} alignItems="flex-end" mb={2.5}>
                                <Button
                                    onClick={() => {
                                        setOpen(false);
                                        history.push('/');
                                        dispatch(fetchLogout());
                                    }}
                                    className={classes.drawerLink}>
                                    Выход
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </SwipeableDrawer>
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                bottom
                showLabels
                className={classes.bottomNavigation}>
                <BottomNavigationAction
                    onClick={handleRoute('/')}
                    icon={<HomeIcon color={value === 0 ? colors.primary : colors.secondary} />}
                />
                <BottomNavigationAction
                    onClick={handleRoute('/services')}
                    icon={<ServicesIcon color={value === 1 ? colors.primary : colors.secondary} />}
                />
                <BottomNavigationAction
                    onClick={handleRoute('/promotions')}
                    icon={<PercentIcon color={value === 2 ? colors.primary : colors.secondary} />}
                />
                {isAuth && (
                    <BottomNavigationAction
                        onClick={handleRoute('/works/refill')}
                        icon={<FuelIcon color={value === 3 ? colors.primary : colors.secondary} />}
                    />
                )}
                <BottomNavigationAction
                    onClick={handleToggleDrawer}
                    icon={
                        <MoreVertIcon
                            style={{
                                color: value === (isAuth ? 4 : 3) ? colors.primary : colors.secondary,
                            }}
                            className={classes.bottomNavigationIcon}
                        />
                    }
                />
            </BottomNavigation>
            <RegistrationForServiceModal
                isOpen={isOpenServiceRegistrationModal}
                setShowModal={setOpenServiceRegistrationModal}
            />
        </>
    );
};
