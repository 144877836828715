import {
    GET_COMPANY,
    GET_SPECIAL_OFFERS,
    GET_COMPANY_STATISTICS,
    GET_COMPANY_REVIEWS,
    ADD_STATION_REVIEW_COMMENT,
    GET_COMPANY_SLIDER_PHOTOS_FAILURE,
    GET_COMPANY_SLIDER_PHOTOS_REQUEST,
    GET_COMPANY_SLIDER_PHOTOS_SUCCESS,
    GET_COMPANY_WORK_RESULT_PHOTOS_FAILURE,
    GET_COMPANY_WORK_RESULT_PHOTOS_REQUEST,
    GET_COMPANY_WORK_RESULT_PHOTOS_SUCCESS,
    GET_COMFORT_SERVICES_SUCCESS,
    GET_COMFORT_SERVICES_REQUEST,
    GET_COMFORT_SERVICES_FAILURE,
    GET_AUTO_SERVICES_JOBS_REQUEST,
    GET_AUTO_SERVICES_JOBS_SUCCESS,
    GET_AUTO_SERVICES_JOBS_FAILURE,
    GET_AUTO_SERVICES_SCHEDULE_FAILURE,
    GET_AUTO_SERVICES_SCHEDULE_REQUEST,
    GET_AUTO_SERVICES_SCHEDULE_SUCCESS,
    GET_AUTO_SERVICES_DAYSOFF_FAILURE,
    GET_AUTO_SERVICES_DAYSOFF_REQUEST,
    GET_AUTO_SERVICES_DAYSOFF_SUCCESS,
    GET_AUTO_SERVICES_CONTACTS_FAILURE,
    GET_AUTO_SERVICES_CONTACTS_REQUEST,
    GET_AUTO_SERVICES_CONTACTS_SUCCESS,
} from '../constants/serviceStation';

const initialState = {
    company: { id: null },
    feedback: {},
    services: [],
    specialOffers: [],
    jobs: [],
    rating: {
        one: 0,
        two: 0,
        three: 0,
        four: 0,
        five: 0,
        average: 0,
        statistics: 0,
    },
    photos: [],
    workResults: [],
    isLoading: false,
    schedule: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANY:
            return { ...state, company: action.payload };
        case GET_SPECIAL_OFFERS:
            return { ...state, specialOffers: action.payload };
        case GET_COMPANY_STATISTICS:
            return { ...state, rating: action.rating };
        case GET_COMPANY_REVIEWS:
            return { ...state, reviews: action.reviews };
        case GET_AUTO_SERVICES_CONTACTS_REQUEST:
        case GET_AUTO_SERVICES_DAYSOFF_REQUEST:
        case GET_AUTO_SERVICES_SCHEDULE_REQUEST:
        case GET_AUTO_SERVICES_JOBS_REQUEST:
        case GET_COMFORT_SERVICES_REQUEST:
        case GET_COMPANY_WORK_RESULT_PHOTOS_REQUEST:
        case GET_COMPANY_SLIDER_PHOTOS_REQUEST:
            return { ...state, isLoading: true };
        case GET_COMPANY_SLIDER_PHOTOS_SUCCESS:
            return { ...state, photos: action.photos };
        case GET_COMPANY_WORK_RESULT_PHOTOS_SUCCESS:
            return { ...state, workResults: action.workResults };
        case GET_AUTO_SERVICES_CONTACTS_SUCCESS:
        case GET_AUTO_SERVICES_DAYSOFF_SUCCESS:
        case GET_AUTO_SERVICES_SCHEDULE_SUCCESS:
        case GET_AUTO_SERVICES_JOBS_SUCCESS:
        case GET_COMFORT_SERVICES_SUCCESS:
            return { ...state, ...(action.payload || {}) };
        case ADD_STATION_REVIEW_COMMENT:
            return { ...state };
        case GET_AUTO_SERVICES_CONTACTS_FAILURE:
            return { ...state, contacts: [] };
        case GET_AUTO_SERVICES_DAYSOFF_FAILURE:
            return { ...state, daysOff: null };
        case GET_AUTO_SERVICES_JOBS_FAILURE:
            return { ...state, jobs: [] };
        case GET_COMFORT_SERVICES_FAILURE:
            return { ...state, comfortServices: [] };
        case GET_COMPANY_WORK_RESULT_PHOTOS_FAILURE:
            return { ...state, workResults: [] };
        case GET_COMPANY_SLIDER_PHOTOS_FAILURE:
            return { ...state, error: action.error };
        case GET_AUTO_SERVICES_SCHEDULE_FAILURE:
            return { ...state, schedule: [] };
        default:
            return state;
    }
};

export default reducer;
