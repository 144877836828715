import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useUnmount } from 'react-use';
import { Helmet } from 'react-helmet';
import { useErrorState } from 'hooks';

const useStyles = makeStyles({
    container: {
        height: 'calc(100vh - 250px)',
        width: '100vw',
        '@media(max-width:600px)': {
            height: 'calc(100vh - 300px)',
        },
        '& > div': {
            height: '100%',
            width: '100%',
            minHeight: 375,
            backgroundImage: 'url(/error404.png)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundColor: '#fff',
            '@media(max-width:1200px)': {
                backgroundImage: 'url(/error404-mobile.png)',
            },
        },
    },
});

function NotFound() {
    const classes = useStyles();
    const { setErrorLink } = useErrorState();

    useUnmount(() => setErrorLink(false));

    return (
        <div className={classes.container}>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <div />
        </div>
    );
}

export default NotFound;
