import { Button, CircularProgress, Icon, Modal } from '@material-ui/core';
import { updateFavoriteCar } from 'actions/mainPage';
import { useFavoriteCarContext } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SimpleZoom from '../Zoom/Zoom';
import { CHOICE, FAILURE, LOADING, SUCCESS } from './constants';
import { FavoriteCarSelectorList } from './FavoriteCarSelectorList';
import useStyles from './styles';

export function SelectAutoModal({ shouldBeOpen, withRedirectOnClose = true, onClose }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const [isOpen, setOpen] = useState(shouldBeOpen);
    const classes = useStyles();
    const { setHasFavoriteCar } = useFavoriteCarContext();
    const [step, setStep] = useState(CHOICE);
    const [favoriteCar, setFavoriteCar] = useState();

    useEffect(() => {
        setOpen(shouldBeOpen);
    }, [shouldBeOpen]);

    async function handleFavoriteCar(params) {
        try {
            setStep(LOADING);
            const updatedFavoriteCar = await dispatch(updateFavoriteCar(params));
            setFavoriteCar(updatedFavoriteCar);
            setStep(SUCCESS);
        } catch (error) {
            setStep(FAILURE);
        }
    }

    function handleClose() {
        setOpen(false);
        onClose && onClose();
        if (withRedirectOnClose) {
            setHasFavoriteCar(false);
            history.push('/garage');
        }
        setStep(CHOICE);
    }

    function ModalStepper() {
        switch (step) {
            case CHOICE:
                return (
                    <>
                        <h2 id="simple-modal-title">Автомобили</h2>
                        <FavoriteCarSelectorList
                            withRedirectOnClose={withRedirectOnClose}
                            setOpen={setOpen}
                            handleCallback={handleFavoriteCar}
                            onClose={onClose}
                        />
                    </>
                );
            case LOADING:
                return (
                    <>
                        <CircularProgress size={90} thickness="4" className={classes.spinner} />
                        <p id="simple-modal-description" className={classes.waitingText}>
                            Ожидайте, обрабатывается...
                        </p>
                    </>
                );
            case SUCCESS:
                return (
                    <>
                        <SimpleZoom isOpen={isOpen} iconBackgroundColor="#42c24f" icon="fas fa-check" />
                        <h2 id="simple-modal-title">Успешно</h2>
                        <p id="simple-modal-description">
                            Автомобиль: {favoriteCar.mark} {favoriteCar.model} выбран
                        </p>
                        <Button
                            className={`${classes.button} ${classes.confirmButton}`}
                            onClick={() => {
                                setOpen(false);
                                setHasFavoriteCar(true);
                                onClose && onClose();
                                setStep(CHOICE);
                            }}>
                            Ок
                        </Button>
                    </>
                );
            case FAILURE:
                return (
                    <>
                        <SimpleZoom isOpen iconBackgroundColor="#ff3232" icon="fas fa-times" iconSize={62} />
                        <h2 id="simple-modal-title">Ошибка</h2>
                        <p id="simple-modal-description">Произошла ошибка. Попробуйте позже.</p>
                        <Button className={`${classes.button} ${classes.cancelButton}`} onClick={handleClose}>
                            Ок
                        </Button>
                    </>
                );
            default:
                return (
                    <>
                        <SimpleZoom isOpen iconBackgroundColor="#ff3232" icon="fas fa-times" iconSize={62} />
                        <h2 id="simple-modal-title">Ошибка</h2>
                        <p id="simple-modal-description">Произошла ошибка. Попробуйте позже.</p>
                        <Button className={`${classes.button} ${classes.cancelButton}`} onClick={handleClose}>
                            Ок
                        </Button>
                    </>
                );
        }
    }

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={isOpen}
            disableEscapeKeyDown
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleClose();
                }
            }}
            disableAutoFocus>
            <div className={classes.paper} style={step === CHOICE ? {} : { height: 220 }}>
                <ModalStepper />
                <Icon className={`${classes.closeIcon} fas fa-times`} onClick={handleClose} />
            </div>
        </Modal>
    );
}
