import { selectorStyles } from '../../modules/works/selectorStyles';

export const selectorFullHeightStyles = {
  input: provided => ({
      ...provided,
      height: '100%'
  }),
  control: provided => ({
    ...provided,
    minHeight: 55,
    height: 110,
    '&:hover': { borderColor: 'hsl(0,0%,70%)', color: 'hsl(0,0%,70%)' },
    borderColor: 'hsl(0,0%,80%)',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '100%',
    fontSize: 16,
    color: '#fff',
    fontFamily: 'Roboto-Light',
  }),
};

export const asyncSelectorStyles = {
  ...selectorStyles,
  control: provided => ({
    ...provided,
    height: 55,
    '&:hover': { borderColor: 'hsl(0,0%,70%)', color: 'hsl(0,0%,70%)' },
    borderColor: 'hsl(0,0%,80%)',
  }),
};
