import { Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

export const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        MenuListProps={{
            divider: true,
        }}
        {...props}
    />
));

export const StyledMenuItem = withStyles(theme => ({
    root: {
        minWidth: 150,
        paddingBottom: 0,
        '&:focus': {
            backgroundColor: theme.palette.common.light,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: '#2B2B2B',
            },
        },
    },
}))(MenuItem);
