import {
    GET_MARKS,
    GET_MODELS,
    GET_GENERATION,
    GET_SERIES,
    GET_MODIFICATIONS,
    ADD_CAR_SUCCESS,
    ADD_CAR_REQUEST,
    ADD_CAR_FAILURE,
    AUTO_ASSIGNMENT_SUCCESS,
    AUTO_ASSIGNMENT_REQUEST,
    AUTO_ASSIGNMENT_FAILURE,
    CHECK_VIN,
    ADD_REVIEW_COMMENT,
    GET_TOP_RATED_MARKS,
    GET_REVIEWS_COMMENTS,
    GENERATE_AUTO_NUMBER,
    UPDATE_CAR_SUCCESS,
    UPDATE_CAR_FAILURE,
    UPDATE_CAR_REQUEST,
} from '../constants/addCarPage';
import { GET_USERS_VEHICLES } from '../constants/garage';

const initialState = {
    marks: [],
    models: [],
    generations: [],
    series: [],
    modifications: [],
    error: null,
    garage: [],
    autoNumber: '',
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MARKS:
            return {
                ...state,
                marks: action.marks,
                models: null,
                generations: null,
                series: null,
                modifications: null,
            };
        case GET_MODELS:
            return {
                ...state,
                models: action.models,
                generations: null,
                series: null,
                modifications: null,
            };
        case GET_GENERATION:
            return {
                ...state,
                generations: action.generations,
                series: null,
                modifications: null,
            };
        case GET_SERIES:
            return {
                ...state,
                series: action.series,
                modifications: null,
            };
        case GET_MODIFICATIONS:
            return {
                ...state,
                modifications: action.modifications,
            };
        case ADD_CAR_SUCCESS:
            return { ...state, isLoading: false };
        case UPDATE_CAR_SUCCESS:
            return { ...state, isLoading: false };
        case ADD_CAR_REQUEST:
        case UPDATE_CAR_REQUEST:
            return { ...state, isLoading: true };
        case ADD_CAR_FAILURE:
        case UPDATE_CAR_FAILURE:
            return { ...state, error: action.payload, isLoading: false };
        case AUTO_ASSIGNMENT_SUCCESS:
            return { ...state, isLoading: false };
        case AUTO_ASSIGNMENT_REQUEST:
            return { ...state, isLoading: true };
        case AUTO_ASSIGNMENT_FAILURE:
            return { ...state, error: action.payload, isLoading: false };
        case CHECK_VIN:
            return { ...state, checkedVin: action.payload };
        case GET_USERS_VEHICLES:
            return { ...state, garage: action.payload };
        case ADD_REVIEW_COMMENT:
            return {
                ...state,
            };
        case GET_TOP_RATED_MARKS:
            return { ...state, top: action.content };
        case GET_REVIEWS_COMMENTS:
            return { ...state, reviews: action.reviews };
        case GENERATE_AUTO_NUMBER:
            return { ...state, autoNumber: action.payload };
        default:
            return state;
    }
};

export default reducer;
