import { GET_AUTO_BY_ID_FAILURE, GET_AUTO_BY_ID_REQUEST, GET_AUTO_BY_ID_SUCCESS } from '../constants/auto';

const initialState = {
    auto: null,
    isLoading: false,
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AUTO_BY_ID_REQUEST:
            return { ...state, isLoading: true };
        case GET_AUTO_BY_ID_FAILURE:
            return { ...state, error: action.error };
        case GET_AUTO_BY_ID_SUCCESS:
            return { ...state, auto: action.payload };
        default:
            return state;
    }
};

export default reducer;
