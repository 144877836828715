import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        top: `50%`,
        left: `50%`,
        height: 'max-content',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 15,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0, 4, 3),
        '@media(max-width:480px)': {
            width: 350,
            padding: theme.spacing(0, 2, 3),
        },
        '@media(max-width:400px)': {
            width: 300,
            padding: theme.spacing(0, 2, 3),
        },
        '@media(max-width:330px)': {
            width: 270,
            padding: theme.spacing(0, 2, 3),
        },
        '& h2': {
            textAlign: 'center',
            color: 'rgb(77, 77, 77)',
        },
        '& p': {
            fontSize: 17,
            fontFamily: 'Roboto-Medium',
            textAlign: 'center',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    inputGroup: {
        display: 'flex',
        flexDirection: 'column',
        '& > div': {
            margin: '6px 0px',
        },
        '& > div:last-child': {
            margin: '6px 0px 14px',
        },
    },
    button: {
        width: '100%',
        height: 45,
        color: '#fff',
        textAlign: 'center',
        '@media(max-width:460px)': {
            fontSize: 12,
        },
    },
    confirmButton: {
        backgroundColor: '#00afc1',
        '&:hover': {
            backgroundColor: '#0e444d',
        },
    },
    formControl: {
        width: '100%',
        height: ({ isSelectCompany }) => (isSelectCompany ? '100%' : 55),
    },
    treeSelect: {
        '& .ant-select': {
            '& .ant-select-selector': {
                minHeight: '45px !important',
                borderRadius: '4px !important',
                borderColor: 'hsl(0, 0%, 80%) !important',
                '& .ant-select-selection-item': {
                    height: 40,
                    backgroundColor: '#0dabc2',
                    '& .ant-select-selection-item-content': {
                        color: '#fff',
                        fontFamily: 'Roboto-Medium',
                        fontSize: 16,
                    },
                    '& .ant-select-selection-item-remove': {
                        fontSize: 13,
                        color: '#0e444d',
                        '&:hover': {
                            color: '#00606d',
                        },
                    },
                },
                '& > span > input': {
                    height: '100% !important',
                    fontSize: '14px !important',
                },
            },
        },
        '& .ant-select-focused': {
            '& .ant-select-arrow > svg': {
                color: 'hsl(0, 0%, 40%)',
            },
        },
        '& .ant-select-selection-placeholder, ': {
            opacity: '1 !important',
            color: '#424242 !important',
            fontSize: '1rem',
            fontFamily: 'Roboto-Light, sans-serif !important',
        },
        '& .ant-select-selection-placeholder, & .ant-select-selection-item': {
            lineHeight: '43px !important',
        },
        '& .ant-select-arrow': {
            width: 35,
            '& > svg': {
                width: 23,
                height: 23,
                padding: 8,
                transform: 'translate(23%, -34%)',
                color: 'hsl(0, 0%, 80%)',
            },
        },
    },
    closeIcon: {
        cursor: 'pointer',
        top: 0,
        color: '#00afc1',
        right: 0,
        height: 40,
        margin: 10,
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            color: '#0e444d',
        },
    },
}));

export default useStyles;
