import React, { useState } from 'react';
import { useCookie } from 'react-use';
import { useHistory, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, TextField, IconButton, CircularProgress, Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { LoginSocialGoogle } from 'reactjs-social-login';
import { NotificationManager } from 'react-notifications';
import { SocialButton } from 'components/SocialButton';

import { signInWitApple, signInWithGoogle } from 'api/auth';
import Countdown, { zeroPad } from 'react-countdown';
import { OTP_TYPES } from 'constants/otp';
import { loginWithOAuth } from 'actions/authFlow';
import { useDispatch } from 'react-redux';
import styles from './styles';
import { useAppleSignIn } from '../hooks/useAppleSignIn';

const ModalContent = ({ classes, onClose, handleClickEnter }) => {
    const [_, updateCookie] = useCookie('oauth-signup');
    const history = useHistory();
    const dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState([]);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingGoogleAuth, setIsLoadingGoogleAuth] = useState(false);
    const [isLoadingAppleAuth, setIsLoadingAppleAuth] = useState(false);
    const [secondsRemaining, setSecondsRemaining] = useState(0);

    const change = e => {
        const { name, value: newValue } = e.target;
        if (name === 'username') {
            setUsername(newValue);
            setUsernameError([]);
        } else if (name === 'password') {
            setPassword(newValue);
            setPasswordError([]);
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    const validate = () => {
        let isError = false;
        const errors = {
            usernameError: [],
            passwordError: [],
        };

        if (!username) {
            isError = true;
            errors.usernameError = ['Введите E-mail адрес'];
        }

        if (!password) {
            isError = true;
            errors.passwordError = ['Введите пароль'];
        }

        setUsernameError(errors.usernameError);
        setPasswordError(errors.passwordError);

        return isError;
    };

    const trySignIn = async event => {
        event.preventDefault();
        const err = validate();
        if (!err) {
            setIsLoading(true);
            const { isAuth, secondsRemaining: newSecondsRemaining } = await handleClickEnter(
                username.trim(),
                password.trim(),
            );
            if (isAuth) {
                setUsername('');
                setPassword('');
                setUsernameError([]);
                setPasswordError([]);
            } else if (newSecondsRemaining) {
                setUsernameError([]);
                setPasswordError([]);
                setSecondsRemaining(newSecondsRemaining);
            } else {
                setUsernameError(['Неверный логин или пароль']);
                setPasswordError(['Неверный логин или пароль']);
            }
            setIsLoading(false);
        }
    };

    const handleSignInGoogle = async ({ data }) => {
        try {
            setIsLoadingGoogleAuth(true);
            const { access_token } = data;
            const response = await signInWithGoogle(access_token);
            const [firstName, lastName] = response.name?.split(' ');
            dispatch(
                loginWithOAuth({
                    accessToken: response.accessToken,
                    refreshToken: response.refreshToken,
                    firstName,
                    lastName,
                    userID: response.userID,
                    number: response.number,
                    email: response.email,
                }),
            );
            onClose();
            setIsLoadingGoogleAuth(false);
            history.push('/garage');
        } catch (error) {
            if (error?.data?.message === 'error.not-found.user-not-registered') {
                const expirationTime = new Date();
                expirationTime.setMinutes(expirationTime.getMinutes() + 10);

                updateCookie(
                    JSON.stringify(
                        {
                            type: OTP_TYPES.GOOGLE,
                            accessToken: error?.data?.userInfo?.accessToken,
                            email: error?.data?.userInfo?.email,
                            firstName: error?.data?.userInfo?.firstName,
                            lastName: error?.data?.userInfo?.lastName,
                        },
                        { expires: expirationTime },
                    ),
                );
                onClose();
                history.push('/registration');
            } else {
                NotificationManager.error('Произошла ошибка. Попробуйте позже');
            }
            setIsLoadingGoogleAuth(false);
        }
    };

    const handleSignInApple = async ({ authorization }) => {
        try {
            setIsLoadingAppleAuth(true);
            const { id_token } = authorization;
            const response = await signInWitApple(id_token);
            const [firstName, lastName] = response.name?.split(' ');
            dispatch(
                loginWithOAuth({
                    accessToken: response.accessToken,
                    refreshToken: response.refreshToken,
                    firstName,
                    lastName,
                    userID: response.userID,
                    number: response.number,
                    email: response.email,
                }),
            );
            setIsLoadingAppleAuth(false);
            onClose();
            history.push('/garage');
        } catch (error) {
            if (error?.data?.message === 'error.not-found.user-not-registered') {
                const expirationTime = new Date();
                expirationTime.setMinutes(expirationTime.getMinutes() + 10);
                updateCookie(
                    JSON.stringify(
                        {
                            type: OTP_TYPES.APPLE,
                            idToken: error?.data?.userInfo?.idToken,
                            email: error?.data?.userInfo?.email,
                        },
                        { expires: expirationTime },
                    ),
                );
                onClose();
                history.push('/registration');
            } else {
                NotificationManager.error('Произошла ошибка. Попробуйте позже');
            }
            setIsLoadingAppleAuth(false);
        }
    };

    const handleSocialAuthStart = type => {
        type === OTP_TYPES.APPLE ? setIsLoadingAppleAuth(true) : setIsLoadingGoogleAuth(true);
    };

    const handleSocialError = type => {
        NotificationManager.error('Произошла ошибка. Попробуйте позже');
        type === OTP_TYPES.APPLE ? setIsLoadingAppleAuth(false) : setIsLoadingGoogleAuth(false);
    };

    const { onClick: onClickSignInApple } = useAppleSignIn({
        clientId: 'com.taexel.sid',
        redirectURI: window.location.origin,
        scope: 'name email',
        onSuccess: handleSignInApple,
        onFailure: () => {
            handleSocialError(OTP_TYPES.APPLE);
        },
        onSignInStart: () => {
            handleSocialAuthStart(OTP_TYPES.APPLE);
        },
    });

    const isMobile = window.innerWidth < 600;

    return (
        <Box className={classes.container}>
            <form onSubmit={trySignIn} id="login">
                <Box>
                    <TextField
                        name="username"
                        autoFocus
                        label="E-mail адрес"
                        className={`${classes.fields} ${classes.input}`}
                        value={username}
                        onChange={change}
                        autoComplete="email"
                        inputProps={{
                            inputMode: 'email',
                        }}
                        helperText={usernameError.map(i => (
                            <div key={i}>{i}</div>
                        ))}
                        error={usernameError.length > 0}
                    />
                </Box>
                <TextField
                    name="password"
                    label="Пароль"
                    className={`${classes.fields} ${classes.input}`}
                    value={password}
                    onChange={change}
                    helperText={passwordError.map(i => (
                        <div key={i}>{i}</div>
                    ))}
                    error={passwordError.length > 0}
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    name="showPassword"
                                    onClick={toggleShowPassword}>
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {!!secondsRemaining && (
                    <>
                        <Typography className={classes.countdownError}>Неверный логин или пароль</Typography>
                        <Typography className={classes.countdownError}>
                            Повторите попытку через{' '}
                            <Countdown
                                onComplete={() => {
                                    setSecondsRemaining(0);
                                }}
                                date={Date.now() + 1000 * secondsRemaining}
                                renderer={({ minutes, seconds }) => (
                                    <span>
                                        {zeroPad(minutes)}:{zeroPad(seconds)}
                                    </span>
                                )}
                            />
                        </Typography>
                    </>
                )}
                {isLoading ? (
                    <CircularProgress size={60} thickness="4" className={classes.spinner} />
                ) : (
                    <>
                        <Box display={!isMobile && 'flex'} justifyContent="space-between" alignItems="center">
                            <Box mt={2}>
                                <Button
                                    disabled={
                                        !!secondsRemaining || isLoading || isLoadingGoogleAuth || isLoadingAppleAuth
                                    }
                                    className={classes.buttons}
                                    variant="contained"
                                    form="login"
                                    type="submit">
                                    Войти
                                </Button>
                            </Box>
                            <Box mt={2}>
                                <Link to="/password/forgot" onClick={onClose}>
                                    Забыли пароль?
                                </Link>
                            </Box>
                        </Box>
                        <Box mt={2}>
                            <Link to="/registration">
                                <Button
                                    disabled={isLoading || isLoadingGoogleAuth || isLoadingAppleAuth}
                                    className={classes.buttons}
                                    variant="contained"
                                    onClick={onClose}>
                                    Регистрация
                                </Button>
                            </Link>
                        </Box>
                        <Box mt={2}>
                            <SocialButton
                                disabled={isLoading || isLoadingGoogleAuth || isLoadingAppleAuth}
                                social="PHONE"
                                onClick={() => {
                                    history.push({
                                        pathname: '/registration',
                                        search: '?type=PHONE',
                                    });
                                }}
                            />
                            <LoginSocialGoogle
                                client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                scope="profile email"
                                onResolve={handleSignInGoogle}
                                onLoginStart={() => {
                                    handleSocialAuthStart(OTP_TYPES.APPLE);
                                }}
                                onReject={() => {
                                    handleSocialAuthStart(OTP_TYPES.APPLE);
                                }}>
                                <SocialButton
                                    disabled={isLoading || isLoadingAppleAuth}
                                    social="GOOGLE"
                                    onClick={handleSignInGoogle}
                                />
                            </LoginSocialGoogle>
                            <SocialButton
                                disabled={isLoading || isLoadingGoogleAuth}
                                social="APPLE"
                                onClick={onClickSignInApple}
                            />
                        </Box>
                    </>
                )}
            </form>
        </Box>
    );
};

export default withStyles(styles)(ModalContent);
