import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const AppleIcon = props => {
    return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="48px" height="48px">
            <path d="M42.428 2c.234 3.388-.871 6.116-2.501 8.117-2.398 2.943-5.932 4.315-8.016 3.907-.392-2.351.58-5.352 2.557-7.771C36.33 3.975 39.083 2.214 42.428 2zM32.359 17.045c2.378 0 4.953-2.41 9.193-2.41 1.911 0 7.388.578 10.408 5.222-1.2.869-5.632 3.659-5.632 10.008 0 7.481 6.059 10.07 6.978 10.544-.331 1.236-1.929 5.523-4.623 8.88-.834 1.039-3.339 5.027-7.079 5.027-3.397 0-4.689-2.102-8.608-2.102-4.464 0-4.678 2.14-9.02 2.14-.912 0-2.25-.412-3.31-1.345-3.765-3.315-9.973-11.883-9.973-22.569 0-10.559 7.003-15.628 13.144-15.628C27.699 14.811 29.902 17.045 32.359 17.045z" />
        </SvgIcon>
    );
};
