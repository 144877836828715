import {
    GET_USERS_VEHICLES,
    CHANGE_FAVORITE_CAR,
    GET_AUTO_ATTACHMENT_REQUEST,
    GET_AUTO_ATTACHMENT_FAILURE,
    GET_AUTO_ATTACHMENT_SUCCESS,
    ADD_AUTO_ATTACHMENT_FAILURE,
    ADD_AUTO_ATTACHMENT_REQUEST,
    ADD_AUTO_ATTACHMENT_SUCCESS,
} from '../constants/garage';

const initialState = {
    cars: [],
    favoriteCar: 0,
    isLoading: false,
    error: '',
    gallery: {
        content: [],
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS_VEHICLES:
            return { ...state, cars: action.payload };
        case CHANGE_FAVORITE_CAR:
            return { ...state, favoriteCar: action.payload };
        case GET_AUTO_ATTACHMENT_REQUEST:
            return { ...state, isLoading: true };
        case GET_AUTO_ATTACHMENT_SUCCESS:
            return { ...state, gallery: action.payload };
        case GET_AUTO_ATTACHMENT_FAILURE:
            return { ...state, error: action.payload };
        case ADD_AUTO_ATTACHMENT_REQUEST:
            return { ...state, isLoading: true };
        case ADD_AUTO_ATTACHMENT_SUCCESS:
            return { ...state, gallery: action.payload };
        case ADD_AUTO_ATTACHMENT_FAILURE:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};

export default reducer;
