import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiThemeProvider, createMuiTheme, StylesProvider } from '@material-ui/core/styles';
import { NotificationContainer } from 'react-notifications';

import { LinkProvider } from './providers';
import App from './modules/App';
import { store, persistor } from './store';
import './index.scss';
import 'react-notifications/lib/notifications.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

if (process.env.REACT_APP_ENV === 'PROD') {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
}

const THEME = createMuiTheme({
    typography: {
        fontFamily: 'Roboto-Medium',
        color: '#666',
    },
    zIndex: {
        modal: 4000,
    },
});

ReactDOM.render(
    <PersistGate loading={null} persistor={persistor}>
        <StylesProvider injectFirst>
            <MuiThemeProvider theme={THEME}>
                <LinkProvider>
                    <Provider store={store}>
                        <App />
                        <NotificationContainer />
                    </Provider>
                </LinkProvider>
            </MuiThemeProvider>
        </StylesProvider>
    </PersistGate>,
    document.getElementById('root'),
);
