import {
    GET_USER_BY_CLIENT_NUMBER_REQUEST,
    GET_USER_BY_CLIENT_NUMBER_FAILURE,
    GET_USER_BY_CLIENT_NUMBER_SUCCESS,
} from '../constants/users';

const initialState = { user: [] };

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_BY_CLIENT_NUMBER_REQUEST:
            return { ...state, isLoading: true };
        case GET_USER_BY_CLIENT_NUMBER_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
        case GET_USER_BY_CLIENT_NUMBER_SUCCESS:
            return { ...state, user: action.payload };
        default:
            return state;
    }
};

export default reducer;
