export const selectorStyles = {
    control: provided => ({
        ...provided,
        height: 47,
        '&:hover': { borderColor: 'hsl(0,0%,70%)', color: 'hsl(0,0%,70%)' },
        borderColor: 'hsl(0,0%,80%)',
    }),
    indicatorsContainer: provided => ({
        ...provided,
        '&:hover': { color: 'hsl(0,0%,70%)' },
        color: 'hsl(0,0%,70%)',
    }),
    indicatorSeparator: provided => ({
        ...provided,
        '&:hover': { color: 'hsl(0,0%,70%)' },
        color: 'hsl(0,0%,80%)',
    }),
    option: (provided, { isSelected }) => ({
        ...provided,
        color: isSelected ? '#fff' : '#000',
        '&:hover': {
            color: '#fff',
        },
    }),
    menu: provided => ({
        ...provided,
        borderColor: 'black',
        fontSize: 16,
        color: '#fff',
        fontFamily: 'Roboto-Light',
        zIndex: 100000,
    }),
    input: provided => ({
        ...provided,
    }),
    valueContainer: provided => ({
        ...provided,
        fontSize: 16,
        color: '#fff',
        fontFamily: 'Roboto-Light',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition, lineHeight: '20px' };
    },
    menuPortal: base => ({
        ...base,
        zIndex: 9999,
    }),
};

export const selectorThemes = theme => ({
    ...theme,
    borderColor: 'black',
    colors: {
        ...theme.colors,
        primary: '#00afc1',
        primary25: '#fff',
        primary50: '#6DCCDA',
    },
});

export const errorStyle = {
    ...selectorStyles,
    control: provided => ({
        ...provided,
        height: 47,
        borderColor: 'red',
        '&:hover': { borderColor: 'hsl(0,0%,70%)', color: 'hsl(0,0%,70%)' },
    }),
};
