import { Box } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { CustomText } from '../../../components/Text';
import useStyles from '../sign-in-up/styles';

export function UnauthHeaderLinks({ isMedium }) {
    const classes = useStyles();
    return (
        <Box
            display="flex"
            flex={1}
            justifyContent={isMedium ? 'space-around' : 'flex-start'}
            width={isMedium ? '100%' : 'auto'}>
            <Link to="/">
                <CustomText
                    className={`${classes.loginButtonText} ${isMedium ? '' : classes.ownerButtonText}`}
                    text="Владельцам"
                />
            </Link>
            <Link to="/b2b">
                <CustomText className={classes.loginButtonText} text="Автосервисам" />
            </Link>
        </Box>
    );
}
