import { useEffect } from 'react';

import { useScript } from './useScript';

const SCRIPT_URL = `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js`;

const EVENT = {
    ON_SUCCESS: 'AppleIDSignInOnSuccess',
    ON_FAILURE: 'AppleIDSignInOnFailure',
};

export function useAppleSignIn(props) {
    const { clientId, redirectURI, onSuccess, onFailure, scope, onSignInStart } = props;

    const [loaded] = useScript(SCRIPT_URL);

    async function onClick(e) {
        try {
            e?.preventDefault();

            onSignInStart?.();

            await window.AppleID.auth.signIn();
        } catch (error) {
            onFailure?.(error);
        }
    }

    useEffect(() => {
        if (loaded) {
            window.AppleID.auth.init({
                usePopup: true,
                clientId,
                scope,
                redirectURI,
            });
        }
    }, [loaded]);

    useEffect(() => {
        function listener(data) {
            onSuccess(data.detail);
        }

        document.addEventListener(EVENT.ON_SUCCESS, listener);

        return () => {
            document.removeEventListener(EVENT.ON_SUCCESS, listener);
        };
    }, []);

    useEffect(() => {
        function listener(error) {
            onFailure?.(error);
        }

        document.addEventListener(EVENT.ON_FAILURE, listener);

        return () => {
            document.removeEventListener(EVENT.ON_FAILURE, listener);
        };
    }, []);

    return {
        onClick,
    };
}
